import { createSlice } from "@reduxjs/toolkit";
import {
  getAlarmsAndFaultsByConnection,
  createCustomAlarmOrFault,
  updateCustomAlarmOrFault,
  deleteCustomAlarmOrFault,

} from "./thunk";


export const initialState: any = {
  alarmsAndFaults: [],
  error: null,
  loading: false,
};

const alarmsAndFaultsSlice = createSlice({
  name: "alarmHistory",
  initialState,
  reducers: {},

  extraReducers: (builder) => {



    builder.addCase(getAlarmsAndFaultsByConnection.fulfilled, (state, action) => {
      state.loading = false;
      state.alarmsAndFaults = action.payload;
    });
    builder.addCase(getAlarmsAndFaultsByConnection.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to fetch alarm histories';
    });

    builder.addCase(createCustomAlarmOrFault.fulfilled, (state, action) => {
     
      state.loading = false;
      state.alarmsAndFaults.push(action.payload);
    });
    builder.addCase(createCustomAlarmOrFault.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to create custom alarm or fault';
    });

    builder.addCase(updateCustomAlarmOrFault.fulfilled, (state, action) => {
      
      state.loading = false;
      const index = state.alarmsAndFaults.findIndex((fault: any) => fault.id === action.payload.id);
      if (index !== -1) {
        state.alarmsAndFaults[index] = action.payload;
      }
    });
    builder.addCase(updateCustomAlarmOrFault.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to update custom alarm or fault';
    });

    builder.addCase(deleteCustomAlarmOrFault.fulfilled, (state : any, action : any) => {
      
      state.loading = false;
      state.alarmsAndFaults = state.alarmsAndFaults.filter((fault: any) => fault.id !== action.payload);
    });
    builder.addCase(deleteCustomAlarmOrFault.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to delete custom alarm or fault';
    });


    /*    builder.addCase(getAlarmHistoriesByZone.pending, (state) => {
         state.loading = true;
         state.error = null;
       });
       builder.addCase(getAlarmHistoriesByZone.fulfilled, (state, action) => {
         state.loading = false;
         state.alarmHistories = action.payload;
       });
       builder.addCase(getAlarmHistoriesByZone.rejected, (state, action: any) => {
         state.loading = false;
         state.error = action.payload.error || 'Failed to fetch alarm histories by zone';
       });
   
       builder.addCase(updateAlarmHistory.fulfilled, (state, action) => {
         state.loading = false;
         if (action.payload && action.payload.is_acknowledged) {
           state.alarmHistoriesNotAcknowledged = state.alarmHistoriesNotAcknowledged.filter((alarmHistory: any) => alarmHistory.id !== action.payload.id);
         }
       }); */
  },
});

export default alarmsAndFaultsSlice.reducer;
