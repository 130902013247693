import { createSlice } from "@reduxjs/toolkit";
import {
  createDevice,
  getDevices,
  deleteDevice,
  updateDevice,
  getDevice
} from "./thunk";



export const initialState: any = {
  devices: [],
};


const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {},

  extraReducers: (builder) => {

    builder.addCase(getDevices.fulfilled, (state: any, action: any) => {
      state.devices = action.payload;
    });
    builder.addCase(getDevices.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getDevice.fulfilled, (state: any, action: any) => {
      state.device = action.payload;
    });
    builder.addCase(getDevice.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(createDevice.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        state.devices.push(action.payload);
      }

    });

    builder.addCase(createDevice.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateDevice.fulfilled, (state: any, action: any) => {
      state.devices = (state.devices || []).map((device: any) =>
        device.id.toString() === action.payload.id.toString()
          ? { ...device, ...action.payload }
          : device
      );
    });

    builder.addCase(updateDevice.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteDevice.fulfilled, (state: any, action: any) => {
      state.devices = state.devices.filter(
        (device: any) => device.id.toString() !== action.payload.toString()
      );
    });

    builder.addCase(deleteDevice.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});

export default deviceSlice.reducer;