import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label } from 'reactstrap';
import { Field, Form, Formik, FieldProps } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getStatus } from 'slices/status/thunk';
import { useLocation, useParams } from 'react-router-dom';
import { createStatusZone, updateStatusZone } from 'slices/statusZone/thunk';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { id } from 'date-fns/locale';

interface Status {
    id: number;
    name: string;
}

interface Device {
    id: number;
    name: string;
    ip: string;
}

interface StatusZone {
    id?: number;
    installation_zone_id: number;
    name: string;
    description: string;
    position: number;
    value: string;
    status_id?: number;
    connection_id?: number;
    message_activated?: string;
    message_deactivated?: string;
}

interface ModalAddStatusZoneProps {
    isOpen: boolean;
    toggle: () => void;
    onSave: (values: StatusZone) => void;
    currentZone?: StatusZone | null;
    idSelected: number | null;
    zoneId?: number;
}

const ModalAddStatusZone: React.FC<ModalAddStatusZoneProps> = ({ isOpen, toggle, onSave, currentZone, idSelected, zoneId }) => {
    const [statusOptions, setStatusOptions] = useState<Status[]>([]);
    const [deviceOptions, setDeviceOptions] = useState<Device[]>([]);
    const [connections, setConnections] = useState<any[]>([]);
    const [loadingPage, setLoadingPage] = useState<boolean>(true);
    const location = useLocation();
    const dispatch: any = useDispatch();
    const param = useParams<{ id: string }>();
    const id = zoneId ? zoneId.toString() : param.id;

    const selectStatusState = (state: any) => state.Status;
    const selectInstallationsState = (state: any) => state.Installations;
    const selectParametersBackupState = (state: any) => state.ParametersBackup;

    const statusProperties = createSelector(
        selectStatusState,
        (state: any) => state
    );

    const installationDataProperties = createSelector(
        selectInstallationsState,
        (state) => state
    );

    const parametersBackupProperties = createSelector(
        selectParametersBackupState,
        (state) => state
    );

    const { status } = useSelector(statusProperties);
    const { parametersBackup } = useSelector(parametersBackupProperties);
    const { installations } = useSelector(installationDataProperties);


    useEffect(() => {
        if (isOpen && idSelected) {
            dispatch(getStatus(idSelected));
        }
    }, [isOpen, dispatch, idSelected]);

    useEffect(() => {
        const isZonaRoute = location.pathname.includes('/dashboard/zona/') || location.pathname.includes('/registros/alarmas-fallos/') || location.pathname.includes('/dashboard/instalacion/');
        if (installations.length > 0) {
            if (isZonaRoute) {
                const matchedZones = installations.flatMap((installation: any) =>
                    installation.zones.filter((zone: any) => zone.id === Number(id))
                );
                if (matchedZones.length > 0) {
                    setConnections(matchedZones[0].connections);
                }
            } else {
                const connectionId = Number(id);


                const matchedZone = installations
                    .flatMap((installation: any) => installation.zones)
                    .find((zone: any) =>
                        zone.connections.some((connection: any) => connection.id === connectionId)
                    );


                if (matchedZone) {
                    const allConnectionsInZone = matchedZone.connections;
                    setConnections(allConnectionsInZone);
                } else {
                    setConnections([]);
                }
            }
        }
        setLoadingPage(false);
    }, [id, location.pathname, installations]);

    useEffect(() => {
        if (currentZone && currentZone.connection_id !== undefined) {
            const deviceIP = parametersBackup[currentZone.connection_id]?.connection_ip;
            if (deviceIP) {
                loadStatusOptions(deviceIP);
            }
        }
    }, [currentZone, connections, parametersBackup, status]);

    const sortStatuses = (a: any, b: any) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();

        const subgroupA = a.subgroup || '';
        const subgroupB = b.subgroup || '';

        // Primero, comparamos los subgrupos
        if (subgroupA < subgroupB) return -1;
        if (subgroupA > subgroupB) return 1;

        // Si los subgrupos son iguales, ordenamos por "entrada" antes que "salida"
        if (labelA.startsWith('entrada') && !labelB.startsWith('entrada')) return -1;
        if (!labelA.startsWith('entrada') && labelB.startsWith('entrada')) return 1;

        if (labelA.startsWith('salida') && !labelB.startsWith('salida')) return -1;
        if (!labelA.startsWith('salida') && labelB.startsWith('salida')) return 1;

        return labelA.localeCompare(labelB);
    };

    const loadStatusOptions = (deviceIP: string) => {
        const selectedDeviceID = connections.find((conn) => conn.ip === deviceIP)?.id;
        if (deviceIP && selectedDeviceID) {
            const statusForDevice = status[deviceIP] || [];
            const filteredStatuses = statusForDevice.filter((status: any) =>
                parametersBackup[selectedDeviceID]?.status.some((param: any) => param.id === status.parameter_id)
            );

            const sortedStatuses = filteredStatuses.map((status: any) => {
                const parameter = parametersBackup[selectedDeviceID]?.status.find((param: any) => param.id === status.parameter_id);
                return {
                    value: status.id,
                    label: parameter ? `${parameter.variable}${parameter.subgroup_1 ? ' - ' + parameter.subgroup_1 : ''}` : null,
                    subgroup: parameter?.subgroup_1 || ''
                };
            }).sort(sortStatuses);

            setStatusOptions(sortedStatuses);
        } else {
            setStatusOptions([]);
        }
    };

    const handleDeviceChange = (selectedOption: any, setFieldValue: any) => {
        const selectedDeviceIP = selectedOption?.value;
        setFieldValue('device', selectedOption);
        setFieldValue('connection_id', connections.find((conn) => conn.ip === selectedDeviceIP)?.id);
        setFieldValue('ip', selectedDeviceIP);
        loadStatusOptions(selectedDeviceIP);
    };

    const statusValidationSchema = Yup.object().shape({
        description: Yup.string(),
        status: Yup.object().required('El estado es obligatorio'),
        device: Yup.object().required('El dispositivo es obligatorio'),
        message_activated: Yup.string().test(
            'at-least-one-message',
            'Al menos uno de los mensajes es obligatorio',
            function (value) {
                const { message_activated, message_deactivated } = this.parent;
                return !!message_activated || !!message_deactivated;
            }
        ),
        message_deactivated: Yup.string().test(
            'at-least-one-message',
            'Al menos uno de los mensajes es obligatorio',
            function (value) {
                const { message_activated, message_deactivated } = this.parent;
                return !!message_activated || !!message_deactivated;
            }
        )
    });

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            borderRadius: '50px'
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            borderRadius: '50px'
        }),
        placeholder: (provided: any) => ({
            ...provided,
            borderRadius: '50px'
        }),
        singleValue: (provided: any) => ({
            ...provided,
            borderRadius: '50px'
        })
    };

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
                <ModalHeader>{currentZone ? 'Editar Estado' : 'Añadir Estado'}</ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            name: currentZone?.name || '',
                            description: currentZone?.description || '',
                            status: currentZone ? {
                                value: currentZone.status_id,
                                label: (() => {
                                    const deviceIP = currentZone.connection_id !== undefined ? parametersBackup[currentZone.connection_id]?.connection_ip : '';
                                    if (deviceIP && status[deviceIP]) {
                                        const foundStatus = status[deviceIP].find((s: Status) => s.id === currentZone.status_id);
                                        if (foundStatus && currentZone.connection_id !== undefined) {
                                            const backup = parametersBackup[currentZone.connection_id];
                                            if (backup && backup.status) {
                                                const parameter = backup.status.find((param: any) => param.id === foundStatus.parameter_id);
                                                if (parameter) {
                                                    return `${parameter.variable}`;
                                                }
                                            }
                                        }
                                    }
                                    return '';
                                })()
                            } : null,
                            device: currentZone ? { value: currentZone.value, label: connections.find((conn) => conn.id === currentZone.connection_id)?.name || '' } : null,
                            is_active: currentZone ? currentZone.position !== 0 : true,
                            connection_id: currentZone ? currentZone.connection_id : undefined,
                            ip: currentZone?.value || '',
                            message_activated: currentZone?.message_activated || '',
                            message_deactivated: currentZone?.message_deactivated || '',
                        }}
                        validationSchema={statusValidationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            const data = {
                                ...values,
                                status_id: values.status?.value,
                                installation_zone_id: currentZone?.installation_zone_id || Number(id),
                                connection_id: values.connection_id,
                            };

                            const statusZone = {
                                id: currentZone ? currentZone.id : null,
                                connection_id: data.connection_id,
                                name: data.name,
                                description: data.description,
                                is_active: data.is_active,
                                status_id: data.status_id,
                                installation_zone_id: data.installation_zone_id,
                                message_activated: data.message_activated,
                                message_deactivated: data.message_deactivated
                            };

                            if (currentZone) {
                                dispatch(updateStatusZone(statusZone))
                                    .then(() => {
                                        toast.success('Estado actualizado exitosamente');
                                        resetForm();
                                        setSubmitting(false);
                                        toggle();
                                    })
                                    .catch(() => {
                                        toast.error('Error al actualizar el estado');
                                        setSubmitting(false);
                                    });
                            } else {
                                dispatch(createStatusZone({ status: statusZone, parametersBackup: parametersBackup }))
                                    .then(() => {
                                        toast.success('Estado guardado exitosamente');
                                        resetForm();
                                        setSubmitting(false);
                                        toggle();
                                    })
                                    .catch(() => {
                                        toast.error('Error al guardar el estado');
                                        setSubmitting(false);
                                    });
                            }
                        }}
                    >
                        {({ errors, touched, handleSubmit, setFieldValue, values }) => (
                            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                <FormGroup>
                                    <Label for="description">Descripción del estado</Label>
                                    <Field
                                        name="description"
                                        type="text"
                                        className="form-control rounded-pill"
                                        placeholder="Descripción del estado"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="messageActivated">Mensaje Activado</Label>
                                    <Field
                                        name="message_activated"
                                        type="text"
                                        className={`form-control rounded-pill ${errors.message_activated && touched.message_activated ? 'is-invalid' : ''}`}
                                        placeholder="Mensaje activado"
                                        maxLength={18}
                                    />
                                    {errors.message_activated && touched.message_activated && (
                                        <div className="invalid-feedback d-block">{errors.message_activated}</div>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="messageDeactivated">Mensaje Desactivado</Label>
                                    <Field
                                        name="message_deactivated"
                                        type="text"
                                        maxLength={18}
                                        className={`form-control rounded-pill ${errors.message_deactivated && touched.message_deactivated ? 'is-invalid' : ''}`}
                                        placeholder="Mensaje desactivado"
                                    />
                                    {errors.message_deactivated && touched.message_deactivated && (
                                        <div className="invalid-feedback d-block">{errors.message_deactivated}</div>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="deviceSelect">Seleccionar Dispositivo</Label>
                                    <Select
                                        id="deviceSelect"
                                        name="device"
                                        options={connections.map((device) => ({ value: device.ip, label: device.name }))}
                                        onChange={(selectedOption: any) => handleDeviceChange(selectedOption, setFieldValue)}
                                        value={values.device}
                                        styles={customStyles}
                                        placeholder="Selecciona un dispositivo..."
                                        className={`react-select-container ${errors.device && touched.device ? 'is-invalid' : ''}`}
                                        classNamePrefix="react-select"
                                    />
                                    {errors.device && touched.device && (
                                        <div className="invalid-feedback d-block">{errors.device}</div>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="statusSelect">Seleccionar Estado</Label>
                                    <Select
                                        id="statusSelect"
                                        name="status"
                                        options={statusOptions}
                                        onChange={(selectedOption: any) => setFieldValue('status', selectedOption)}
                                        value={values.status}
                                        isDisabled={!values.device}
                                        styles={customStyles}
                                        placeholder="Selecciona un estado..."
                                        className={`react-select-container ${errors.status && touched.status ? 'is-invalid' : ''}`}
                                        classNamePrefix="react-select"
                                    />
                                    {errors.status && touched.status && (
                                        <div className="invalid-feedback d-block">{errors.status}</div>
                                    )}
                                </FormGroup>
                                <FormGroup className="d-flex align-items-center">
                                    <div className="form-check form-switch form-switch-lg">
                                        <Field name="is_active">
                                            {({ field }: FieldProps) => (
                                                <input
                                                    {...field}
                                                    type="checkbox"
                                                    className="form-check-input rounded-pill"
                                                    id="activeSwitch"
                                                    checked={field.value}
                                                />
                                            )}
                                        </Field>
                                        <Label className="form-check-label ms-2" for="activeSwitch">Estado activo</Label>
                                    </div>
                                </FormGroup>
                                <ModalFooter className='d-flex justify-content-center pb-0'>
                                    <Button color="secondary" className="rounded-pill" onClick={toggle}>Cancelar</Button>
                                    <Button type="submit" color="primary" className="rounded-pill">{currentZone ? 'Actualizar' : 'Guardar'}</Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ModalAddStatusZone;
