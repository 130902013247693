import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDailyAverageRegistersBetweenDates as getDailyAverageRegistersBetweenDatesApi,
  getRegistersBetweenDates as getRegistersBetweenDatesApi
} from "../../helpers/fakebackend_helper";

export const getDailyAverageRegistersBetweenDates = createAsyncThunk(
  "register/getDailyAverageRegistersBetweenDates",
  async ({ startDate, endDate, zoneId }: { startDate: string; endDate: string; zoneId: string }) => {
    try {
      const response = await getDailyAverageRegistersBetweenDatesApi(startDate, endDate, zoneId);
      return response;
    } catch (error: any) {
      return { error: error.message };
    }
  }
);

export const getRegistersBetweenDates = createAsyncThunk(
  "register/getRegistersBetweenDates",
  async ({ startDate, endDate, zoneId }: { startDate: string; endDate: string; zoneId: string }) => {
    try {
      const response = await getRegistersBetweenDatesApi(startDate, endDate, zoneId);
      return response;
    } catch (error: any) {
      return { error: error.message };
    }
  }
);

export const refreshFavoritesRecords = createAsyncThunk(
  "register/refreshFavoritesRecords",
  async (zoneId: string) => {
    try {
      /*  const response = await refreshFavoritesRecordsApi(zoneId);
       return response; */
    } catch (error: any) {
      return { error: error.message };
    }
  }
);
