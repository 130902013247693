import React, { useMemo, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Table,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Select from 'react-select';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Formik, Form, Field, FieldProps } from 'formik';

type MainVariable = {
  id: number;
  name: string;
};

type Props = {
  mainVariables: MainVariable[];
  isOpen: boolean;
  toggle: () => void;
  connections: any[];
};

const MainVariablesBySection: React.FC<Props> = ({ mainVariables, isOpen, toggle, connections }) => {
  const [selectedVariables, setSelectedVariables] = useState<MainVariable[]>([]);

  if (mainVariables && mainVariables.length > 0) {
    const selectOptions = mainVariables.map(mv => ({
      value: mv.id,
      label: mv.name
    }));
  }

  const selectStyles = {
    control: (base: any) => ({
      ...base,
      borderRadius: '25px',
    }),
  };

  /* const availableOptions = useMemo(() => {
    const selectedIds = new Set(selectedVariables.map(v => v.id)); // Guarda los IDs de las variables seleccionadas
    return mainVariables.filter(mv => !selectedIds.has(mv.id)).map(mv => ({
      value: mv.id,
      label: mv.name,
    }));
  }, [mainVariables, selectedVariables]); */

  const availableOptions = useMemo(() => {
    if (!mainVariables || mainVariables.length === 0 || !selectedVariables || selectedVariables.length === 0) {
      return [];
    }

    const selectedIds = new Set(selectedVariables.map(v => v.id));
    return mainVariables.filter(mv => !selectedIds.has(mv.id)).map(mv => ({
      value: mv.id,
      label: mv.name,
    }));
  }, [mainVariables, selectedVariables]);

  const addVariable = (selectedOption: { value: number; label: string } | null) => {
    if (selectedOption) {
      const exists = selectedVariables.find((variable) => variable.id === selectedOption.value);
      if (!exists) {
        setSelectedVariables((prev) => [
          ...prev,
          {
            id: selectedOption.value,
            name: selectedOption.label,
          },
        ]);
      }
    }
  };

  const connectionOptions = connections.map(conn => ({
    value: conn.id,
    label: conn.name,
  }));

  const removeVariable = (id: number) => {
    setSelectedVariables((prev) => prev.filter((variable) => variable.id !== id));
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(selectedVariables);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedVariables(items);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Gestión de Variables Principales</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{ selectDevice: false, deviceName: '', selectedDevice: null }}
          onSubmit={(values) => {
            console.log("Form values:", values);
            console.log("Selected Variables:", selectedVariables);
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className="form-check form-switch form-switch-lg mb-3">
                <Label className="form-check-label" htmlFor="selectDevice">
                  Seleccionar dispositivo
                </Label>
                <Field name="selectDevice" type="checkbox" className="form-check-input" id="selectDevice" />
              </div>

              <FormGroup>
                <Label>Variable principal</Label>
                <Select
                  options={availableOptions}
                  onChange={(option: any) => addVariable(option)}
                  styles={selectStyles}
                  placeholder="Seleccione una variable principal"
                />
              </FormGroup>

              <Label className="mt-3">Variables seleccionadas</Label>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-main-variables">
                  {(provided: any) => (
                    <Table className="table table-hover align-middle border-0" ref={provided.innerRef} {...provided.droppableProps}>
                      <thead className="border-0">
                        <tr>
                          <th>Nombre</th>
                          <th className="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedVariables.map((variable, index) => (
                          <Draggable key={variable.id.toString()} draggableId={variable.id.toString()} index={index}>
                            {(provided: any) => (
                              <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="border-bottom">
                                <td>{variable.name}</td>
                                <td className="text-center">
                                  <Button color="danger" size="sm" onClick={() => removeVariable(variable.id)}>
                                    <i className="mdi mdi-delete"></i>
                                  </Button>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    </Table>
                  )}
                </Droppable>
              </DragDropContext>

              <Button type="submit" color="primary" className="mt-3 rounded-pill">Guardar</Button>
              <Button color="secondary" className="mt-3 ml-2 rounded-pill" onClick={toggle}>Cerrar</Button>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>

  );
};

export default MainVariablesBySection;
