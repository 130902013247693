import BreadCrumb from 'Components/Common/BreadCrumb';
import { SearchTable } from 'pages/Tables/ReactTables/ReactTable';
import React, { useEffect } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import GeneralData from 'pages/Dashboard/GeneralData';
import InstallationsList from './InstallationsList';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';




const Installations = () => {
  
    document.title = "Instalaciones | Ecentroserver";
    return (
        <React.Fragment>
               <BreadCrumb pageTitle="Listado de instalaciones" />
            <div className="page-content">
                <Container fluid>
                 
                    <Col lg={12} className="order-xxl-0 order-first">
                      {/*   <GeneralData /> */}
                    </Col>
                    <Row>
                        <Col xs={12}>
                            <InstallationsList />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Installations;