import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getAlarmsAndFaultsByConnection as getAlarmsAndFaultsByConnectionApi,
  createCustomAlarmOrFault as createCustomAlarmOrFaultApi,
  updateCustomAlarmOrFault as updateCustomAlarmOrFaultApi,
  deleteCustomAlarmOrFault as deleteCustomAlarmOrFaultApi,
} from "../../helpers/fakebackend_helper";


export const getAlarmsAndFaultsByConnection = createAsyncThunk("alarmsAndFaults/getAlarmsAndFaultsByConnection", async (connectionId: number) => {
  try {
    const response = await getAlarmsAndFaultsByConnectionApi(connectionId);
   
    return response;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});


export const createCustomAlarmOrFault = createAsyncThunk("alarmsAndFaults/createCustomAlarmOrFault", async (alarmOrFaultData: any) => {
  try {
    const response = await createCustomAlarmOrFaultApi(alarmOrFaultData);
  
    return response;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});


export const updateCustomAlarmOrFault = createAsyncThunk("alarmsAndFaults/updateCustomAlarmOrFault", async (alarmOrFaultData: any) => {

  try {
    const response = await updateCustomAlarmOrFaultApi(alarmOrFaultData);

    return alarmOrFaultData;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }


})

export const deleteCustomAlarmOrFault = createAsyncThunk("alarmsAndFaults/deleteCustomAlarmOrFault", async (alarmOrFaultId: number) => {
  try {
    const response = await deleteCustomAlarmOrFaultApi(alarmOrFaultId);
    return alarmOrFaultId;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});


