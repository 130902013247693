import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getParameters as getParametersApi,
  getParameter as getParameterApi,
  getValueModbusByParameter as getValueModbusByParameterApi,
  createParameter as createParameterApi,
  updateParameter as updateParameterApi,
  deleteParameter as deleteParameterApi,
} from "../../helpers/fakebackend_helper";
import { updateParameterBackupValue, updateParametersBackupByConnection } from "slices/parametersBackup/reducer";
import { isMotorDataParameter } from "devices/vacon";

export const getParameters = createAsyncThunk("parameter/getParameters", async (connectionId: number) => {
  try {
    const response = await getParametersApi(connectionId);

    return response;
  } catch (error) {
    return error;
  }
});



export const getValueModbusByParameter = createAsyncThunk(
  "parameter/getValueModbusByParameter",
  async (parameterInfo: {
    connection_id: number;
    parameter_id: any;
    id_map_parameter?: any,
    parameterValues: any,
    parametersBackup: any,
    bit_parameter_id?: number
  }, { dispatch, rejectWithValue }) => {
    try {


      const response: any = await getValueModbusByParameterApi({
        connection_id: parameterInfo.connection_id,
        parameter_id: parameterInfo.parameter_id,
        ...(parameterInfo.id_map_parameter && { id_map_parameter: parameterInfo.id_map_parameter }),
        ...(parameterInfo.bit_parameter_id && { bit_parameter_id: parameterInfo.bit_parameter_id })
      });
      
      if(parameterInfo.bit_parameter_id) {
        return response
      }


      const newParameterBackups = parameterInfo.parametersBackup.map((parameterBackup: any) => {
        if (parameterBackup.parameter_id === parameterInfo.parameter_id.toString()) {
          console.log(`Actualizando valor en parametersBackup con conexion ${parameterInfo.connection_id} para parameter_id ${parameterInfo.parameter_id}:`, response.value);
          return {
            ...parameterBackup,
            value: response.value
          };
        }
        return parameterBackup;
      });

      console.log("newParameterBackups", newParameterBackups);


      dispatch(updateParametersBackupByConnection({
        connection_id: parameterInfo.connection_id,
        parametersBackup: newParameterBackups,
        parameterValues: parameterInfo.parameterValues,
        parameter_id: parameterInfo.parameter_id,
      }));


      const updatedParameterInfo = {
        ...parameterInfo,
        value: response.value
      };

      console.log("updatedParameterInfo", updatedParameterInfo);

      dispatch(updateParameterBackupValue(updatedParameterInfo));


      return updatedParameterInfo;
    } catch (error: any) {
      console.error("Error en getValueModbusByParameter:", error.message);
      return rejectWithValue({ error: error.message }); // Maneja el error según tu lógica
    }
  }
);




export const getParameter = createAsyncThunk("parameter/getParameter", async (parameterId: number) => {
  try {
    const response = await getParameterApi(parameterId);
    return response;
  } catch (error) {
    return error;
  }
});

export const createParameter = createAsyncThunk("parameter/createParameter", async (parameter: any) => {
  try {

    const response: any = await createParameterApi(parameter);
    if (response.id) {
      parameter.id = response.id;
      parameter.supervision_name = response.supervision_name;
    }
    return parameter;
  } catch (error) {
    return { 'error': error };
  }
});


export const updateParameter = createAsyncThunk(
  "parameter/updateParameter",
  async (parameterInfo: { connectionId: number; parameter_id: any; value: any }, { rejectWithValue }) => {
    try {
      const response = await updateParameterApi(parameterInfo); // Asegúrate de que esta función maneje el objeto completo
      return response.data; // Ajusta esto según cómo tu API devuelva los datos
    } catch (error: any) {
      return rejectWithValue({ error: error.message }); // Maneja el error según tu lógica
    }
  }
);

export const deleteParameter = createAsyncThunk("parameter/deleteParameter", async (parameter: any) => {
  try {

    const response = await deleteParameterApi(parameter);
    return parameter;
  } catch (error) {
    return error;
  }
});
