import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Label, FormGroup, UncontrolledTooltip, Col, Row } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { createInstallationZone, deleteInstallationZone, getInstallationZones, reorderInstallationZones, updateInstallationZone } from 'slices/installationZones/thunk';
import * as Yup from 'yup';
import { Field, FieldProps, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import ModalConfirm from 'Components/Common/Modals/ModalConfirm';

interface Zone {
  id: number;
  zone_name: string;
  zone_description: string;
  is_active: boolean;
  installation_id: number;
}

interface ModalInstallationZonesListProps {
  isOpen: boolean;
  toggle: () => void;
  idSelected: number;
}

const ModalInstallationZonesList: React.FC<ModalInstallationZonesListProps> = ({ isOpen, toggle, idSelected }) => {

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.InstallationZones;
  const installationZoneDataProperties = createSelector(
    selectLayoutState,
    (state: any) => state
  );
  const { installationZones } = useSelector(installationZoneDataProperties);

  const zoneValidationSchema = Yup.object().shape({
    zone_name: Yup.string()
      .required('El nombre de la zona es obligatorio'),
    zone_description: Yup.string()
  });

  const [zones, setZones] = useState<Zone[]>(installationZones);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [addModal, setAddModal] = useState<boolean>(false); // Nuevo estado para el modal de añadir zona
  const [currentZone, setCurrentZone] = useState<Zone | null>(null);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  useEffect(() => {
    setZones(installationZones);
  }, [installationZones])

  useEffect(() => {
    if (idSelected)
      dispatch(getInstallationZones(idSelected));
  }, [isOpen]);

  const toggleEditModal = () => {
    if (editModal) {
      setCurrentZone(null); // Clear current zone on close
    }
    setEditModal(!editModal);
  };

  const toggleAddModal = () => {
    setAddModal(!addModal);
  };

  const handleZoneClick = (zone: Zone) => {
    setCurrentZone(zone);
    toggleEditModal();
  };

  const handleUpdateZone = () => {
    if (currentZone && currentZone.zone_name !== '') {
      const updatedZones = zones.map(zone => zone.id === currentZone.id ? { ...zone, zone_name: currentZone.zone_name } : zone);
      setZones(updatedZones);
      toggleEditModal();
    }
  };

  const handleDeleteZone = (zone: any) => {
    setCurrentZone(zone);
    toggleDeleteConfirmationModal();
  };

  const onDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination || destination.index === source.index) {
      return;
    }
    const newZones = Array.from(zones);
    const [relocatedItem] = newZones.splice(source.index, 1);
    newZones.splice(destination.index, 0, relocatedItem);
    setZones(newZones);

    dispatch(reorderInstallationZones(newZones));
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: "none",
    background: isDragging ? "rgba(255, 255, 255, 0.8)" : '',
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? "#F0F0F0" : '',
    width: '100%'
  });

  const handleAddZone = async (values: any) => {
    const response = await dispatch(createInstallationZone(values));
    if (!response.payload.error) {
      toast.success('Zona creada con éxito!');
      dispatch(getInstallationZones(idSelected)); // Actualizar las zonas
    } else {
      toast.error('Error al crear la zona');
    }
  };

  const handleEditZone = async (values: any) => {
    const response = await dispatch(updateInstallationZone(values));

    if (!response.error) {
      toast.success('Zona editada con éxito!');
      dispatch(getInstallationZones(idSelected)); // Actualizar las zonas
    } else {
      toast.error('Error al editar la zona');
    }
  };

  const toggleDeleteConfirmationModal = () => {
    setDeleteConfirmationModal(!deleteConfirmationModal)
  }

  const handleConfirmDeleteInstallationZone = async (installationZoneId: number | null) => {
    setDeleteConfirmationModal(false);
    try {
      if (installationZoneId) {
        const trial = await dispatch(deleteInstallationZone(currentZone)).unwrap();
        toast.success('Zona eliminada con éxito!');
        dispatch(getInstallationZones(idSelected)); // Actualizar las zonas
      }
    } catch (error: any) {
      toast.error('Error al eliminar la instalación: ' + error.message);
    }
  }

  const handleCancelDeleteInstallationZone = () => {
    setDeleteConfirmationModal(false);
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          Zonas de instalación

        </ModalHeader>
        <ModalBody>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-zones">
              {(provided: any, snapshot: any) => (
                <Table className="table table-striped mb-5" style={getListStyle(snapshot.isDraggingOver)}>
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "120px" }}>Nombre</th>
                      <th scope="col" style={{ width: "240px" }}>Descripción</th>
                      <th scope="col" style={{ width: "240px" }}>Activo</th>
                      <th scope="col" style={{ width: "160px" }} className='text-center'>Acciones</th>
                    </tr>
                  </thead>
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {zones.map((zone, index) => (
                      <Draggable key={zone.id} draggableId={zone.id.toString()} index={index}>
                        {(provided: any, snapshot: any) => (
                          <tr
                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}>
                            <td style={{ width: "350px" }} className='py-1'>{zone.zone_name}</td>
                            <td style={{ width: "350px" }} className='py-1'>{zone.zone_description}</td>
                            <td style={{ width: "110px" }} className='py-1'>{zone.is_active ?
                              <i className="ms-3 mdi mdi-check-bold fs-3 text-success" aria-hidden="true"></i>
                              : <i className="ms-3 mdi mdi-close-thick text-danger fs-3" aria-hidden="true"></i>}</td>
                            <td style={{ width: "350px" }} className='text-center py-1'>
                              <Button id={`editZoneButton${zone.id}`} className="btn btn-sm me-2" color='none' onClick={() => handleZoneClick(zone)}>
                                <i className='mdi mdi-pencil fs-6'></i>
                              </Button>
                              <UncontrolledTooltip placement="top" target={`editZoneButton${zone.id}`}>Editar zona</UncontrolledTooltip>
                              <Button id={`deleteZoneButton${zone.id}`} color="danger" className='btn btn-sm me-2' onClick={() => handleDeleteZone(zone)}>
                                <i className='mdi mdi-delete fs-6'></i>
                              </Button>
                              <UncontrolledTooltip placement="top" target={`deleteZoneButton${zone.id}`}>Eliminar zona</UncontrolledTooltip>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button color="primary" className="rounded-pill" style={{ backgroundColor: '#007bff', borderColor: '#007bff' }} onClick={toggleAddModal}>
            Añadir Zona
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal para Añadir Zona */}
      {addModal && (
        <Modal isOpen={addModal} toggle={toggleAddModal}>
          <ModalHeader toggle={toggleAddModal}>Añadir Zona</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                id: null,
                zone_name: '',
                zone_description: '',
                installation_id: idSelected,
                is_active: true
              }}
              validationSchema={zoneValidationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleAddZone(values);
                resetForm();
                setSubmitting(false);
                toggleAddModal();
              }}
            >
              {formikProps => (
                <Form>
                  <FormGroup className="col-md-12">
                    <Field
                      name="zone_name"
                      type="text"
                      className={`form-control rounded-pill ${formikProps.errors.zone_name && formikProps.touched.zone_name ? 'is-invalid' : ''}`}
                      placeholder="Nombre de la zona"
                      maxLength={20}
                    />
                    {formikProps.errors.zone_name && formikProps.touched.zone_name && (
                      <div className="invalid-feedback d-block">{formikProps.errors.zone_name}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="col-md-12">
                    <Field
                      name="zone_description"
                      type="text"
                      className="form-control rounded-pill"
                      placeholder="Descripción de la zona"
                    />
                  </FormGroup>
                  <Col md={12} className='mt-2 d-flex align-items-center'>
                    <Row className="align-items-center">
                      <Col className="pr-0">
                        <Label className="form-check-label" for="activeSwitch">Zona activa</Label>
                      </Col>
                      <Col xs="auto" className='p-0'>
                        <div className="form-check form-switch form-switch-lg">
                          <Field name="is_active">
                            {({ field }: FieldProps) => (
                              <input
                                {...field}
                                type="checkbox"
                                className="form-check-input"
                                id="activeSwitch"
                                checked={field.value}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <ModalFooter>
                    <Button color="secondary" className='rounded-pill' onClick={toggleAddModal}>Cancelar</Button>
                    <Button type="submit" color="primary" className='rounded-pill'>Guardar cambios</Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      )}

      {/* Modal para Editar Zona */}
      {currentZone && (
        <Modal isOpen={editModal} toggle={toggleEditModal}>
          <ModalHeader toggle={toggleEditModal}>Editar Zona</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                id: currentZone.id,
                zone_name: currentZone.zone_name,
                zone_description: currentZone.zone_description,
                installation_id: currentZone.installation_id,
                is_active: currentZone.is_active
              }}
              validationSchema={zoneValidationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleEditZone(values);
                resetForm();
                setSubmitting(false);
                toggleEditModal();
              }}
            >
              {formikProps => (
                <Form>
                  <FormGroup className="col-md-12">
                    <Field
                      name="zone_name"
                      type="text"
                      className={`form-control rounded-pill ${formikProps.errors.zone_name && formikProps.touched.zone_name ? 'is-invalid' : ''}`}
                      placeholder="Nombre de la zona"
                      maxLength={20}
                    />
                    {formikProps.errors.zone_name && formikProps.touched.zone_name && (
                      <div className="invalid-feedback d-block">{formikProps.errors.zone_name}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="col-md-12">
                    <Field
                      name="zone_description"
                      type="text"
                      className="form-control rounded-pill"
                      placeholder="Descripción de la zona"
                    />
                  </FormGroup>
                  <Col md={12} className='mt-2 d-flex align-items-center'>
                    <Row className="align-items-center">
                      <Col className="pr-0">
                        <Label className="form-check-label" for="activeSwitch">Zona activa</Label>
                      </Col>
                      <Col xs="auto" className='p-0'>
                        <div className="form-check form-switch form-switch-lg">
                          <Field name="is_active">
                            {({ field }: FieldProps) => (
                              <input
                                {...field}
                                type="checkbox"
                                className="form-check-input"
                                id="activeSwitch"
                                checked={field.value}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <ModalFooter>
                    <Button color="secondary" className='rounded-pill' onClick={toggleEditModal}>Cerrar</Button>
                    <Button type="submit" color="primary" className='rounded-pill'>Guardar Cambios</Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      )}

      <ModalConfirm
        isOpen={deleteConfirmationModal}
        toggle={toggleDeleteConfirmationModal}
        title={"Borrar Zona"}
        message={`¿Estás seguro de que quieres borrar  ${currentZone?.zone_name} ?`}
        onConfirm={handleConfirmDeleteInstallationZone}
        onCancel={handleCancelDeleteInstallationZone}
        idSelected={currentZone ? Number(currentZone.id) : null}
        iconName=' mdi mdi-delete display-5 text-danger'
      />
    </div>
  );
};

export default ModalInstallationZonesList;
