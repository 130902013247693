import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getConnections as getConnectionsApi,
  getUnconfiguredActiveConnections as getUnconfiguredActiveConnectionsApi,
  getSupervisorsByZone as getSupervisorsByZoneApi,
  createConnection as createConnectionApi,
  reorderConnections as reorderConnectionsApi,
  updateConnection as updateConnectionApi,
  deleteConnection as deleteConnectionApi,
} from "../../helpers/fakebackend_helper";
import { updateDevice, reorderConnectionsInNavbar, deleteDevice } from "slices/installations/reducer";
import { deleteCalculatedParameters } from "slices/calculatedParameters/reducer";

export const getConnections = createAsyncThunk("connection/getConnections", async () => {
  try {
    const response = await getConnectionsApi();


    return response;
  } catch (error) {
    return error;
  }
});

export const getSupervisorsByZone = createAsyncThunk("connection/getSupervisorsByZone", async (zoneID: any) => {
  try {
    const response = await getSupervisorsByZoneApi(zoneID);


    return response;
  } catch (error) {
    return error;
  }
});


export const getUnconfiguredActiveConnections = createAsyncThunk("connection/getUnconfiguredActiveConnections", async (zoneID: any) => {
  try {
    const response = await getUnconfiguredActiveConnectionsApi(zoneID);



    return response;
  } catch (error) {
    return error;
  }
});


export const reorderConnections = createAsyncThunk(
  "connection/reorderConnections",
  async ({ connections, installationId, zoneId }: { connections: any, installationId: number, zoneId: number }, { dispatch }) => {
    try {
      const connectionsIds = connections.map((connection: any) => connection.id);

      const payload = { connections_ids: connectionsIds, installation_id: installationId, zone_id: zoneId };
      const response = await reorderConnectionsApi(payload);
      dispatch(reorderConnectionsInNavbar({ connections, installationId, zoneId }));
      return connections;
    } catch (error) {
      return error;
    }
  }
);

export const createConnection = createAsyncThunk("connection/createConnection", async (connection: any) => {
  try {

    const response: any = await createConnectionApi(connection);
    if (response.id) {
      connection.id = response.id;
      connection.supervision_name = response.supervision_name;
    }
    return connection;
  } catch (error) {
    return { 'error': error };
  }
});

export const updateConnection = createAsyncThunk(
  "connection/updateConnection",
  async (connection: any, { dispatch }) => {
    try {
   
      const response = await updateConnectionApi(connection);
      dispatch(updateDevice(connection));
      return connection;
    } catch (error) {
      return error;
    }
  });

export const deleteConnection = createAsyncThunk(
  "connection/deleteConnection",
  async (connection: any, { dispatch }) => {
    
    try {
     
      const response = await deleteConnectionApi(connection.id);
      dispatch(deleteDevice(connection));
      dispatch(deleteCalculatedParameters(connection.id))
      return connection;
    } catch (error) {
      return error;
    }
  });
