import { createSlice } from "@reduxjs/toolkit";
import {
  getDailyAverageRegistersBetweenDates,
  getRegistersBetweenDates,
  refreshFavoritesRecords
} from "../../slices/registers/thunk";

export const initialState: any = {
  registers: {},
  selectedOptions: {},
  loading: false,
  error: null
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setZoneSelection: (state, action) => {
      const { zoneId, selectedYear, selectedMonth, selectedWeek, selectedDay, selectionMode, startDate, endDate  } = action.payload;
      state.selectedOptions[zoneId] = {
        selectedYear: selectedYear,
        selectedMonth: selectedMonth,
        selectedWeek: selectedWeek,
        selectedDay: selectedDay,
        selectionMode: selectionMode,
        startDate: startDate,
        endDate: endDate
      };
    },
  },
  extraReducers: (builder) => {
    builder

    .addCase(getDailyAverageRegistersBetweenDates.fulfilled, (state, action) => {
      const { zoneId } = action.meta.arg; 
      state.loading = false;
      state.registers[zoneId] = action.payload;  // Guardamos los datos bajo el zoneId
    })
      .addCase(getDailyAverageRegistersBetweenDates.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload.error || null;
      })

      .addCase(getRegistersBetweenDates.fulfilled, (state, action) => {
        const { zoneId } = action.meta.arg;  // Extraemos el zoneId del argumento
        state.loading = false;
        state.registers[zoneId] = action.payload;  // Guardamos los datos bajo el zoneId
      })

      .addCase(getRegistersBetweenDates.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload.error || null;
      })

      .addCase(refreshFavoritesRecords.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.registers = action.payload;
      })
      .addCase(refreshFavoritesRecords.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload.error || null;
      });
  },
});

export const { setZoneSelection } = registerSlice.actions;
export default registerSlice.reducer;
