import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthorization } from "../helpers/api_helper";
import { useProfile } from "../Components/Hooks/UserHooks";
import { checkTokenUser, logoutUser } from "../slices/auth/login/thunk";
import { createSelector } from "reselect";
import { SocketContext } from "context/SocketProvider";
import { use } from "i18next";
import { Spinner } from "reactstrap";
import { getInstallations } from '../slices/installations/thunk'
import { getFaultHistoriesNotAcknowledged } from 'slices/faultHistory/thunk';
import { getAlarmHistoriesNotAcknowledged } from 'slices/alarmHistory/thunk';

const AuthProtected = (props: any) => {
  const dispatch: any = useDispatch();
  const { userProfile, loading, token } = useProfile();
  const [isTokenVerified, setIsTokenVerified] = useState<boolean | null>(null);

  const selectUsersState = (state: any) => state.Login;
  const userDataProperties = createSelector(selectUsersState, (state) => state);
  const user = useSelector(userDataProperties);
  const socket = useContext(SocketContext);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (token) {

      const verifyToken = async () => {

        const tokenIsValid = await dispatch(checkTokenUser(token));

        setIsTokenVerified(tokenIsValid);
        if (tokenIsValid) {

          setAuthorization(token);
          await Promise.all([
            dispatch(getInstallations()),
            /*   dispatch(getAlarmHistoriesNotAcknowledged()),
              dispatch(getFaultHistoriesNotAcknowledged()) */
          ]);
          setIsDataLoaded(true);


        } else {
          dispatch(logoutUser());
          // No se debe devolver un componente en useEffect. En su lugar, maneje la redirección con un estado.
        }
      };
      verifyToken();
    } else {
      setIsTokenVerified(false);
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (socket) {
      socket.emit('authenticate', { token: token });
    }
  }, [socket])

  // Maneja la redirección si el token no es válido
  if (isTokenVerified === false) {
    return <Navigate to="/login" replace />;
  }

  // Si isTokenVerified es null, significa que la verificación aún está en proceso
  if (isTokenVerified === null || !isDataLoaded) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner />
      </div>
    );
  }

  // Renderiza los hijos si el token está verificado
  return <>{props.children}</>;
};

export default AuthProtected;
