import { createSlice } from "@reduxjs/toolkit";
import {
  createUser,
  getUsers,
  deleteUser,
  updateUser
} from "./thunk";
import { getSupervisorsByZone } from "slices/thunks";



export const initialState: any = {
  users: [],
};


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},

  extraReducers: (builder) => {

    builder.addCase(getUsers.fulfilled, (state: any, action: any) => {
      state.users = action.payload;
    });
    builder.addCase(getUsers.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getSupervisorsByZone.fulfilled, (state: any, action: any) => {
      state.supervisors = action.payload;
    });
    builder.addCase(getSupervisorsByZone.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(createUser.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        state.users.push(action.payload);
      }

    });

    builder.addCase(createUser.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateUser.fulfilled, (state: any, action: any) => {
      state.users = (state.users || []).map((user: any) =>
        user.id.toString() === action.payload.id.toString()
          ? { ...user, ...action.payload }
          : user
      );
    });

    builder.addCase(updateUser.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteUser.fulfilled, (state: any, action: any) => {
      state.users = state.users.filter(
        (user: any) => user.id.toString() !== action.payload.toString()
      );
    });

    builder.addCase(deleteUser.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});

export default userSlice.reducer;