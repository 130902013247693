import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getFaultsByConnectionID as getFaultsByConnectionIDApi,


} from "../../helpers/fakebackend_helper";
import { connect } from "http2";


export const getFaultsByConnectionID = createAsyncThunk("fault/getFaultsByConnectionID", async (connectionID: number) => {

  try {
    const response = await getFaultsByConnectionIDApi(connectionID);

    return response;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});

