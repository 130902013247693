import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getStatus as getStatusApi,
  createStatus as createStatusApi,
  updateStatus as updateStatusApi,
  deleteStatus as deleteStatusApi,

} from "../../helpers/fakebackend_helper";

export const getStatus = createAsyncThunk("statis/getStatus", async (zoneID: number) => {
  try {
    const response = await getStatusApi(zoneID);
    return response;
  } catch (error) {
    return error;
  }
});


export const createStatus = createAsyncThunk(
  "status/createStatus",
  async (status: any, { dispatch }) => {
    try {
      const response: any = await createStatusApi(status);

      if (response.id) {
        status.id = response.id;
      }

      dispatch(createStatus(status));

      return status;

    } catch (error) {
      return { 'error': error || 'An error occurred during the creation proces' };
    }
  }
);

export const updateStatus = createAsyncThunk(
  "status/updateStatus",
  async (installationZone: any, { dispatch }) => {
    try {
      const response = await updateStatusApi(installationZone);
      dispatch(updateStatus(installationZone));
      return installationZone;
    } catch (error) {
      return error;
    }
  });


export const deleteStatus = createAsyncThunk(
  "status/deleteStatus",
  async (zone: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await deleteStatusApi(zone.id);
      if (response) {
        // Despacha la acción deleteZone definida en el otro slice con el id de la zona
        dispatch(deleteStatus(zone.id));
      }
      return zone.id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);