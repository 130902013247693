import { update } from "lodash";

const idsInputPressureSupervision = [1689, 1690, 1691, 1692];
const idsActualValue = [1033, 1034, 1056, 736, 758, 336, 337, 338, 339, 20, 21, 15541, 15542, 22, 167, 168]
const idsExternalActualValue = [1664, 1665, 1637, 1660, 1661, 1653, 1654, 83, 84, 85, 86]
interface CheckInputParams {
    unit_parameter?: number;
    decimal_parameter: number;
    affectedParameters: number[];
    parameter_id?: number;
    parametersBackup: any;
    parameterValues?: any;
    connectionId?: string;
    updatedDataBase: boolean;
}

export const unitAndDecimalsDanfossVacon100Flow = (
    parameter_id: any,
    parametersBackup: any,
    parameterValues: any,
    connectionId: any,
) => {
    let filteredDeviceParametersCache;

    filteredDeviceParametersCache = checkUnitAndDecimals({
        unit_parameter: 1687,
        decimal_parameter: 1688,
        affectedParameters: idsInputPressureSupervision,
        parameter_id: parameter_id,
        parametersBackup: parametersBackup,
        parameterValues: parameterValues,
        connectionId: connectionId,
        updatedDataBase: true
    });

    filteredDeviceParametersCache = checkUnitAndDecimals({
        unit_parameter: 1036,
        decimal_parameter: 1035,
        affectedParameters: idsActualValue,
        parameter_id: parameter_id,
        parametersBackup: filteredDeviceParametersCache,
        parameterValues: parameterValues,
        connectionId: connectionId,
        updatedDataBase: true
    });

    filteredDeviceParametersCache = checkUnitAndDecimals({
        unit_parameter: 1635,
        decimal_parameter: 1666,
        affectedParameters: idsExternalActualValue,
        parameter_id: parameter_id,
        parametersBackup: filteredDeviceParametersCache,
        parameterValues: parameterValues,
        connectionId: connectionId,
        updatedDataBase: true
    });


    filteredDeviceParametersCache = checkAndDisableParameter({
        parameter_id: parameter_id,
        parametersBackup: filteredDeviceParametersCache,
        parameterValues: parameterValues
    });

    filteredDeviceParametersCache = toggleModbusRtuIds({
        parameter_id: parameter_id,
        parametersBackup: filteredDeviceParametersCache,
        parameterValues: parameterValues
    })

    filteredDeviceParametersCache = checkAnalogConditions({
        parameter_id: parameter_id,
        parametersBackup: filteredDeviceParametersCache,
        parameterValues: parameterValues
    });


    filteredDeviceParametersCache = updateMinMaxForParameters(parameter_id, filteredDeviceParametersCache);



    return filteredDeviceParametersCache

};
const checkUnitAndDecimals = ({
    unit_parameter,
    decimal_parameter,
    affectedParameters,
    parameter_id,
    parametersBackup,
    parameterValues,
    connectionId,
    updatedDataBase
}: CheckInputParams) => {

    const unitValue = parametersBackup.find((p: any) => unit_parameter && p.parameter_id === unit_parameter.toString())?.value;
    const unitTextValue = parameterValues && parameterValues.find((p: any) => unit_parameter && parseInt(p.value) === parseInt(unitValue))?.textValue;
    const decimalValue = parametersBackup.find((p: any) => p.parameter_id === decimal_parameter.toString())?.value;


    let updatedParameter;

    const updatedParameters = parametersBackup.map((parameter: any) => {


        /*  if (parseInt(parameter.parameter_id) == unit_parameter) {
 
             updatedParameter = {
                 value: parameter.value,
             }
             console.log("updatedParameter Backup", updatedParameter)
               updateParameterBackup(parameter.backup_id, updatedParameter) 
 
         }
 
         if (parseInt(parameter.parameter_id) == decimal_parameter) {
             updatedParameter = {
                 value: parameter.value,
             }
               updateParameterBackup(parameter.backup_id, updatedParameter) 
 
         } */


        if (unit_parameter && parameter_id === unit_parameter && (affectedParameters.includes(parseInt(parameter.parameter_id))) && connectionId) {
            updatedParameter = {
                parameter_id: parameter.id,
                connection_id: parseInt(connectionId),
                value: parameter.value,
                decimals: parameter.decimals,
                unit: unitTextValue
            }
            if (updatedDataBase)
                /*  updateParameterBackup(parameter.backup_id, updatedParameter) */
                return { ...parameter, unit: unitTextValue };
        }


        if (((parameter_id === decimal_parameter && updatedDataBase) || !updatedDataBase) && affectedParameters.includes(parseInt(parameter.parameter_id)) && connectionId

        ) {
            const formattedMin = decimalValue !== undefined ? formatDecimalValue(parameter.min, decimalValue) : parameter.min;
            const formattedMax = decimalValue !== undefined ? formatDecimalValue(parameter.max, decimalValue) : parameter.max;
            const formattedValue = decimalValue !== undefined ? formatDecimalValue(parameter.value, decimalValue) : parameter.value;

            updatedParameter = {
                parameter_id: parameter.id,
                connection_id: parseInt(connectionId),
                value: formattedValue,
                decimals: parseInt(decimalValue),
                unit: parameter.unit
            }

            if (updatedDataBase)
                /*  updateParameterBackup(parameter.backup_id, updatedParameter) */
                return {
                    ...parameter,
                    min: formattedMin,
                    max: formattedMax,
                    value: formattedValue,
                    decimals: parseInt(decimalValue)
                }
        }

        return parameter;
    });

    return updatedParameters;
};


const formatDecimalValue = (value: string, decimalCountStr: string) => {
    if (value === undefined || value === null) return value;

    const originalSeparatorIsComma = value.includes(',');

    const normalizedValue = originalSeparatorIsComma ? value.replace(',', '.') : value;

    const decimalCount = parseInt(decimalCountStr, 10);
    if (isNaN(decimalCount)) return value;

    const decimalPointIndex = normalizedValue.indexOf('.');

    if (decimalPointIndex !== -1) {

        const decimalPart = normalizedValue.substring(decimalPointIndex + 1);
        let result;
        if (decimalPart.length >= decimalCount) {
            result = normalizedValue.substring(0, decimalPointIndex + decimalCount + 1);
        } else {
            // Decide si añadir 9s o 0s al final.
            const lastDigit = decimalPart[decimalPart.length - 1] || '0';
            const fillChar = lastDigit === '9' ? '9' : '0';
            result = normalizedValue + fillChar.repeat(decimalCount - decimalPart.length);
        }
        return originalSeparatorIsComma ? result.replace('.', ',') : result;
    } else {
        // Si el valor no tiene parte decimal.
        const fillChar = '0'; // Aquí siempre añadiremos 0s ya que no hay un último dígito decimal para verificar.
        const resultWithDot = normalizedValue + '.' + fillChar.repeat(decimalCount);
        return originalSeparatorIsComma ? resultWithDot.replace('.', ',') : resultWithDot;
    }
};

export const updatedMinandMax = (parametersBackup: any): any => {
    let updatedDeviceParametersCache: any = {};

    Object.entries(parametersBackup).forEach(([key, deviceParameters]: [string, any]) => {

        let updatedParameters: any = checkUnitAndDecimals({
            decimal_parameter: 1688,
            affectedParameters: idsInputPressureSupervision,
            parametersBackup: deviceParameters.parameters,
            connectionId: key,
            updatedDataBase: false
        });

        updatedParameters = checkUnitAndDecimals({
            decimal_parameter: 1035,
            affectedParameters: idsActualValue,
            parametersBackup: updatedParameters,
            connectionId: key,
            updatedDataBase: false
        });

        updatedParameters = checkUnitAndDecimals({
            decimal_parameter: 1666,
            affectedParameters: idsExternalActualValue,
            parametersBackup: updatedParameters,
            connectionId: key,
            updatedDataBase: false
        });


        updatedDeviceParametersCache[key] = {
            ...deviceParameters,
            parameters: updatedParameters
        };
    });

    return updatedDeviceParametersCache;
};


export const isParameterInLogicProgrammerMode = (parametersBackup: any, parameter: any): boolean => {

    const filteredParameters = parametersBackup.filter((p: any) => p.index.startsWith('P'));

    const logicProgrammerParameters = filteredParameters.filter((p: any) => p.subgroup_1 === 'Programador de Lógicas');

    const blockParameters = logicProgrammerParameters.filter((p: any) => p.subgroup_2.startsWith('Bloque'));

    const matchingParameter = blockParameters.find((p: any) => p.index === parameter.index);

    if (matchingParameter) {
        return true;
    }

    return false;
};

const checkAndDisableParameter = ({
    parameter_id,
    parametersBackup,
    parameterValues
}: {
    parameter_id: any,
    parametersBackup: any,
    parameterValues: any
}) => {
    if (parameter_id === 854 ||
        parameter_id === 855 ||
        parameter_id === 856 ||
        parameter_id === 857 ||
        parameter_id === 858 ||
        parameter_id === 859 ||
        parameter_id === 892 ||
        parameter_id === 893 ||
        parameter_id === 894 ||
        parameter_id === 895 ||
        parameter_id === 896 ||
        parameter_id === 897 ||
        parameter_id === 3102 ||
        parameter_id === 3103 ||
        parameter_id === 3104 ||
        parameter_id === 3105 ||
        parameter_id === 3106 ||
        parameter_id === 3107

    ) {

        //SLOT C CONFIGURATION
        const param854 = parametersBackup.find((p: any) => p.parameter_id === '854');
        const param855 = parametersBackup.find((p: any) => p.parameter_id === '855');
        const param856 = parametersBackup.find((p: any) => p.parameter_id === '856');
        const param857 = parametersBackup.find((p: any) => p.parameter_id === '857');
        const param858 = parametersBackup.find((p: any) => p.parameter_id === '858');
        const param859 = parametersBackup.find((p: any) => p.parameter_id === '859');

        //SLOT D CONFIGURATION

        const param892 = parametersBackup.find((p: any) => p.parameter_id === '892');
        const param893 = parametersBackup.find((p: any) => p.parameter_id === '893');
        const param894 = parametersBackup.find((p: any) => p.parameter_id === '894');
        const param895 = parametersBackup.find((p: any) => p.parameter_id === '895');
        const param896 = parametersBackup.find((p: any) => p.parameter_id === '896');
        const param897 = parametersBackup.find((p: any) => p.parameter_id === '897');

        //SLOT E CONFIGURATION

        const param3102 = parametersBackup.find((p: any) => p.parameter_id === '3102');
        const param3103 = parametersBackup.find((p: any) => p.parameter_id === '3103');
        const param3104 = parametersBackup.find((p: any) => p.parameter_id === '3104');
        const param3105 = parametersBackup.find((p: any) => p.parameter_id === '3105');
        const param3106 = parametersBackup.find((p: any) => p.parameter_id === '3106');
        const param3107 = parametersBackup.find((p: any) => p.parameter_id === '3107');

        const updates = [
            { param: param854, value: "12500", idToDisable: 1102, idToEnable: 1096 },
            { param: param855, value: "12501", idToDisable: 1103, idToEnable: 1097 },
            { param: param856, value: "12502", idToDisable: 1104, idToEnable: 1098 },
            { param: param857, value: "12503", idToDisable: 1105, idToEnable: 1099 },
            { param: param858, value: "12504", idToDisable: 1106, idToEnable: 1100 },
            { param: param859, value: "12505", idToDisable: 1107, idToEnable: 1101 },
            { param: param854, value: "12520", idToDisable: 1096, idToEnable: 1102 },
            { param: param855, value: "12521", idToDisable: 1097, idToEnable: 1103 },
            { param: param856, value: "12522", idToDisable: 1098, idToEnable: 1104 },
            { param: param857, value: "12523", idToDisable: 1099, idToEnable: 1105 },
            { param: param858, value: "12524", idToDisable: 1100, idToEnable: 1106 },
            { param: param859, value: "12525", idToDisable: 1101, idToEnable: 1107 },

            { param: param892, value: "13500", idToDisable: 1114, idToEnable: 1108 },
            { param: param893, value: "13501", idToDisable: 1115, idToEnable: 1109 },
            { param: param894, value: "13502", idToDisable: 1116, idToEnable: 1110 },
            { param: param895, value: "13503", idToDisable: 1117, idToEnable: 1111 },
            { param: param896, value: "13504", idToDisable: 1118, idToEnable: 1112 },
            { param: param897, value: "13505", idToDisable: 1119, idToEnable: 1113 },
            { param: param892, value: "13520", idToDisable: 1108, idToEnable: 1114 },
            { param: param893, value: "13521", idToDisable: 1009, idToEnable: 1115 },
            { param: param894, value: "13522", idToDisable: 1110, idToEnable: 1116 },
            { param: param895, value: "13523", idToDisable: 1111, idToEnable: 1117 },
            { param: param896, value: "13524", idToDisable: 1112, idToEnable: 1118 },
            { param: param896, value: "13525", idToDisable: 1113, idToEnable: 1119 },


            { param: param3102, value: "14500", idToDisable: 1126, idToEnable: 1120 },
            { param: param3103, value: "14501", idToDisable: 1127, idToEnable: 1121 },
            { param: param3104, value: "14502", idToDisable: 1128, idToEnable: 1122 },
            { param: param3105, value: "14503", idToDisable: 1129, idToEnable: 1123 },
            { param: param3106, value: "14504", idToDisable: 1130, idToEnable: 1124 },
            { param: param3107, value: "14505", idToDisable: 1131, idToEnable: 1125 },
            { param: param3102, value: "14520", idToDisable: 1120, idToEnable: 1126 },
            { param: param3103, value: "14521", idToDisable: 1121, idToEnable: 1127 },
            { param: param3104, value: "14522", idToDisable: 1122, idToEnable: 1128 },
            { param: param3105, value: "14523", idToDisable: 1123, idToEnable: 1129 },
            { param: param3106, value: "14524", idToDisable: 1124, idToEnable: 1130 },
            { param: param3107, value: "14525", idToDisable: 1125, idToEnable: 1131 },




        ];

        updates.forEach(({ param, value, idToDisable, idToEnable }) => {
            if (param && param.value === value) {
                parametersBackup = parametersBackup.map((parameter: any) => {
                    if (parameter.id === idToDisable) {
                        return { ...parameter, is_active: false };
                    }
                    if (parameter.id === idToEnable) {
                        return { ...parameter, is_active: true };
                    }
                    return parameter;
                });
            }
        });
    }

    return parametersBackup;
};


const toggleModbusRtuIds = ({
    parameter_id,
    parametersBackup,
    parameterValues
}: {
    parameter_id: any,
    parametersBackup: any,
    parameterValues: any
}) => {
    // Comprobar si el parameter_id es 2208
    if (parameter_id === 2208) {
        const param2208 = parametersBackup.find((p: any) => p.parameter_id === '2208');

        if (param2208 && param2208.value === "4") {
            const idsToEnable = [3132, 3133, 3134, 3135, 3136, 3137, 3138, 3139, 3140, 3141, 3142, 3143, 3144, 3145, 3146, 3147, 3148, 3149, 3150, 3151, 3152, 3153, 3154, 3155, 3156, 3157, 3158, 3159];

            parametersBackup = parametersBackup.map((parameter: any) => {
                if (idsToEnable.includes(parseInt(parameter.parameter_id))) {
                    return { ...parameter, is_active: true };
                }
                return parameter;
            });
        } else {
            const idsToDisable = [3132, 3133, 3134, 3135, 3136, 3137, 3138, 3139, 3140, 3141, 3142, 3143, 3144, 3145, 3146, 3147, 3148, 3149, 3150, 3151, 3152, 3153, 3154, 3155, 3156, 3157, 3158, 3159];

            parametersBackup = parametersBackup.map((parameter: any) => {
                if (idsToDisable.includes(parseInt(parameter.parameter_id))) {
                    return { ...parameter, is_active: false };
                }
                return parameter;
            });
        }
    }

    return parametersBackup;
};


const checkAnalogConditions = ({
    parameter_id,
    parametersBackup,
    parameterValues
}: {
    parameter_id: any,
    parametersBackup: any,
    parameterValues: any
}) => {

    const param141 = parametersBackup.find((p: any) => p.parameter_id === '141');
    const param152 = parametersBackup.find((p: any) => p.parameter_id === '152');
    const param188 = parametersBackup.find((p: any) => p.parameter_id === '188');



    let firstAnalogToShow: number | null = null;


    if (param141 && param141.value === '300') {
        firstAnalogToShow = 61; // Analógica 3
    } else if (param152 && param152.value === '300') {
        firstAnalogToShow = 62; // Analógica 4
    } else if (param188 && param188.value === '300') {
        firstAnalogToShow = 63; // Analógica 5

    }


    if (param141 && param141.value === '400') {
        firstAnalogToShow = 1228; // Analógica 3
    } else if (param152 && param152.value === '400') {
        firstAnalogToShow = 1229; // Analógica 4
    } else if (param188 && param188.value === '400') {
        firstAnalogToShow = 1230; // Analógica 5
    }


    if (param141 && param141.value === '500') {
        firstAnalogToShow = 1215; // Analógica 3
    } else if (param152 && param152.value === '500') {
        firstAnalogToShow = 1216; // Analógica 4
    } else if (param188 && param188.value === '500') {
        firstAnalogToShow = 1217; // Analógica 5
    }


    const idsToShow = firstAnalogToShow !== null ? [firstAnalogToShow] : [];
    const allIds = [61, 62, 63, 1228, 1229, 1230, 1215, 1216, 1217];
    const idsToHide = allIds.filter(id => !idsToShow.includes(id));


    parametersBackup = parametersBackup.map((parameter: any) => {

        if (idsToShow.includes(parseInt(parameter.id))) {
            return { ...parameter, is_active: true };
        }
        if (idsToHide.includes(parseInt(parameter.id))) {
            return { ...parameter, is_active: false };
        }
        return parameter;
    });

    return parametersBackup;
};

const updateMinMaxForParameters = (parameter_id: number, parametersBackup: any) => {
    let updatedParameters = [...parametersBackup];


    const updateMinMax = (sourceParams: any[], targetParams: any[], field?: 'min' | 'max') => {
        sourceParams.forEach((sourceParam: any) => {
            targetParams.forEach((targetParam: any) => {
                const updatedTargetParam = { ...targetParam };  // Hacer una copia del parámetro

                if (targetParam.parameter_id === '1239') {
                    const minValue = sourceParam.value;
                    updatedTargetParam['min'] = `-${minValue}`;
                    updatedTargetParam['max'] = `${minValue}`;

                } else if (field) {
                    updatedTargetParam[field] = sourceParam.value;
                }


                // Buscar el índice y actualizar el parámetro
                const index = updatedParameters.findIndex(param => param.parameter_id === updatedTargetParam.parameter_id);

                // Eliminar el parámetro anterior
                updatedParameters = updatedParameters.filter(param => param.parameter_id !== updatedTargetParam.parameter_id);

                if (index !== -1) {
                    updatedParameters.splice(index, 0, updatedTargetParam);
                } else {
                    updatedParameters.push(updatedTargetParam);
                }
            });
        });
    };

    if (parameter_id === 101 || parameter_id === 102) {
        const params101 = updatedParameters.filter((p: any) => p.parameter_id == '101');
        const params102 = updatedParameters.filter((p: any) => p.parameter_id == '102');
        const params184 = updatedParameters.filter((p: any) => p.parameter_id == '184');
        const params180 = updatedParameters.filter((p: any) => p.parameter_id == '180');
        const params105 = updatedParameters.filter((p: any) => p.parameter_id == '105');
        const params106 = updatedParameters.filter((p: any) => p.parameter_id == '106');
        const params126 = updatedParameters.filter((p: any) => p.parameter_id == '126');
        const params127 = updatedParameters.filter((p: any) => p.parameter_id == '127');
        const params128 = updatedParameters.filter((p: any) => p.parameter_id == '128');
        const params129 = updatedParameters.filter((p: any) => p.parameter_id == '129');
        const params130 = updatedParameters.filter((p: any) => p.parameter_id == '130');
        const params1239 = updatedParameters.filter((p: any) => p.parameter_id == '1239');

        if (parameter_id === 101) {
            updateMinMax(params101, params102, 'min');
            updateMinMax(params101, params184, 'min');
            updateMinMax(params101, params180, 'min');
            updateMinMax(params101, params105, 'min');
            updateMinMax(params101, params106, 'min');
            updateMinMax(params101, params126, 'min');
            updateMinMax(params101, params127, 'min');
            updateMinMax(params101, params128, 'min');
            updateMinMax(params101, params129, 'min');
            updateMinMax(params101, params130, 'min');

        }

        if (parameter_id === 102) {
            updateMinMax(params102, params101, 'max');
            updateMinMax(params102, params184, 'max');
            updateMinMax(params102, params180, 'max');
            updateMinMax(params102, params105, 'max');
            updateMinMax(params102, params106, 'max');
            updateMinMax(params102, params126, 'max');
            updateMinMax(params102, params127, 'max');
            updateMinMax(params102, params128, 'max');
            updateMinMax(params102, params129, 'max');
            updateMinMax(params102, params130, 'max');
            updateMinMax(params102, params1239, 'max');
        }


    }

    return updatedParameters;
};

const motorParameterIds: number[] = [110, 111, 112, 113, 120, 116];

export const isMotorDataParameter = (
    parameter_id: number
): boolean => {
    const isMotorData = motorParameterIds.includes(Number(parameter_id));
    return isMotorData;
};




