import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import classnames from 'classnames';
import { getFaultHistoriesNotAcknowledged, updateFaultHistory } from 'slices/faultHistory/thunk';
import { getAlarmHistoriesNotAcknowledged, updateAlarmHistory } from 'slices/alarmHistory/thunk';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import images
import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import SimpleBar from "simplebar-react";
import { SocketContext } from 'context/SocketProvider';

const NotificationDropdown = () => {
    // Dropdown Toggle
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const socket = useContext(SocketContext);
    const dispatch: any = useDispatch();
    const selectFaultHistoryState = (state: any) => state.FaultHistory;
    const selectAlarmHistoryState = (state: any) => state.AlarmHistory;
    

    const FaultHistoryProperties = createSelector(
        selectFaultHistoryState,
        (state) => state
    );

    const AlarmHistoryProperties = createSelector(
        selectAlarmHistoryState,
        (state) => state
    );

    const { faultHistoryNotAcknowledged } = useSelector(FaultHistoryProperties);
    const { alarmHistoriesNotAcknowledged } = useSelector(AlarmHistoryProperties);








    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    // Tab
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab: any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    // Modal Toggle
    const [modal, setModal] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState<any | null>(null);
    const [isFault, setIsFault] = useState<boolean>(true);
    const isRequestPending = useRef(false);


    const openModal = (notification: any, isFault: boolean) => {
        setSelectedNotification(notification);
        setIsFault(isFault);
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    useEffect(() => {
        let isMounted = true;
    
        const fetchHistories = async () => {
            if (!isRequestPending.current) {
                isRequestPending.current = true;
    
                try {
                    // Ejecuta las peticiones
                    await dispatch(getFaultHistoriesNotAcknowledged());
                    await dispatch(getAlarmHistoriesNotAcknowledged());
                } catch (error) {
                    if (isMounted) {
                        console.error("Error fetching histories:", error);
                    }
                } finally {
                    if (isMounted) {
                        isRequestPending.current = false;
                        setTimeout(fetchHistories, 2000); // Programa la siguiente ejecución 2 segundos después
                    }
                }
            }
        };
    
        // Inicia la primera ejecución
        fetchHistories();
    
        // Cleanup on component unmount
        return () => {
            isMounted = false;
        };
    }, [dispatch]);

    /*    useEffect(() => {
            if (socket) {
                socket.on('message_event', (data) => {
                    console.log("recibiendo")
                     dispatch(getFaultHistoriesNotAcknowledged());
                    dispatch(getAlarmHistoriesNotAcknowledged()); 
                });
            }
        }, [socket, dispatch]);  */

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy HH:mm', { locale: es });
    };

    const renderNotificationItem = (notification: any, isFault: boolean) => (
        <div className="text-reset notification-item d-block dropdown-item position-relative" onClick={() => openModal(notification, isFault)} style={{ cursor: 'pointer' }} key={notification.id}>
            <div className="d-flex">
                <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                        {notification.custom_fault?.custom_fault_name || notification.fault?.fault_name || notification.custom_alarm?.custom_fault_name || notification.alarm?.fault_name || 'Desconocido'}
                    </h6>
                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                        <span><i className="mdi mdi-clock-outline"></i> {formatDate(notification.created_at)}</span>
                        <span className={`badge ms-2 ${isFault ? 'bg-danger' : 'bg-warning'}`}>
                            {isFault ? 'Fallo' : 'Alarma'}
                        </span>
                        <span className="badge bg-info ms-2">{notification.connection_name || 'Dispositivo desconocido'}</span>
                    </p>
                </div>
                <div className="px-2 fs-15">
                    <div className="form-check notification-check">
                        <label className="form-check-label" htmlFor={`notification-check-${notification.id}`}></label>
                    </div>
                </div>
            </div>
        </div>
    );

    const handleAcknowledgeNotification = () => {
        if (selectedNotification) {
            if (isFault) {
                dispatch(updateFaultHistory({ id: selectedNotification.id, is_acknowledged: 1 })).then(() => {
                    toast.success("El fallo ha sido acusado");
                });
            } else {
                dispatch(updateAlarmHistory({ id: selectedNotification.id, is_acknowledged: 1 })).then(() => {
                    toast.success("La alarma ha sido acusada");
                });
            }
        }
        closeModal();
    };

    return (
        <React.Fragment>
            <ToastContainer
                closeButton={false}
                position={toast.POSITION.TOP_CENTER} // Position at the top center
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                limit={1}
            />
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none">
                    <i className='bx bx-bell fs-22'></i>
                    {faultHistoryNotAcknowledged && faultHistoryNotAcknowledged.length + alarmHistoriesNotAcknowledged.length > 0 &&
                        <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{faultHistoryNotAcknowledged.length + alarmHistoriesNotAcknowledged.length}<span className="visually-hidden">unread messages</span></span>
                    }

                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Alarmas y fallos </h6>
                                </Col>
                                <div className="col-auto dropdown-tabs">
                                    <span className="badge bg-light-subtle text-body fs-13"> {faultHistoryNotAcknowledged && faultHistoryNotAcknowledged.length + alarmHistoriesNotAcknowledged.length > 1 ? `${faultHistoryNotAcknowledged.length + alarmHistoriesNotAcknowledged.length} Nuevos` : `${faultHistoryNotAcknowledged.length + alarmHistoriesNotAcknowledged.length}Nuevo`}</span>
                                </div>
                            </Row>
                        </div>

                        <div className="px-2 pt-2">
                            <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}
                                    >
                                        Todos ({faultHistoryNotAcknowledged && faultHistoryNotAcknowledged.length + alarmHistoriesNotAcknowledged.length})
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggleTab('2'); }}
                                    >
                                        Fallos ({faultHistoryNotAcknowledged && faultHistoryNotAcknowledged.length})
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggleTab('3'); }}
                                    >
                                        Alertas ({alarmHistoriesNotAcknowledged && alarmHistoriesNotAcknowledged.length})
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                {faultHistoryNotAcknowledged && alarmHistoriesNotAcknowledged && faultHistoryNotAcknowledged.length === 0 && alarmHistoriesNotAcknowledged.length === 0 ? (
                                    <div className="text-center my-3">
                                        <h6 className="text-muted">No hay alarmas o fallos</h6>
                                    </div>
                                ) : (
                                    <>
                                        {Array.isArray(faultHistoryNotAcknowledged) && faultHistoryNotAcknowledged.map((fault: any) => renderNotificationItem(fault, true))}
                                        {Array.isArray(alarmHistoriesNotAcknowledged) && alarmHistoriesNotAcknowledged.map((alarm: any) => renderNotificationItem(alarm, false))}
                                    </>
                                )}

                            </SimpleBar>
                        </TabPane>

                        <TabPane tabId="2" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                {faultHistoryNotAcknowledged && faultHistoryNotAcknowledged.length === 0 ? (
                                    <div className="text-center my-3">
                                        <h6 className="text-muted">No hay fallos</h6>
                                    </div>
                                ) : (
                                    Array.isArray(faultHistoryNotAcknowledged) && faultHistoryNotAcknowledged.map((fault: any) => renderNotificationItem(fault, true))
                                )}

                            </SimpleBar>
                        </TabPane>

                        <TabPane tabId="3" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                {alarmHistoriesNotAcknowledged && alarmHistoriesNotAcknowledged.length === 0 ? (
                                    <div className="text-center my-3">
                                        <h6 className="text-muted">No hay alarmas</h6>
                                    </div>
                                ) : (
                                    Array.isArray(alarmHistoriesNotAcknowledged) && alarmHistoriesNotAcknowledged.map((alarm: any) => renderNotificationItem(alarm, false))
                                )}

                            </SimpleBar>
                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>

            <Modal isOpen={modal} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>Detalles del {isFault ? 'fallo' : 'alarma'}</ModalHeader>
                <ModalBody>
                    {selectedNotification && (
                        <>
                            <h6>{selectedNotification.custom_fault?.custom_fault_name || selectedNotification.fault?.fault_name || selectedNotification.custom_alarm?.custom_fault_name || selectedNotification.alarm?.fault_name || 'Desconocido'}</h6>
                            <p>{selectedNotification.custom_fault?.custom_correction_guide || selectedNotification.fault?.correction_guide || selectedNotification.custom_alarm?.custom_correction_guide || selectedNotification.alarm?.correction_guide || 'No hay guía de corrección disponible'}</p>
                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span><i className="mdi mdi-clock-outline"></i> {formatDate(selectedNotification.created_at)}</span>
                                <span className={`badge ms-2 ${isFault ? 'bg-danger' : 'bg-warning'}`}>
                                    {isFault ? 'Fallo' : 'Alarma'}
                                </span>
                                <span className="badge bg-info ms-2">{selectedNotification.connection_name || 'Dispositivo desconocido'}</span>
                            </p>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleAcknowledgeNotification}>Acusar {isFault ? 'Fallo' : 'Alarma'}</Button>
                    <Button color="secondary" onClick={closeModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </React.Fragment>
    );
};

export default NotificationDropdown;
