import React, { createContext, useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';


/* const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL; */

const isLocal = window.location.hostname === '192.168.0.6';

const socketServerUrl = isLocal
  ? process.env.REACT_APP_SOCKET_SERVER_URL_LOCAL
  : process.env.REACT_APP_SOCKET_SERVER_URL;


export const SocketContext = createContext<Socket | null>(null);

interface SocketProviderProps {
  children: React.ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isDisconnected, setIsDisconnected] = useState(false); // Estado para controlar la desconexión

  useEffect(() => {

    if (!socketServerUrl) {
      throw new Error('La URL del servidor Socket no está definida. Asegúrate de que REACT_APP_SOCKET_SERVER_URL esté configurada.');
    }

    const newSocket = io(socketServerUrl, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000000,
    });

    newSocket.on('operativo', () => {
      /* console.log("Conectado al Servidor"); */
      setIsDisconnected(false); // Reconectado, oculta la ReconnectPage
    });

    newSocket.on('disconnect', (reason) => {
      /*  console.log(`Desconectado del servidor: ${reason}`); */
      setIsDisconnected(true); // Desconectado, muestra la ReconnectPage
    });


    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const handleReconnect = () => {
    socket?.connect();
  };

  return (
    <SocketContext.Provider value={socket}>

      {!isDisconnected && children}
    </SocketContext.Provider>
  );
};