import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledDropdown, Spinner } from 'reactstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Mousewheel } from "swiper/modules";
import { useParams, useNavigate } from 'react-router-dom';
import ModalConfirm from 'Components/Common/Modals/ModalConfirm';
import ModalAddDevice from 'Components/Common/Modals/ModalAddDevice';
import { Pagination } from 'swiper/modules';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getInstallations, deleteConnection } from 'slices/thunks';
import vacon from "../../assets/images/Danfoss Vacon Drives.jpg";
import vlt from "../../assets/images/Danfoss VLT Drives.jpg";
import DeviceDetails from './DeviceDetails';
import { toast } from 'react-toastify';
import rolesIds from 'constants/roles';

const Devices = ({ favorites, id, status }: any) => {
    const [modal_standard, setmodal_standard] = useState<boolean>(false);
    const [modalAddChart, setmodalAddChart] = useState<boolean>(false);
    const [modal_confirmation, setModal_confirmation] = useState<boolean>(false);
    const [selectedConnectionId, setSelectedConnectionId] = useState<number | null>(null);
    const [addToChartModalOpen, setAddToChartModalOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const [connections, setConnections] = useState<any[]>([]);
    const [connection, setConnection] = useState<any>({});
    const [selectedDevice, setSelectedDevice] = useState<any | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    const selectInstallationsState = (state: any) => state.Installations;
    const selectParameterValuesState = (state: any) => state.ParameterValues;
    const installationDataProperties = createSelector(
        selectInstallationsState,
        (state: any) => state
    );

    const parameterValuesDataProperties = createSelector(
        selectParameterValuesState,
        (state: any) => state
    );

    const profiledropdownData = createSelector(
        (state: any) => state.Profile.user,
        (user) => user
    );

    const { installations } = useSelector(installationDataProperties);
    const { parameterValues } = useSelector(parameterValuesDataProperties);
    const user = useSelector(profiledropdownData);

    useEffect(() => {
        if (installations && installations.length > 0 && Array.isArray(installations)) {
            const filteredInstallations = installations.filter((installation: any) =>
                installation.zones.some((zone: any) => zone.id === Number(id))
            );

            const connections = filteredInstallations.flatMap((installation: any) =>
                installation.zones.filter((zone: any) => zone.id === Number(id))
                    .flatMap((zone: any) => zone.connections)
            );
            setConnections(connections);
            setLoading(false);
        }
    }, [installations, id]);

    const toggle = () => setModal(!modal);

    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    function tog_modalAddChart() {
        setmodalAddChart(!modalAddChart);
    }

    function tog_confirmation() {
        setModal_confirmation(!modal_confirmation);
    }

    const handleConfirmBackup = (idSelected: number | null) => {

    };

    const handleCardClick = (connectionId: any) => {
        navigate(`/dispositivo/${connectionId}`);
    };

    const handleDropdownClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const getStatusColor = (connectionId: any, statusType: any) => {
        const statusItem = status && status[connectionId]?.[statusType];
        if (statusType === 'fault') {
            return statusItem?.value === 1 ? 'text-danger' : 'text-muted';
        } else if (statusType === 'warning') {
            return statusItem?.value === 1 ? 'text-warning' : 'text-muted';
        } else {
            return statusItem?.value === 1 ? 'text-success' : 'text-muted';
        }
    };

    const handleEditDevice = (device: any) => {
        setSelectedDevice(device);
        setEditMode(true);
        setModal(true);
    };

    const handleDeleteDevice = (device: any) => {
        setSelectedDevice(device);
        setModal_confirmation(true);

    };

    const confirmDeleteDevice = async (connection: any) => {

        if (selectedDevice) {
            try {
                await dispatch(deleteConnection(connection)).unwrap();
                toast.success('Dispositivo eliminado con éxito!');
                setConnections(connections.filter(conn => conn.id !== connection.id));
                setModal_confirmation(false);
            } catch (error: any) {
                toast.error('Error al eliminar el dispositivo: ' + error.message);
            }
        }
    };

    return (
        <React.Fragment>
            <Col lg={12}>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>
                ) : (
                    <Swiper
                        pagination={true}
                        modules={[Pagination, Autoplay, Mousewheel]}
                        breakpoints={{
                            640: {
                                slidesPerView: 1, // 2 -> 1
                                spaceBetween: 24
                            },
                            768: {
                                slidesPerView: 2, // Agregar este punto de interrupción
                                spaceBetween: 24
                            },
                            1024: {
                                slidesPerView: 2, // 3 -> 2
                                spaceBetween: 24
                            },
                            1200: {
                                slidesPerView: 3, // Igual que antes
                                spaceBetween: 24
                            },
                            1600: {
                                slidesPerView: 4, // 5 -> 4
                                spaceBetween: 24
                            },
                        }}
                        mousewheel={true}
                    >
                        
                        {(connections != null && connections[0] != undefined ? connections : []).map((item: any, key: any) => (
                            <SwiperSlide key={key} className='mt-3'>
                                <Card className="custom-card-height overflow-hidden" onClick={() => handleCardClick(item.id)}>
                                    <CardBody className='px-4 py-2'>
                                        <div className="float-end" onClick={handleDropdownClick}>
                                            <UncontrolledDropdown direction='start'>
                                                <DropdownToggle className="text-reset" tag="a" role="button">
                                                    <span className="text-muted fs-18"><i className="mdi mdi-dots-horizontal"></i></span>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu dropdown-menu-end">
                                                    {user.role_id != rolesIds.operator ? <DropdownItem onClick={() => handleEditDevice(item)}>Modificar Dispositivo</DropdownItem> : null}
                                                    {user.role_id != rolesIds.operator ? <DropdownItem onClick={() => handleDeleteDevice(item)}>Eliminar Dispositivo</DropdownItem> : null}
                                                    <DropdownItem onClick={() => { tog_standard(); setConnection(item); }}>Detalles</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img
                                                src={item && item.img === "Vacon" ? vacon : item && item.img === "VLT" ? vlt : ""}
                                                className="img-fluid w-25"
                                                alt={item && item.img}
                                            />
                                            <h6 className="ms-2 mb-0 fs-14 text-truncate" style={{ maxWidth: '150px' }}>{item && item.name}</h6>
                                        </div>
                                        <Row className="g-0 w-full">
                                            <Col xs={12}>
                                                <div className='d-flex justify-content-between align-items-center mt-1 w-100'>
                                                    <div className='d-flex align-items-center'>
                                                        <p className='mb-0 fs-6 me-2'>Listo</p>
                                                        <i className={`mdi mdi-checkbox-blank-circle ${getStatusColor(item && item.id, 'ready')} fs-6`}></i>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <p className='mb-0 fs-6 me-2'>En marcha</p>
                                                        <i className={`mdi mdi-checkbox-blank-circle ${getStatusColor(item && item.id, 'run')} fs-6`}></i>
                                                    </div>

                                                    <div className='d-flex align-items-center'>
                                                        <p className='mb-0 fs-6 me-2'>Fallo</p>
                                                        <i className={`mdi mdi-checkbox-blank-circle ${getStatusColor(item && item.id, 'fault')} fs-6`}></i>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <p className='mb-0 fs-6 me-2'>Alarma</p>
                                                        <i className={`mdi mdi-checkbox-blank-circle ${getStatusColor(item && item.id, 'warning')} fs-6`} style={{ color: status && status[item && item.id] && status[item.id].warning && status[item && item.id].warning.value === 1 ? 'orange' : undefined }}></i>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-end g-0 w-full">
                                            <Col xs={12}>
                                                <div className='d-flex justify-content-around align-items-center mt-1 w-100' style={{ minHeight: '100px', flexDirection: 'column', gap: '5px' }}>
                                                    {Array.from({ length: 6 }, (_, index) => {
                                                        const favorite = (favorites || []).filter((fav: any) => fav.connection_id === item.id)[index]; // Filtra los favoritos por connection_id
                                                        return (
                                                            <div key={index} className='d-flex w-100' style={{ borderRadius: '5px', paddingLeft: '5px', paddingRight: '5px', minHeight: '16px', justifyContent: 'space-between', backgroundColor: favorite?.color }}>
                                                                <div className='d-flex text-truncate' style={{ width: '60%' }}>
                                                                    <p className='mb-0 mr-2 ml-2 text-truncate' style={{ maxWidth: '100%' }}>
                                                                        {favorite?.name || ' - '}
                                                                    </p>
                                                                </div>
                                                                <div className='d-flex align-items-center' style={{ width: '35%', justifyContent: 'center' }}>
                                                                    {favorite?.value !== undefined && favorite?.name && favorite?.value !== "" ? (
                                                                        <>
                                                                            <p style={{ marginLeft: '10px', marginRight: '5px' }} className='mb-0 mr-2 text-center'>{
                                                                                parameterValues[item.id][favorite?.favorite_parameter_id]?.find(
                                                                                    (parameterValue : any) => parameterValue.parameter_id == favorite?.favorite_parameter_id && parameterValue.value == favorite?.value
                                                                                )?.textValue ?? favorite?.value
                                                                            }</p>
                                                                            <p className='mb-0 mr-2' style={{ fontSize: '0.8rem' }}>{favorite?.unit}</p>
                                                                        </>
                                                                    ) : favorite?.name ? (
                                                                        <Spinner size="sm" color="primary" />
                                                                    ) : (
                                                                        <p className='mb-0 mr-2 ml-2' style={{ marginLeft: '10px' }}>-</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </SwiperSlide>
                        ))}
                        {
                            user.role_id == rolesIds.operator ? (
                                null
                            ) : <SwiperSlide className="mt-3">
                                <Card className="custom-card-height d-flex align-items-center justify-content-center card-dotted-outline card-add-device" onClick={() => { setSelectedDevice(null); setEditMode(false); setModal(true); }}>
                                    <div className="card-content text-center">
                                        <h4>Añade un dispositivo</h4>
                                        <i className="mdi mdi-plus-circle-outline"></i>
                                    </div>
                                </Card>
                            </SwiperSlide>
                        }

                    </Swiper>
                )}
            </Col>
            <DeviceDetails modal_standard={modal_standard} tog_standard={tog_standard} connection={connection} />
            <ModalConfirm
                isOpen={modal_confirmation}
                toggle={tog_confirmation}
                title="Eliminar dispositivo"
                message={`¿Está seguro de que desea eliminar el dispositivo ${selectedDevice?.name}?`}
                onConfirm={confirmDeleteDevice}
                idSelected={selectedDevice && selectedDevice}
                onCancel={tog_confirmation}
                iconName=' mdi mdi-delete display-5 text-danger'
                requireNameConfirmation={true}
                confirmationName={selectedDevice?.name}
            />
            <ModalAddDevice isOpen={modal} toggle={toggle} zoneId={id} editMode={editMode} selectedDevice={selectedDevice} />
        </React.Fragment>
    );
};

export default Devices;
