import BreadCrumb from 'Components/Common/BreadCrumb';
import { SearchTable } from 'pages/Tables/ReactTables/ReactTable';
import React from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import UsersList from './AlarmsAndFaultsList';
import GeneralData from 'pages/Dashboard/GeneralData';
import AlarmsAndFaultsList from './AlarmsAndFaultsList';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useParams } from 'react-router-dom';

const AlarmsAndFaults = () => {
    document.title = "Alarmas y fallos | Ecentroserver";
    const { id } = useParams();
    const connectionID = Number(id)

    const selectInstallationsState = (state: any) => state.Installations;
    const selectParametersBackupState = (state: any) => state.ParametersBackup;

    const installationDataProperties = createSelector(
        selectInstallationsState,
        (state) => state
    );


    const parametersBackupProperties = createSelector(
        selectParametersBackupState,
        (state: any) => state
    );

    const { installations } = useSelector(installationDataProperties);
    const { parametersBackup } = useSelector(parametersBackupProperties);

    const installation = installations?.find((inst: any) =>
        inst.zones.some((zone: any) => zone.connections.some((conn: any) => conn.id === connectionID))
    );
    const zone = installation?.zones.find((zone: any) =>
        zone.connections.some((conn: any) => conn.id === connectionID)
    );


    return (
        <React.Fragment>
            <BreadCrumb
                pageTitle={installation && installation.installation_name}
                pageTitleUrl={`/dashboard/instalacion/${installation && installation.id}`}
                subtitle={zone && zone.zone_name}
                subtitleUrl={`/dashboard/zona/${zone && zone.id}`}
                subsubtitle={parametersBackup && parametersBackup[connectionID] ? parametersBackup[connectionID].connection_name : ''}
                subsubtitleUrl={`/dispositivo/${connectionID}`}
                subsubsubtitle='Alarmas y fallos'
            />
            <div className="page-content">
                <Container fluid>
                    <Col lg={12} className="order-xxl-0 order-first">
                        <GeneralData />
                    </Col>
                    <Row>
                        <Col xs={12}>
                            <AlarmsAndFaultsList />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AlarmsAndFaults;