import { createSlice } from "@reduxjs/toolkit";
import {
  createParameter,
  getParameters,
  deleteParameter,
  updateParameter,
  getParameter,
} from "./thunk";



export const initialState: any = {
  parameters: [],
};


const parameterSlice = createSlice({
  name: "parameter",
  initialState,
  reducers: {
    setFilteredParameters: (state, action) => {
      state.filteredParameters = action.payload;
    }
  },

  extraReducers: (builder) => {

    builder.addCase(getParameters.fulfilled, (state, action: any) => {
      const newParameters = action.payload;

      Object.keys(newParameters).forEach((key) => {
        if (!state.parameters[key]) {
          state.parameters[key] = newParameters[key];
        }
      });
    });

    /* builder.addCase(getParametersValues.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getParametersValues.fulfilled, (state, action: any) => {
      console.log("getParametersValues reducer", action.payload);
      const newParameters = action.payload;
      console.log("newParametersValues", newParameters);
      Object.keys(newParameters).forEach((key) => {
          const numericKey = Number(key); // Convertir la clave a número
          if (!state.parametersValues) {
              state.parametersValues = {}; // Asegurar que parametersValues esté inicializado
          }
          if (!state.parametersValues[numericKey]) {
              state.parametersValues[numericKey] = newParameters[key];
          }
      });
  }); */

    builder.addCase(getParameters.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });



    builder.addCase(getParameter.fulfilled, (state: any, action: any) => {

      state.parameter = action.payload;
    });
    builder.addCase(getParameter.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(createParameter.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        state.parameters.push(action.payload);
      }

    });

    builder.addCase(createParameter.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateParameter.fulfilled, (state: any, action: any) => {
      state.parameters = (state.parameters || []).map((parameter: any) =>
        parameter.id.toString() === action.payload.id.toString()
          ? { ...parameter, ...action.payload }
          : parameter
      );
    });

    builder.addCase(updateParameter.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteParameter.fulfilled, (state: any, action: any) => {
      state.parameters = state.parameters.filter(
        (parameter: any) => parameter.id.toString() !== action.payload.toString()
      );
    });

    builder.addCase(deleteParameter.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});
export const { setFilteredParameters } = parameterSlice.actions;
export default parameterSlice.reducer;