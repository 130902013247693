import rolesIds from 'constants/roles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Card, CardBody, CardTitle, Table, Col, Row } from 'reactstrap';
import { createSelector } from 'reselect';
import { getInstallationZonesByUser, updateUserInstallationZone } from 'slices/thunks';
import { getZoneEmailByUser, createZoneEmailByUser, deleteZoneEmailByUser } from 'slices/zoneEmail/thunk';

interface Zone {
    name: string;
}

interface Installation {
    title: string;
    zones: Zone[];
}

interface UserZoneConfigModalProps {
    isOpen: boolean;
    toggle: () => void;
    userData?: any;
}

const UserZoneConfigModal: React.FC<UserZoneConfigModalProps> = ({ isOpen, toggle, userData }) => {
    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.InstallationZones;
    const selectLoginState = (state: any) => state.Login;
    const selectZoneEmailState = (state: any) => state.ZoneEmail;
    const installationZoneDataProperties = createSelector(
        selectLayoutState,
        (state: any) => state
    );

    const zoneEmailDataProperties = createSelector(
        selectZoneEmailState,
        (state: any) => state
    );

    const loginDataProperties = createSelector(
        selectLoginState,
        (state: any) => state
    );


    useEffect(() => {
        if (userData) {
            dispatch(getInstallationZonesByUser(userData.id));
            dispatch(getZoneEmailByUser(userData.id));
        }
    }, [userData]);

    const { installationZonesByUser } = useSelector(installationZoneDataProperties);
    const { zoneEmails } = useSelector(zoneEmailDataProperties);
    const { user } = useSelector(loginDataProperties);


    const [checkboxStates, setCheckboxStates] = useState<{ [key: string]: { readWrite: boolean, read: boolean, email: boolean } }>({});

    useEffect(() => {
        if (installationZonesByUser && Array.isArray(zoneEmails)) {
            const newCheckboxStates: any = {};
            installationZonesByUser.forEach((installation: any) => {
                installation.zones.forEach((zone: any) => {
                    const emailMatch = zoneEmails.some((email: any) => email.installation_zone_id === zone.id);
                    newCheckboxStates[zone.id] = {
                        readWrite: zone.read_write,
                        read: zone.read,
                        email: emailMatch
                    };
                });
            });
            setCheckboxStates(newCheckboxStates);
        }
    }, [installationZonesByUser, zoneEmails]);

    const handleCheckboxChange = (installationId: number, zoneId: number, type: 'read_write' | 'read' | 'email', isChecked: boolean) => {
        const updatedCheckboxStates = {
            ...checkboxStates,
            [zoneId]: {
                ...checkboxStates[zoneId],
                [type]: isChecked
            }
        };
        setCheckboxStates(updatedCheckboxStates);

        if (type === 'email') {
            if (isChecked) {
                dispatch(createZoneEmailByUser({ user_id: userData.id, installation_zone_id: zoneId, email: userData.email })).then((action: any) => {
                    if (action.payload && action.payload.created) {
                        setCheckboxStates((prevState) => ({
                            ...prevState,
                            [zoneId]: {
                                ...prevState[zoneId],
                                email: true,
                                id: action.payload.id
                            }
                        }));
                    }
                });
            } else {
                const zoneEmail = zoneEmails.find((email: any) => email.installation_zone_id === zoneId && email.user_id === userData.id);
                if (zoneEmail) {
                    dispatch(deleteZoneEmailByUser(zoneEmail.id)).then((action: any) => {
                        if (action.payload && action.payload.deleted) {
                            setCheckboxStates((prevState) => ({
                                ...prevState,
                                [zoneId]: {
                                    ...prevState[zoneId],
                                    email: false
                                }
                            }));
                        }
                    });
                }
            }
        } else {
            const userInstallationZone = {
                installation_id: installationId,
                user_id: userData.id,
                role_id: userData.role_id,
                supervisor_id: userData.supervisor_id,
                installation_zone_id: zoneId,
                type: type,
                checked: isChecked
            };
            dispatch(updateUserInstallationZone(userInstallationZone));
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-xl">
            <ModalHeader toggle={toggle}>Configuración zonas de {userData?.name} de la empresa {userData.company_name}</ModalHeader>
            <ModalBody>
                <Row className='justify-content-center'>
                    {installationZonesByUser && installationZonesByUser.length > 0 ? installationZonesByUser.map((installation: any, index: number) => (
                        <Col key={index} lg={6} xs={12} className="mb-3 card-shadow w-full overflow-auto">
                            <Card className='shadow-none h-100 m-0'>
                                <CardBody>
                                    <CardTitle tag="h5">{installation.installation_name}</CardTitle>
                                    <Table className="align-middle table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th className='text-center'>Lectura y escritura</th>
                                                <th className='text-center'>Lectura</th>
                                                <th className='text-center'>Aviso por email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {installation.zones.map((zone: any, idx: number) => (
                                                <tr key={idx}>
                                                    <th scope="row">{zone.zone_name}</th>
                                                    <td className='text-center'>
                                                        <input
                                                            type="checkbox"
                                                            disabled={
                                                                userData.role_id === rolesIds.operator ||
                                                                userData.role_id === rolesIds.aplicationAdministrator ||
                                                                userData.role_id === rolesIds.companyAdministrator ||
                                                                (user.role_id === rolesIds.supervisor && userData.role_id === rolesIds.supervisor)
                                                            }
                                                            checked={checkboxStates[zone.id]?.readWrite || false}
                                                            onChange={(e) => handleCheckboxChange(installation.installation_id, zone.id, 'read_write', e.target.checked)}
                                                            aria-label={`Read and write access for ${zone.name}`}
                                                        />
                                                    </td>
                                                    <td className='text-center'>
                                                        <input
                                                            type="checkbox"
                                                            disabled={
                                                                userData.role_id === rolesIds.aplicationAdministrator ||
                                                                userData.role_id === rolesIds.companyAdministrator ||
                                                                (user.role_id === rolesIds.supervisor && userData.role_id === rolesIds.supervisor)
                                                            }
                                                            checked={checkboxStates[zone.id]?.read || false}
                                                            onChange={(e) => handleCheckboxChange(installation.installation_id, zone.id, 'read', e.target.checked)}
                                                            aria-label={`Read only access for ${zone.name}`}
                                                        />
                                                    </td>
                                                    <td className='text-center'>
                                                        <input
                                                            type="checkbox"
                                                            checked={checkboxStates[zone.id]?.email || false}
                                                            onChange={(e) => handleCheckboxChange(installation.installation_id, zone.id, 'email', e.target.checked)}
                                                            aria-label={`Email notification for ${zone.name}`}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    )) : <div>No hay zonas para configurar</div>}
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default UserZoneConfigModal;
