import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Table } from 'reactstrap';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { createLinkedParameter, createFavorite, deleteLinkedParameter } from 'slices/favorites/thunk';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ModalLinkedParametersProps {
    isOpen: boolean;
    toggle: () => void;
    idSelected: number;
    selectedRow?: any;
}

interface OptionType {
    value: number | string;
    label: string;
}

const ModalLinkedParameters: React.FC<ModalLinkedParametersProps> = ({ isOpen, toggle, idSelected, selectedRow }) => {
    const [selectedType, setSelectedType] = useState('favorites');
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
    const [linkedItems, setLinkedItems] = useState<any[]>([]);
    const [filteredFavorites, setFilteredFavorites] = useState<OptionType[]>([]);
    const [filteredParameters, setFilteredParameters] = useState<OptionType[]>([]);
    const [newFavoriteModal, setNewFavoriteModal] = useState(false);
    const [newFavoriteName, setNewFavoriteName] = useState('');
    const { id } = useParams();
    const dispatch: any = useDispatch();

    const selectFavoritesState = (state: any) => state.Favorites;
    const selectParametersBackupState = (state: any) => state.ParametersBackup;

    const favoritesProperties = createSelector(
        selectFavoritesState,
        (state: any) => state
    );

    const parametersBackupProperties = createSelector(
        selectParametersBackupState,
        (state: any) => state
    );

    const { favorites, linkedFavorites, linkedFavoritesByConnection } = useSelector(favoritesProperties);
    const { parametersBackup } = useSelector(parametersBackupProperties);

    useEffect(() => {
        if (linkedFavorites && idSelected) {
            setLinkedItems(Array.isArray(linkedFavorites) ? linkedFavorites : []);
        }
    }, [linkedFavorites, idSelected]);

    useEffect(() => {
        const updateFilteredFavorites = () => {
            const currentFavorites = favorites[Number(id)]?.filter((fav: any) => {
                const isNotLinked = Array.isArray(linkedFavoritesByConnection) && !linkedFavoritesByConnection.some((parameter_id: any) => parameter_id == fav.parameter_id);
                return fav.type === 'Parametrization' && isNotLinked;
            }) || [];

            const sortedFavorites = currentFavorites.sort((a: any, b: any) => a.position - b.position);

            setFilteredFavorites(sortedFavorites.map((fav: any) => ({ value: fav.id, label: fav.name })));
        };

        updateFilteredFavorites();
    }, [favorites, linkedFavoritesByConnection, id, setFilteredFavorites]);

    useEffect(() => {
        const updateFilteredParameters = () => {
            const currentParameters = parametersBackup[Number(id)]?.parameters?.filter((param: any) => param.parameter_type !== 'Visualization' && param.parameter_type !== null && Array.isArray(linkedFavoritesByConnection) && !linkedFavoritesByConnection.some((parameter_id: any) => parameter_id === param.id)) || [];
            setFilteredParameters(currentParameters.map((param: any) => ({ value: param.id, label: `${param.index} - ${param.variable} - ID ${param.id}` })));
        };

        updateFilteredParameters();
    }, [linkedItems, parametersBackup, linkedFavoritesByConnection, id]);

    const handleTypeChange = (option: any) => {
        setSelectedType(option.value);
        setSelectedOption(null); // Reset selected option when type changes
    };

    const handleOptionChange = (option: OptionType) => {
        if (option && linkedItems.some(item => item.value === option.value)) {
            toast.error("El favorito ya está en la lista.");
            return;
        }
        if (option && !linkedItems.some(item => item.value === option.value)) {
            setSelectedOption(option);
            if (selectedType === 'parameters') {
                setNewFavoriteName(option.label); // Set default name to parameter name
                toggleNewFavoriteModal(); // Show the modal to create a new favorite
            } else {
                addLinkedParameter(option);
            }
        }
    };

    const addLinkedParameter = async (option: OptionType) => {
        try {
            const response = await dispatch(createLinkedParameter({ favorite_visualization_id: idSelected, favorite_parametrization_id: option.value, connection_id: Number(id) }));
            if (response && !response.error) {
                toast.success("Se ha vinculado el favorito correctamente");
                setLinkedItems([...linkedItems, { id: option.value, name: option.label }]);
            } else {
                toast.error("Hubo un error al vincular el favorito");
            }
        } catch (error) {
            toast.error("Hubo un error al introducir el parámetro");
        }
        setSelectedOption(null); // Clear the selected option
    };

    const saveNewFavorite = async () => {
        if (selectedOption && newFavoriteName) {
            try {
                const createFavoriteResponse = await dispatch(createFavorite({
                    name: newFavoriteName,
                    connection_id: Number(id),
                    parameter_id: selectedOption.value,
                    type: 'Parametrization'
                }));

                if (createFavoriteResponse.payload && !createFavoriteResponse.error) {
                    const favoriteId = createFavoriteResponse.payload.id;

                    const linkedParameterResponse = await dispatch(createLinkedParameter({
                        favorite_visualization_id: idSelected,
                        favorite_parametrization_id: favoriteId,
                        connection_id: Number(id),
                    }));

                    setLinkedItems([...linkedItems, { id: favoriteId, name: newFavoriteName }]);
                    toast.success("Favorito creado y vinculado correctamente");
                } else {
                    toast.error("Hubo un error al crear el favorito");
                }
            } catch (error) {
                toast.error("Hubo un error al introducir el parámetro");
            } finally {
                toggleNewFavoriteModal();
                setNewFavoriteName('');
                setSelectedOption(null);
            }
        }
    };

    const removeLinkedItem = async (item: any) => {
        try {
            const response = await dispatch(deleteLinkedParameter(item));
            if (deleteLinkedParameter.fulfilled.match(response)) {
                setLinkedItems(linkedItems.filter(linkedItem => linkedItem.id !== item.id));
                toast.success("Parámetro vinculado eliminado correctamente");
            } else {
                toast.error("Hubo un error al eliminar el parámetro vinculado");
            }
        } catch (error) {
            toast.error("Hubo un error al eliminar el parámetro vinculado");
        }
    };

    const toggleNewFavoriteModal = () => {
        setNewFavoriteModal(!newFavoriteModal);
    };


    const customStyles = {
        control: (base: any) => ({
            ...base,
            borderRadius: '50px',
            borderColor: '#ced4da',
        }),
        valueContainer: (base: any) => ({
            ...base,
            borderRadius: '50px',
        }),
        singleValue: (base: any) => ({
            ...base,
            borderRadius: '50px',
        })
    };

    return (
        <div>
            <ToastContainer
                closeButton={false}
                position={toast.POSITION.TOP_CENTER} 
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                limit={1}
            />
            <Modal isOpen={isOpen} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>{`Parámetros vinculados -  ${selectedRow?.index || ''}  - ${selectedRow ? ' ' : ''} ${selectedRow?.variable || ''} - ${parametersBackup && parametersBackup[selectedRow?.connection_id] && parametersBackup[selectedRow?.connection_id].connection_name || ' '}`}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="typeSelect">Seleccionar tipo</Label>
                        <Select
                            id="typeSelect"
                            placeholder="Selecciona un tipo"
                            options={[
                                { value: 'favorites', label: 'Favoritos' },
                                { value: 'parameters', label: 'Parámetros' }
                            ]}
                            onChange={handleTypeChange}
                            defaultValue={{ value: 'favorites', label: 'Favoritos' }}
                            styles={customStyles} // Apply the custom styles
                        />
                    </FormGroup>
                    {selectedType === 'favorites' ? (
                        <FormGroup>
                            <Label for="favoritesSelect">Favoritos</Label>
                            <Select
                                id="favoritesSelect"
                                placeholder="Selecciona un Favorito"
                                options={filteredFavorites}
                                onChange={handleOptionChange}
                                value={selectedOption}
                                styles={customStyles} // Apply the custom styles
                            />
                        </FormGroup>
                    ) : (
                        <FormGroup>
                            <Label for="parametersSelect">Parámetros</Label>
                            <Select
                                id="parametersSelect"
                                placeholder="Selecciona un Parámetro"
                                options={filteredParameters}
                                onChange={handleOptionChange}
                                value={selectedOption}
                                styles={customStyles} // Apply the custom styles
                            />
                        </FormGroup>
                    )}
                    <Table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col" className='text-center'>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {linkedItems

                                .slice()
                                .sort((a, b) => {
                                    const favoriteA = favorites[Number(id)]?.find((fav: any) => fav.id === a.id);
                                    const favoriteB = favorites[Number(id)]?.find((fav: any) => fav.id === b.id);


                                    if (favoriteA && favoriteB) {
                                        return favoriteA.position - favoriteB.position;
                                    }
                                    return 0;
                                })
                                .map(item => {
                                    const favorite = favorites[Number(id)]?.find((fav: any) => fav.id === item.id);
                                    return (
                                        <tr key={`${item.value}_${item.name}`}>
                                            <td>{item.name}</td>
                                            <td className='text-center'>
                                                <Button color="danger" size="sm" onClick={() => removeLinkedItem(favorite)}>
                                                    <i className='mdi mdi-delete fs-6'></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                </ModalBody>
         
            </Modal>

            <Modal isOpen={newFavoriteModal}  size="md">
                <ModalHeader toggle={toggleNewFavoriteModal}>Crear Nuevo Favorito</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="newFavoriteName">Nombre del Favorito</Label>
                        <input type="text" id="newFavoriteName" className="form-control rounded-pill" value={newFavoriteName} onChange={(e) => setNewFavoriteName(e.target.value)} />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                <Button color="secondary rounded-pill" onClick={toggleNewFavoriteModal}>Cancelar</Button>
                    <Button color="primary rounded-pill" onClick={saveNewFavorite}>Guardar</Button>
                    
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalLinkedParameters;
