import { createSlice } from "@reduxjs/toolkit";
import { createRole, getRoles, deleteRole, updateRole } from "./thunk";



export const initialState: any = {
  roles: [],
};


const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRoles.fulfilled, (state: any, action: any) => {
      state.roles = action.payload;
    });
    builder.addCase(getRoles.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(createRole.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        state.roles.push(action.payload);
      }

    });

    builder.addCase(createRole.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

       builder.addCase(updateRole.fulfilled, (state: any, action: any) => {
         state.roles = (state.roles || []).map((role: any) =>
           role.id.toString() === action.payload.id.toString()
             ? { ...role, ...action.payload }
             : role
         );
       }); 

    builder.addCase(updateRole.rejected, (state: any, action: any) => {
       state.error = action.payload.error || null;
     });
  

    builder.addCase(deleteRole.fulfilled, (state: any, action: any) => {
      state.roles = state.roles.filter(
        (role: any) => role.id.toString() !== action.payload.toString()
      );
    });

    builder.addCase(deleteRole.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});

export default roleSlice.reducer;