import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import ModalConfirm from 'Components/Common/Modals/ModalConfirm';
import { SocketContext } from 'context/SocketProvider';
import { getParametersBackup } from 'slices/parametersBackup/thunk';
import { getParameterValuesByConnection } from 'slices/parameterValues/thunk';
import { getValueModbusByParameter } from 'slices/parameters/thunk';
import rolesIds from 'constants/roles';
import { checkAnalogicInFavorites } from 'slices/favorites/thunk';
import { setDeleteBackup, setValueChanged } from 'slices/parametersBackup/reducer';
import { isMotorDataParameter, isParameterInLogicProgrammerMode } from 'devices/vacon';
import { set } from 'lodash';

const ParameterRow = ({ cell, value, updateData, id, parameter, backgroundColor, customHelp, iconUrl }: any) => {
    const cellValue = cell ? cell.value : value;
    const cellRow = cell ? cell.row : { index: parameter?.index };
    const cellColumn = cell ? cell.column : { id: 'value' };
    const parameterData = cell ? cell.row.original : parameter || {};
    const { parameter_type, parameter_id, min, max, index, unit, variable } = parameterData;
    const dispatch: any = useDispatch();
    const socket = useContext(SocketContext);
    const selectParametersBackupState = (state: any) => state.ParametersBackup;
    const selectParameterValuesState = (state: any) => state.ParameterValues;
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [selectedTextOption, setSelectedTextOption] = useState({ label: '', value: '' });
    const [selectedNumberOption, setSelectedNumberOption] = useState({ label: '', value: '' });
    const [analogicModalOpen, setAnalogicModalOpen] = useState(false);
    const [analogicModalMessage, setAnalogicModalMessage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [localValue, setLocalValue] = useState(cellValue);
    const [originalValue, setOriginalValue] = useState(cellValue); // Estado para almacenar el valor original
    const [waiting, setWaiting] = useState(false); // Nuevo estado para manejar la espera
    const [inputModalOpen, setInputModalOpen] = useState(false);
    const [tempOption, setTempOption] = useState<any>(null);

    const parametersBackupProperties = createSelector(
        selectParametersBackupState,
        (state: any) => state
    );

    const parameterValuesProperties = createSelector(
        selectParameterValuesState,
        (state: any) => state
    );

    const profiledropdownData = createSelector(
        (state: any) => state.Profile.user,
        (user) => user
    );

    const user = useSelector(profiledropdownData);

    const { parametersBackup, valueChanged } = useSelector(parametersBackupProperties);
    const { parameterValues } = useSelector(parameterValuesProperties);

    const idNumber = Number(id);

    const foundParameter = parametersBackup[idNumber]?.parameters.find(
        (parameter: any) => parameter.parameter_id === parameter_id && index === parameter.index
    );

    const parameterIdNumber = foundParameter ? foundParameter.id : null;

    useEffect(() => {
        if (!parameterValues || !parameterValues[Number(id)]) {
            dispatch(getParameterValuesByConnection(Number(id)));
        }

        if ((!parametersBackup || !parametersBackup[Number(id)]) && parameterValues[Number(id)]) {
            dispatch(getParametersBackup({ connectionId: Number(id), parameterValues: parameterValues[Number(id)] }));
        }
    }, [dispatch, id, parametersBackup, parameterValues]);

    useEffect(() => {
        if (parameterValues && parameterValues[idNumber] && parameterValues[idNumber][parameterIdNumber]) {
            const selectedValue = parameterValues[idNumber][parameterIdNumber].find((option: any) => option.value == cellValue) || {};
            setSelectedTextOption({ label: selectedValue.textValue, value: selectedValue.textValue });
            setSelectedNumberOption({ label: selectedValue.multiselect_number, value: selectedValue.multiselect_number });
        }
    }, [parameterValues, idNumber, parameterIdNumber, cellValue]);

    useEffect(() => {
        if (!isEditing) {
            setLocalValue(cellValue);
        }
    }, [cellValue, isEditing]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalValue(e.target.value);
    };

    const handleTextSelectChange = (option: any) => {
        setSelectedTextOption(option);

        const filteredNumberOptions = parameterValues[idNumber][parameterIdNumber]
            .filter((opt: any) => opt.textValue === option.value)
            .map((opt: any) => ({ label: opt.multiselect_number, value: opt.multiselect_number }));

        if (filteredNumberOptions.length > 0) {
            setSelectedNumberOption(filteredNumberOptions[0]);
        } else {
            setSelectedNumberOption({ label: '', value: '' });
        }
    };

    const handleInputBlur = () => {
        setIsEditing(false);
    };

    const saveMultiSelect = () => {
        if (!selectedTextOption || !selectedNumberOption) {
            toast.error('Por favor, selecciona ambos valores.');
            return;
        }

        const newValue = {
            ip: parametersBackup[Number(id)].connection_ip,
            id: parseInt(parameter_id),
            value: parameterValues[idNumber][parameterIdNumber].find((option: any) => option.textValue === selectedTextOption.value && option.multiselect_number === selectedNumberOption.value).value,
            min: min,
            max: max,
            idmap_parameter_id: parametersBackup[Number(id)].parameters.find((parameter: any) => parameter.parameter_id === parameter_id).idmap_id
        };

        if (socket) socket.emit('ChangingParameterValue', newValue);
        toast.info('Se ha enviado la modificación. Por favor espere...');

        updateData(cellRow.index, cellColumn.id, selectedTextOption.label + " " + selectedNumberOption.label);
        toggleModal();
    };

    const handleNumberSelectChange = (option: any) => {
        setSelectedNumberOption(option);
    };

    const confirmButtonAction = () => {
        const newValue = {
            ip: parametersBackup[Number(id)].connection_ip,
            id: parseInt(parameter_id),
            value: 1,
            min: min,
            max: max,
            idmap_parameter_id: parametersBackup[Number(id)].parameters.find((parameter: any) => parameter.parameter_id === parameter_id).idmap_id
        };

        if (socket) socket.emit('ChangingParameterValue', newValue);
        updateData(cellRow.index, cellColumn.id, '1');
        toast.info('Se ha enviado la modificación. Por favor espere...');
        toggleConfirmModal();
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };


    const toggleInputModal = async () => {
        const p319ParameterIndex = 'P 3.19.1.1';
        const p319Parameter = parametersBackup[Number(id)].parameters.find((parameter: any) => parameter.index === p319ParameterIndex);
        const parameters = isParameterInLogicProgrammerMode(parametersBackup[Number(id)].parameters, parameterData);
        if (parametersBackup[Number(id)].device_range === "Vacon" && p319Parameter && parameters) {
            const parameterInfo = {
                connection_id: Number(id),
                parameter_id: p319Parameter.parameter_id,
                id_map_parameter: p319Parameter.idmap_id,
                parameterValues: parameterValues[Number(id)][parameterData.id],
                parametersBackup: parametersBackup[Number(id)].parameters
            };

            setWaiting(true);

            const response: any = await dispatch(getValueModbusByParameter(parameterInfo));

            if (response.payload && response.payload.value === '0' && parameters) {
                toast.error('Por favor, cambie a modo programación el programador lógico.');
                setWaiting(false);
                return;
            }

            setWaiting(false);
        }


        if (isMotorDataParameter(Number(parameter_id))) {

            setWaiting(true);

            const parameterInfoRunDrive = {
                connection_id: Number(id),
                parameter_id: 2101,
                id_map_parameter: null,
                bit_parameter_id: 1,
                parameterValues: [],
                parametersBackup: []
            }

            const response: any = await dispatch(getValueModbusByParameter(parameterInfoRunDrive));




            if (response.payload && response.payload.value === '1') {
                toast.error('Para cambiar este parámetro el motor tiene que estar parado');
                setWaiting(false);
                return
            }


        }

        if (!inputModalOpen) {
            setOriginalValue(localValue);
            setInputModalOpen(true);
            updateModbusValue();
        } else {
            setLocalValue(originalValue);
            setInputModalOpen(false);
        }
    };

    const closeModal = () => {
        setLocalValue(originalValue); // Restaurar el valor original al cerrar el modal sin guardar
        setModalOpen(false);
    };

    const closeModalInput = () => {
        setLocalValue(originalValue); // Restaurar el valor original al cerrar el modal sin guardar
        setInputModalOpen(false);
    };

    const handleSelectChange = async (selectedOption: any) => {
        const p319ParameterIndex = 'P 3.19.1.1';
        const p319Parameter = parametersBackup[Number(id)].parameters.find((parameter: any) => parameter.index === p319ParameterIndex);

        if (parametersBackup[Number(id)].device_range === "Vacon" && p319Parameter) {
            const parameterInfo = {
                connection_id: Number(id),
                parameter_id: p319Parameter.parameter_id,
                id_map_parameter: p319Parameter.idmap_id,
                parameterValues: parameterValues[Number(id)][parameterData.id],
                parametersBackup: parametersBackup[Number(id)].parameters
            };

            const response: any = await dispatch(getValueModbusByParameter(parameterInfo));

            const parameters = isParameterInLogicProgrammerMode(parametersBackup[Number(id)].parameters, parameterData);

            if (response.payload && response.payload.value === '0' && parameters) {
                toast.error('Por favor, cambie a modo programación el programador lógico.');
                return;
            }
        }

        if (parameterData.variable === 'Aplicación') {
            setTempOption(selectedOption);
            toggleConfirmModal();
            return;
        }

        const newValue = {
            ip: parametersBackup[Number(id)].connection_ip,
            id: parseInt(parameter_id),
            value: selectedOption.value,
            min: min,
            max: max,
            idmap_parameter_id: parametersBackup[Number(id)].parameters.find((parameter: any) => parameter.parameter_id === parameter_id).idmap_id
        };

        if (parametersBackup[Number(id)].device_range === "Vacon" &&
            (
                parseInt(parameter_id) === 1686 ||
                parseInt(parameter_id) === 334 ||
                parseInt(parameter_id) === 335 ||
                parseInt(parameter_id) === 1652 ||
                parseInt(parameter_id) === 1655
            )
        ) {
            const response = await dispatch(checkAnalogicInFavorites({
                parameter_id: parseInt(parameter_id),
                connection_id: Number(id),
                value: selectedOption.value,
            }));

            if (response.payload.is_in_favorites) {
                setAnalogicModalMessage(response.payload.message);
                setAnalogicModalOpen(true);
                return;
            }
        }

        if (socket) socket.emit('ChangingParameterValue', newValue);
        toast.info('Se ha enviado la modificación. Por favor espere...');
    };

    const toggleConfirmModal = () => {
        setConfirmModalOpen(!confirmModalOpen);
    };

    const confirmApplicationChange = () => {
        if (!tempOption) return;

        const newValue = {
            ip: parametersBackup[Number(id)].connection_ip,
            id: parseInt(parameter_id),
            value: tempOption.value,
            min: min,
            max: max,
            idmap_parameter_id: parametersBackup[Number(id)].parameters.find((parameter: any) => parameter.parameter_id === parameter_id).idmap_id
        };

        if (socket) socket.emit('ChangingParameterValue', newValue);
        toast.info('Se ha enviado la modificación. Por favor espere...');
        dispatch(setDeleteBackup(true));

        // Actualizar el valor en la tabla después de confirmar el cambio
        updateData(cellRow.index, cellColumn.id, tempOption.label);
        setTempOption(null); // Limpiar el valor temporal
        toggleConfirmModal();
    };

    const toggleAnalogicModal = () => {
        setAnalogicModalOpen(!analogicModalOpen);
    };

    const updateModbusValue = async () => {


        const parameter = parametersBackup[Number(id)].parameters.find((parameter: any) => parameter.parameter_id.toString() === parameter_id.toString());


        const parameterInfo = {
            id_map_parameter: parametersBackup[Number(id)].parameters.find((parameter: any) => parameter.parameter_id === parameter_id).idmap_id,
            parameter_id: parameter_id,
            connection_id: Number(id),
            parameterValues: parameterValues[Number(id)][parameter.id],
            parametersBackup: parametersBackup[Number(id)].parameters
        };



        try {
            const data = await dispatch(getValueModbusByParameter(parameterInfo));


            if (data.payload && data.payload.value !== undefined) {


                if (!isEditing) {

                    updateData(cellRow.index, cellColumn.id, data.payload.value);


                    setLocalValue(data.payload.value);

                    setOriginalValue(data.payload.value); // Actualizar el valor original
                }
                setWaiting(false); // Ocultar el toast de espera
            } else {
                throw new Error('Hubo un problema a la hora de actualizar el valor');
            }
        } catch (error) {
            console.error("Error durante la actualización del valor:", error);
            toast.error('Hubo un problema a la hora de actualizar el valor');
            setWaiting(false); // Ocultar el toast de espera en caso de error
        }
    };

    const handleSaveValue = () => {
        const inputValue = parseFloat(localValue.replace(',', '.'));
        const minValue = parseFloat(min?.replace(',', '.') || '0');
        const maxValue = parseFloat(max?.replace(',', '.') || '0');

        if (inputValue < minValue || inputValue > maxValue) {
            toast.error(`El valor debe estar entre ${min} y ${max}`);
            return;
        }

        const newValue = {
            ip: parametersBackup[Number(id)].connection_ip,
            id: parseInt(parameter_id),
            value: localValue,
            min: min,
            max: max,
            idmap_parameter_id: parametersBackup[Number(id)].parameters.find((parameter: any) => parameter.parameter_id === parameter_id).idmap_id
        };

        if (socket) socket.emit('ChangingParameterValue', newValue);
        toast.info('Se ha enviado la modificación. Por favor espere...');
        updateData(cellRow.index, cellColumn.id, localValue); // Actualizar el valor en la tabla después de confirmar el cambio
        setInputModalOpen(false);
    };

    const selectStyles = {
        control: (base: any, state: any) => ({
            ...base,
            backgroundColor: backgroundColor || 'transparent',
            borderRadius: '20px',
            margin: '5px',
            width: '180px',
            borderColor: backgroundColor || state.isFocused ? backgroundColor : base.borderColor,
            boxShadow: state.isFocused ? `0 0 0 0.2rem ${backgroundColor || 'rgba(0,123,255,.25)'}` : 'none',
            '&:hover': {
                borderColor: state.isFocused ? backgroundColor || '#80bdff' : '#ced4da'
            }
        }),
        menu: (base: any) => ({
            ...base,
            borderRadius: '10px',
            zIndex: 9999,
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999,
        })
    };

    const inputStyle = {
        minWidth: '40px',
        maxWidth: '400px',
        border: 'none',
        padding: '0px',

        backgroundColor: backgroundColor || 'transparent'
    };

    if (!parameter_type) {
        return null;
    }

    if (cellColumn.id === 'value') {
        switch (parameter_type) {
            case 'Select':
                const options = parameterValues && parameterValues[idNumber]?.[parameterIdNumber]?.map((option: any) => ({
                    value: option.value,
                    label: option.textValue
                })) || [];

                const optionSelected = options.find((option: any) => option.value == cellValue) || null;

                return (
                    <>
                        {waiting ? (
                            <Spinner style={{ width: '1rem', height: '1rem' }} />
                        ) : (
                            <Select
                                options={options}
                                value={optionSelected}
                                onChange={handleSelectChange}
                                isSearchable={false}
                                onMenuOpen={updateModbusValue}
                                styles={selectStyles}
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                                isDisabled={user.role_id == rolesIds.operator || customHelp && !iconUrl}
                            />
                        )}

                        <Modal isOpen={analogicModalOpen} toggle={toggleAnalogicModal}>
                            <ModalHeader toggle={toggleAnalogicModal}>Advertencia</ModalHeader>
                            <ModalBody>
                                {analogicModalMessage}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleAnalogicModal}>Cerrar</Button>
                            </ModalFooter>
                        </Modal>

                        <ModalConfirm
                            isOpen={confirmModalOpen}
                            toggle={toggleConfirmModal}
                            title="Confirmación de escritura"
                            message="¿Estás seguro de que quieres cambiar la aplicación? Esto borrará el backup y tendrás que hacerlo de nuevo."
                            onConfirm={confirmApplicationChange}
                            onCancel={toggleConfirmModal}
                            idSelected={tempOption}
                            iconName='ri-information-line display-5 text-warning'
                        />
                    </>
                );
            case 'ReadWrite':
                return (
                    <>
                        {waiting ? (
                            <Spinner style={{ width: '1rem', height: '1rem' }} />
                        ) : (
                            <Input
                                type="text"
                                className='rounded-pill'
                                value={localValue}
                                onClick={() => { toggleInputModal(); }}
                                onChange={handleInputChange}
                                style={inputStyle}
                                disabled={user.role_id === rolesIds.operator || customHelp && !iconUrl}
                                readOnly
                            />
                        )}
                        <Modal isOpen={inputModalOpen} toggle={toggleInputModal}>
                            <ToastContainer
                                closeButton={false}
                                position={toast.POSITION.TOP_CENTER}
                                autoClose={3000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                limit={1}
                            />
                            <ModalHeader toggle={toggleInputModal}>{`Modificar Valor: ${variable}`}</ModalHeader>
                            <ModalBody style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                        type="text"
                                        className='rounded-pill'
                                        value={localValue}
                                        onChange={handleInputChange}
                                        style={{ width: '80px', textAlign: 'center' }}
                                    />
                                    {unit && <span style={{ marginLeft: '10px' }}>{unit}</span>}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" className='rounded-pill' onClick={handleSaveValue}>Guardar Cambios</Button>{' '}
                                <Button color="secondary" className='rounded-pill' onClick={closeModalInput}>Cancelar</Button>
                            </ModalFooter>
                        </Modal>
                    </>
                );
            case 'Button':
                return (
                    <>
                        <Button onClick={toggleConfirmModal} className='p-1 rounded-pill'>
                            Activar
                        </Button>
                        <ModalConfirm
                            isOpen={confirmModalOpen}
                            toggle={toggleConfirmModal}
                            title="Confirmación de escritura"
                            message="¿Estás seguro de que quieres escribir en este parámetro?"
                            onConfirm={confirmButtonAction}
                            onCancel={toggleConfirmModal}
                            idSelected={parameter_id}
                            iconName='ri-information-line display-5 text-warning'
                        />
                    </>
                );
            case 'Multiselect':
                const multiSelect = parameterValues && parameterValues[idNumber]?.[parameterIdNumber]?.find((option: any) => option.value == cellValue) || {};
                const multiSelectText = multiSelect.textValue;
                const multiSelectNumber = multiSelect.multiselect_number;

                const uniqueOptions = Array.from(
                    new Set(parameterValues[idNumber]?.[parameterIdNumber]?.map((option: any) => option.textValue))
                ).map((textValue) => {
                    const option = parameterValues[idNumber]?.[parameterIdNumber]?.find((opt: any) => opt.textValue === textValue);
                    return {
                        label: option.textValue,
                        value: option.textValue
                    };
                });

                const filteredNumberOptions = parameterValues[idNumber]?.[parameterIdNumber]?.filter((option: any) => option.textValue === (selectedTextOption ? selectedTextOption.label : '')).map((option: any) => ({
                    label: option.multiselect_number,
                    value: option.multiselect_number
                })) || [];

                const selectedValue = parameterValues && parameterValues[idNumber]?.[parameterIdNumber]?.find((option: any) => option.value == cellValue) || {};
                const selectedOption = selectedValue ? { label: selectedValue.textValue, value: selectedValue.textValue } : null;

                return (
                    <>
                        <Input
                            type="text"
                            value={selectedTextOption.label && selectedNumberOption.label ? `${selectedTextOption.label} ${selectedNumberOption.label}` : localValue}
                            readOnly
                            style={inputStyle}
                            onClick={toggleModal}
                        />
                        <Modal isOpen={modalOpen} toggle={toggleModal}>
                            <ModalHeader toggle={toggleModal}>Selecciona una opción</ModalHeader>
                            <ModalBody className='d-flex justify-content-center'>
                                <Select
                                    defaultValue={selectedOption}
                                    value={selectedTextOption}
                                    options={uniqueOptions}
                                    onChange={handleTextSelectChange}
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    isDisabled={user.role_id == rolesIds.operator || customHelp && iconUrl}
                                />
                                <Select
                                    defaultValue={selectedNumberOption}
                                    value={selectedNumberOption}
                                    options={filteredNumberOptions}
                                    onChange={handleNumberSelectChange}
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    isDisabled={user.role_id == rolesIds.operator || customHelp && iconUrl}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={saveMultiSelect}>Guardar</Button>{' '}
                                <Button color="secondary" onClick={closeModal}>Cancelar</Button>
                            </ModalFooter>
                        </Modal>
                    </>
                );
            case 'Visualization':
                const parameterWithTextValue = parameterValues && parameterValues[idNumber]?.[parameterIdNumber]?.find((option: any) => option.value == cellValue);
                const displayValue = parameterWithTextValue ? parameterWithTextValue.textValue : cellValue;

                return (
                    cellValue === '' ?
                        <Spinner style={{ width: '1rem', height: '1rem', borderWidth: '0.2em' }} /> :

                        <p className='m-0'>
                            {displayValue}
                        </p>
                );
            default:
                return null;
        }
    } else {
        return cellValue;
    }
};

export default ParameterRow;
