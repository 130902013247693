import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getCompanies as getCompaniesApi,
  createCompany as createCompanyApi,
  updateCompany as updateCompanyApi,
  deleteCompany as deleteCompanyApi,
} from "../../helpers/fakebackend_helper";

export const getCompanies = createAsyncThunk("company/getCompanies", async () => {
  try {
    const response = await getCompaniesApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const createCompany = createAsyncThunk("company/createCompany", async (company: any) => {
  try {
    const response: any = await createCompanyApi(company);
   
    if (response.id) {
      company.id = response.id;
    }
    return company;
  } catch (error) {
    return { 'error': error };
  }
});

export const updateCompany = createAsyncThunk("company/updateCompany", async (company: any) => {
  try {
    const response = await updateCompanyApi(company);
    return company;
  } catch (error) {
    return error;
  }
});



export const deleteCompany = createAsyncThunk("installation/deleteInstallation", async (company: any) => {
  try {
    const response = await deleteCompanyApi(company);
    return company;
  } catch (error) {
    return error;
  }
});