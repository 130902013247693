import React, { Fragment, useEffect, useState, useCallback } from "react";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row, Col, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { DefaultColumnFilter } from "Components/Common/filters";
import ParameterRow from './ParameterRow';

function GlobalFilter({
    globalFilter,
    setGlobalFilter,
    SearchPlaceholder,
}: any) {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value: any) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <Fragment>
            <CardBody>
                <Row className="g-3">
                    <Col>
                        <div className="search-box me-2 mb-2 d-inline-block col-12">
                            <input
                                onChange={(e) => {
                                    setValue(e.target.value);
                                    onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search rounded-pill"
                                placeholder={SearchPlaceholder}
                                value={value || ""}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Fragment>
    );
}

interface ParameterTableProps {
    columns?: any;
    data?: any;
    isPagination?: any;
    isGlobalFilter?: any;
    customPageSize?: any;
    tableClass?: any;
    theadClass?: any;
    divClass?: any;
    SearchPlaceholder?: any;
    isBordered?: any;
    id: number;
}

const ParameterTable = ({
    columns,
    data,
    isPagination,
    isGlobalFilter,
    customPageSize,
    tableClass,
    isBordered,
    theadClass,
    divClass,
    SearchPlaceholder,
    id,
}: ParameterTableProps) => {
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [currentData, setCurrentData] = useState(data);

    useEffect(() => {
        if (JSON.stringify(data) !== JSON.stringify(currentData)) {

            setCurrentData(data.filter((row: any) => row.is_active !== false));
        }
    }, [data]);

    const handleColumnSelection = useCallback((selectedOptions: any) => {
        const selectedValues = selectedOptions.map((option: any) => option.value);
        setSelectedColumns(columns.filter((column: any) => selectedValues.includes(column.Header)));
    }, [columns]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns: selectedColumns,
            data: currentData,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0,
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    const handleInputChange = useCallback((rowIndex: number, columnId: string, value: string) => {
        setCurrentData((prevData: any) => {
            const newData = [...prevData];
            newData[rowIndex] = {
                ...newData[rowIndex],
                [columnId]: value
            };
            return newData;
        });
    }, []);

    return (
        <Fragment>
            {isGlobalFilter && (
                <Row className="mb-3">
                    <Col lg={10}>
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            SearchPlaceholder={SearchPlaceholder}
                        />
                    </Col>
                </Row>
            )}

            <Row>
                <Col lg={12} className="mb-2">
                    <Select
                        isMulti
                        options={columns.map((column: any) => ({ value: column.Header, label: column.Header }))}
                        defaultValue={selectedColumns.map((column: any) => ({ value: column.Header, label: column.Header }))}
                        onChange={handleColumnSelection}
                        styles={{
                            control: (base: any) => ({
                                ...base,
                                borderRadius: '30px',
                                borderColor: 'gray',
                                boxShadow: 'none',
                                '&:hover': {
                                    borderColor: 'gray',
                                }
                            }),
                            multiValue: (base: any) => ({
                                ...base,
                                color: 'gray',
                                borderRadius: '30px',
                                borderColor: 'gray',
                            }),
                            multiValueLabel: (base: any) => ({
                                ...base,
                                color: 'gray',
                            }),
                        }}
                    />
                </Col>
            </Row>

            {currentData.length === 0 ? (
                 <div className="text-center my-4">
                 {/* Icono de parámetros usando la clase de Remix Icons (ri-bar-chart-fill) */}
                 <i className="ri-bar-chart-fill fs-2 text-secondary"></i>
                 <h5>No se ha seleccionado ningún grupo de parámetros.</h5>
             </div>
            ) : (
                <div style={{ maxHeight: '620px', overflowY: 'auto', overflowX: 'auto' }}>
                    <Table hover {...getTableProps()} className={`${tableClass} table-fixed`} bordered={isBordered} style={{ width: '100%' }}>
                        <thead className={theadClass}>
                            {headerGroups.map((headerGroup: any) => (
                                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column: any) => (
                                        <th key={column.id} className="py-0" style={{ minWidth: column.width, whiteSpace: 'nowrap' }}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row: any) => {
                                prepareRow(row);

                                const rowStyle = row.original.custom_help && !row.original.icon_url ? {
                                    backgroundColor: '#d3d3d3',
                                    color: '#343a40',
                                    opacity: 0.8,
                                } : {};

                                return (
                                    <tr key={row.id} {...row.getRowProps()} style={rowStyle}>
                                        {row.cells.map((cell: any) => (
                                            <td key={cell.column.id} className="pt-0 pb-0 pl-1" style={{ whiteSpace: 'nowrap' }}>
                                                {cell.column.id === "value" ? (
                                                    <ParameterRow
                                                        cell={cell}
                                                        updateData={handleInputChange}
                                                        id={id}
                                                        customHelp={row.original.custom_help}
                                                        iconUrl={row.original.icon_url}
                                                    />
                                                ) : (
                                                    cell.render("Cell")
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            )}

            {isPagination && (
                <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                    <div className="col-sm">
                        <div className="text-muted">Mostrando<span className="fw-semibold ms-1">{page.length}</span> de <span className="fw-semibold">{data.length}</span> Resultados</div>
                    </div>
                    <div className="col-sm-auto">
                        <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                            <li className={!canPreviousPage ? "page-item disabled" : "page-item"}>
                                <Link to="#" className="page-link" onClick={previousPage}>Anterior</Link>
                            </li>
                            {pageOptions.map((item: any, key: any) => (
                                <React.Fragment key={key}>
                                    <li className="page-item">
                                        <Link to="#" className={pageIndex === item ? "page-link active" : "page-link"} onClick={() => gotoPage(item)}>{item + 1}</Link>
                                    </li>
                                </React.Fragment>
                            ))}
                            <li className={!canNextPage ? "page-item disabled" : "page-item"}>
                                <Link to="#" className="page-link" onClick={nextPage}>Siguiente</Link>
                            </li>
                        </ul>
                    </div>
                </Row>
            )}
        </Fragment>
    );
};

export default ParameterTable;
