import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { createSelector } from 'reselect';
import { getDevice } from 'slices/thunks';

interface DeviceDetailsProps {
    modal_standard: boolean;
    tog_standard: () => void;
    connection: any;
}

const DeviceDetails = ({ modal_standard, tog_standard, connection }: DeviceDetailsProps) => {
    const dispatch: any = useDispatch();
    const selectDevicesState = (state: any) => state.Devices;

    const installationDataProperties = createSelector(
        selectDevicesState,
        (state: any) => state
    );

    const { device } = useSelector(installationDataProperties);

    useEffect(() => {
        if (modal_standard) {
            dispatch(getDevice(connection.device_id));
        }
    }, [modal_standard, dispatch, connection.id]);



    return (
        <Modal id="ConfirmAddGraphic"
            isOpen={modal_standard}
            toggle={tog_standard}
        >
            <ModalHeader>
                Detalles del dispositivo
            </ModalHeader>
            <ModalBody>
                <div className="">
                    <label htmlFor="tipo" className="col-form-label">Tipo</label>
                    <Input type="text" className="form-control rounded-pill" id="tipo" value={device?.type_device || ''} readOnly />
                </div>
                <div className="">
                    <label htmlFor="tipo" className="col-form-label">Nombre</label>
                    <Input type="text" className="form-control rounded-pill" id="tipo" value={device?.name || ''} readOnly />
                </div>
                <div className="">
                    <label htmlFor="input" className="col-form-label">Input</label>
                    <Input type="text" className="form-control rounded-pill" id="input" value={device?.input || ''} readOnly />
                </div>
                <div className="">
                    <label htmlFor="output" className="col-form-label">Output</label>
                    <Input type="text" className="form-control rounded-pill" id="output" value={device?.output || ''} readOnly />
                </div>
                <div className="">
                    <label htmlFor="potencia" className="col-form-label">Potencia</label>
                    <Input type="text" className="form-control rounded-pill" id="potencia" value={device?.power || ''} readOnly />
                </div>
                <div className="">
                    <label htmlFor="sn" className="col-form-label">S/N</label>
                    <Input type="text" className="form-contro rounded-pill" id="sn" value={connection.sn || ''} readOnly />
                </div>
                {
                    connection.b_id ?
                    <div className="mb-3">
                    <label htmlFor="bid" className="col-form-label">B.ID</label>
                    <Input type="text" className="form-control rounded-pill" id="bid" value={connection.b_id || ''} readOnly />
                </div> :''
                }
               
            </ModalBody>
        </Modal>
    );
};

export default DeviceDetails;
