import React, { Fragment, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect
} from "react-table";
import { Table, Row, Col, CardBody, Button } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import { Link } from "react-router-dom";
import Select from "react-select";

// Define a default UI for filterin<g>
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  SearchPlaceholder,
}: any) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <CardBody>
        <form>
          <Row className="g-3">
            <Col>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <input
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control search rounded-pill /"
                  placeholder={SearchPlaceholder}
                  value={value || ""}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
          </Row>
        </form>
      </CardBody>
    </React.Fragment>
  );
}

interface TableContainerProps {
  columns?: any;
  data?: any;
  isPagination?: any;
  isGlobalSearch?: any;
  isGlobalFilter?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  className?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  SearchPlaceholder?: any;
  isBordered?: any;
  button?: boolean;
  buttonName?: string;
  toogleModal?: () => void;
  useDragAndDrop?: boolean;
  onDragEnd?: (newData: any[]) => void;
}

const TableContainer = ({
  columns,
  data,
  isPagination,
  isGlobalSearch,
  isGlobalFilter,
  customPageSize,
  tableClass,
  isBordered,
  theadClass,
  trClass,
  thClass,
  divClass,
  SearchPlaceholder,
  button,
  buttonName,
  toogleModal,
  useDragAndDrop,
  onDragEnd
}: TableContainerProps) => {
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [currentData, setCurrentData] = useState(data);

  useEffect(() => {
    setCurrentData(data);
  }, [data]);

  const handleColumnSelection = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: any) => option.value);
    setSelectedColumns(columns.filter((column: any) => selectedValues.includes(column.Header)));
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: selectedColumns,
      data: currentData,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0, /* sortBy: [
          {
            desc: true,
          },
        ], */
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: "none",
    background: isDragging ? "rgba(255, 255, 255, 0.8)" : '',
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? "#F0F0F0" : '',
    overflow: 'auto',
    width: '100%'
  });

  const options = columns.map((column: any) => ({ value: column.Header, label: column.Header }));

  const generateSortingIndicator = (column: any) => {
    return column.isSorted ? (column.isSortedDesc ? <span>&#8593;</span> : <span>&#8595;</span>) : "";
  };

  const onChangeInSelect = (event: any) => {
    setPageSize(Number(event.target.value));
  };

  const handleOnDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination || destination.index === source.index) {
      return;
    }

    const newData = Array.from(data);
    const [removed] = newData.splice(source.index, 1);
    newData.splice(destination.index, 0, removed);

    setCurrentData(newData)
    if (onDragEnd) {
      onDragEnd(newData);
    }
  };

  return (
    <Fragment>
      {(isGlobalSearch || isGlobalFilter) && (
        <Row className=" mb-2 mb-md-3 ">
          {isGlobalSearch && (
            <Col lg={1}>
              <select
                className="form-select "
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
          )}
          <Col lg={10}>
            {isGlobalFilter && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                SearchPlaceholder={SearchPlaceholder}
              />
            )}
          </Col>
          {button ? (
            <>
              <Col md={2} className="d-none d-md-block">
                <Button className="btn btn-primary rounded-pill" onClick={toogleModal}>
                  <i className="fas fa-user-plus"></i> {buttonName}
                </Button>
              </Col>
              <Col xs={12} className="d-flex justify-content-center d-md-none">
                <Button className="btn btn-primary rounded-pill" onClick={toogleModal}>
                  <i className="fas fa-user-plus"></i> {buttonName}
                </Button>
              </Col>
            </>
          ) : null}
        </Row>
      )}

      <Row className="mb-3">
        <Col lg={button ? 8 : 12} className="mb-2">
          <Select
            isMulti
            options={options}
            defaultValue={selectedColumns.map((column: any) => ({ value: column.Header, label: column.Header }))}
            onChange={handleColumnSelection}
            styles={{
              control: (base: any) => ({
                ...base,
                borderRadius: '30px',
                borderColor: 'gray',
                boxShadow: 'none',
                '&:hover': {
                  borderColor: 'gray',
                }
              }),
              multiValue: (base: any) => ({
                ...base,
                color: 'gray',
                borderRadius: '30px',
                borderColor: 'gray',
              }),
              multiValueLabel: (base: any) => ({
                ...base,
                color: 'gray',
              }),
            }}
          />
        </Col>
      </Row>

      <div className={`${divClass} overflow-auto`} style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <Table hover {...getTableProps()} className={tableClass} bordered={isBordered}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup: any) => (
              <tr className={trClass} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th key={column.id} className={thClass} style={{ minWidth: '30px', whiteSpace: 'nowrap' }}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {useDragAndDrop ? (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppable-table">
                {(provided: any, snapshot: any) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    {page.map((row: any, index: any) => {
                      prepareRow(row);
                      return (
                        <Draggable key={row.original.id} draggableId={row.original.id.toString()} index={index}>
                          {(provided: any, snapshot: any) => (
                            <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              {row.cells.map((cell: any) => (
                                <td key={cell.column.id} className="p-1" style={{ minWidth: '30px', whiteSpace: 'nowrap' }} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                              ))}
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <tbody>
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => (
                      <td key={cell.column.id} className="p-1" style={{ minWidth: '30px', whiteSpace: 'nowrap' }} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </div>

      {isPagination && (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">Mostrando<span className="fw-semibold ms-1">{page.length}</span> de <span className="fw-semibold">{data.length}</span> Resultados
            </div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              <li className={!canPreviousPage ? "page-item disabled" : "page-item"}>
                <Link to="#" className="page-link" onClick={previousPage}>Anterior</Link>
              </li>
              {pageOptions.map((item: any, key: any) => {
                const isCurrent = pageIndex === item;
                const isFirst = item === 0;
                const isLast = item === pageOptions.length - 1;
                const isNearCurrent = Math.abs(pageIndex - item) <= 2;

                if (isFirst || isLast || isNearCurrent) {
                  return (
                    <React.Fragment key={key}>
                      <li className={isCurrent ? "page-item active" : "page-item"}>
                        <Link to="#" className="page-link" onClick={() => gotoPage(item)}>{item + 1}</Link>
                      </li>
                    </React.Fragment>
                  );
                }

                if (Math.abs(pageIndex - item) === 3) {
                  return (
                    <React.Fragment key={key}>
                      <li className="page-item disabled">
                        <span className="page-link">...</span>
                      </li>
                    </React.Fragment>
                  );
                }

                return null;
              })}
              <li className={!canNextPage ? "page-item disabled" : "page-item"}>
                <Link to="#" className="page-link" onClick={nextPage}>Siguiente</Link>
              </li>
            </ul>
          </div>
        </Row>
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
