
import ModalAddDevice from "Components/Common/Modals/ModalAddDevice";
import rolesIds from "constants/roles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { getInstallationZones, getInstallations } from "slices/thunks";
interface ChildItem {
    id: string;
    label: string;
    link: string;
    isChildItem?: boolean;
    stateVariables?: boolean;
    childItems?: ChildItem[];
    click?: ((e: any) => void) | null;  // Explicitly allow function or null
}

interface SubItem {
    id: string;
    label: string;
    link: string;
    isChildItem?: boolean;
    stateVariables?: boolean;
    childItems?: ChildItem[];
    click?: ((e: any) => void) | null;  // Explicitly allow function or null
}

interface MenuItem {
    id: string;
    label: string;
    icon: string;
    link: string;
    stateVariables?: boolean;
    subItems?: SubItem[];
    click?: ((e: any) => void) | null;  // Explicitly allow function or null
}

interface Devices {
    id: string;
    isOpen: boolean;
}

interface ZoneState {
    id: string;
    isOpen: boolean;
    devices: Devices
}

interface InstallationState {
    id: string;
    isOpen: boolean;
    zones: ZoneState[];
}



const Navdata = () => {

    const dispatch: any = useDispatch();

    const selectInstallationsState = (state: any) => state.Installations;
    const selectLoginState = (state: any) => state.Login;
    const selectInstallationZonesState = (state: any) => state.InstallationZones;

    const installationDataProperties = createSelector(
        selectInstallationsState,
        (state: any) => state
    );

    const loginDataProperties = createSelector(
        selectLoginState,
        (state: any) => state
    );

    const installationZoneDataProperties = createSelector(
        selectInstallationZonesState,
        (state: any) => state
    );

    const { installationZones } = useSelector(installationZoneDataProperties);
    const { installations } = useSelector(installationDataProperties);
    const { user } = useSelector(loginDataProperties);

    const role_id = user ? user.role_id : null;

    const [menuState, setMenuState] = useState<InstallationState[]>([]);





    const toggleInstallation = (installationName: string) => {

        setMenuState(prevState => {
            const updatedMenuState = prevState.map(installation => {

                if (installation.id === installationName) {
                    return {
                        ...installation,
                        isOpen: !installation.isOpen,
                        zones: installation.zones.map(zone => ({
                            ...zone,
                            isOpen: false,
                            devices: { ...zone.devices, isOpen: false }
                        }))
                    };
                } else {
                    return {
                        ...installation,
                        isOpen: false,
                        zones: installation.zones.map(zone => ({
                            ...zone,
                            isOpen: false,
                            devices: { ...zone.devices, isOpen: false }
                        }))
                    };
                }
            });

            return updatedMenuState;
        });
    };


    const toggleZone = (installationName: string, zoneName: string) => {
        setMenuState(prevState => {
            const updatedMenuState = prevState.map(installation => {
                // Chequear si es la instalación correcta
                if (installation.id === installationName) {
                    const updatedZones = installation.zones.map(zone => {
                        // Cambiar el estado de isOpen solo para la zona seleccionada
                        // y cerrar todas las otras zonas
                        if (zone.id === zoneName) {
                            return {
                                ...zone,
                                isOpen: !zone.isOpen, // Alternar el estado isOpen
                                devices: {
                                    ...zone.devices,
                                    isOpen: zone.isOpen ? false : zone.devices.isOpen // Si se cierra la zona, también cerrar los dispositivos
                                }
                            };
                        } else {
                            return {
                                ...zone,
                                isOpen: false, // Cerrar todas las demás zonas
                                devices: { ...zone.devices, isOpen: false } // Cerrar los dispositivos de las demás zonas
                            };
                        }
                    });
                    return {
                        ...installation,
                        zones: updatedZones
                    };
                }
                return installation;
            });
            return updatedMenuState;
        });
    };

    const toggleDevice = (installationName: string, zoneName: string, deviceId: string) => {

        setMenuState(prevState => {
            const updatedMenuState = prevState.map(installation => {
                if (installation.id === installationName) {
                    const updatedZones = installation.zones.map(zone => {
                        if (zone.id === zoneName) {
                            // Aquí se actualiza el estado de isOpen para el dispositivo específico
                            let updatedDevices = { ...zone.devices };


                            if (updatedDevices.id === deviceId) {

                                updatedDevices = {
                                    ...updatedDevices,
                                    isOpen: !updatedDevices.isOpen
                                };
                            }
                            return {
                                ...zone,
                                devices: updatedDevices
                            };
                        }
                        return zone;
                    });
                    return {
                        ...installation,
                        zones: updatedZones
                    };
                }
                return installation;
            });
            return updatedMenuState;
        });
    };

    // Función auxiliar para verificar si el rol actual tiene permisos para ver el menú
    function hasRole(requiredRoles: number[]): boolean {
        return requiredRoles.includes(role_id);
    }

    // Definición de elementos estáticos del menú en base a los roles
    const staticMenuItems = [
        {
            id: "users",
            label: "Usuarios",
            icon: "bx bx-user-circle",
            link: "/usuarios",
            click: null,
            subItems: [],
            requiredRoles: [rolesIds.aplicationAdministrator, rolesIds.companyAdministrator, rolesIds.supervisor, rolesIds.technician]
        },
        {
            id: "installations",
            label: "Instalaciones y zonas",
            icon: "bx bx-buildings",
            link: "/instalaciones",
            click: null,
            subItems: [],
            requiredRoles: [rolesIds.aplicationAdministrator, rolesIds.companyAdministrator, rolesIds.supervisor]
        }


    ].filter(item => hasRole(item.requiredRoles));  // Filtra los elementos según los roles

    // Construcción final del array menuItems
    const menuItems: MenuItem[] = [
        ...staticMenuItems,
        ...(user && Array.isArray(installations) && installations.length > 0
            ?
            installations.filter((installation: any) => installation.is_active)
                .map((installation: any) => ({
                    id: installation.id,
                    label: installation.installation_name,
                    icon: "bx bx-building",
                    link: `/installation/${installation.id}`,
                    stateVariables: installation.stateVariables,
                    click: installation.click,
                    subItems:
                        installation.zones && installation.zones.length > 0
                            ?

                            installation.zones.map((zone: any) => ({
                                id: zone.id,
                                label: zone.zone_name,
                                link: "#",
                                isChildItem: true,
                                stateVariables: zone.stateVariables,
                                click: zone.click,
                                installation_id: installation.id,
                                installation_name: installation.installation_name,
                                is_active: zone.is_active,
                                zone_description: zone.zone_description,
                                zone_name: zone.zone_name,
                                childItems: zone.connections && zone.connections.length > 0
                                    ? [
                                        {
                                            id: `dashboard_${zone.id}`,
                                            label: "Dashboard",
                                            link: `/dashboard/zona/${zone.id}`
                                        },

                                        {
                                            id: `sinoptico_${zone.id}`,
                                            label: "Sinóptico",
                                            link: `/sinoptico/zona/${zone.id}`
                                        },

                                        {
                                            id: `devices_${zone.id}`,
                                            label: "Dispositivos",
                                            link: "#",
                                            isChildItem: true,
                                            click: null,
                                            stateVariables: zone.stateVariables,
                                            childItems:
                                                zone.connections.filter((connection: any) => connection.is_active)
                                                    .map((connection: any) => ({
                                                        id: connection.id,
                                                        label: `${connection.name}`,
                                                        link: `/dispositivo/${connection.id}`,
                                                        img: connection.img,
                                                        is_active: connection.is_active
                                                    }))
                                        },

                                        {
                                            id: "zoneRecords",
                                            label: "Registros de variables",
                                            link: `/registros/zona/${zone.id}`
                                        },
                                        {
                                            id: "alarmsAndFaultHistory",
                                            label: "Histórico Alarmas y fallos",
                                            link: `/registros/alarmas-fallos/${zone.id}`
                                        },
                                        {
                                            id: "createConnection",
                                            label: "Crea un dispositivo",
                                            link: "#"
                                        },

                                        /*  {
                                             id: `registers_${zone.id}`,
                                             label: "Registros",
                                             link: "#",
                                             isChildItem: true,
                                             click: null,
                                             stateVariables: false,
                                             childItems: [
                                                 ,
                                                
                                             ]
                                         } */
                                    ]
                                    : [{
                                        id: `dashboard_${zone.id}`,
                                        label: "Dashboard",
                                        link: `/dashboard/zona/${zone.id}`
                                    }, {
                                        id: "createConnection",
                                        label: "Crea un dispositivo",
                                        link: "#"
                                    }]
                            }))
                            : []  // Si no hay zonas, subItems será un arreglo vacío
                }))
            : []
        ),

    ];




    useEffect(() => {
        if (user) {

            // Crear una lista inicial para el estado del menú que incluya tanto las instalaciones como los ítems estáticos
            let initialState: any = [];

            // Agregar las instalaciones al estado si existen

            // Agregar los elementos estáticos del menú si el usuario tiene los roles adecuados
            const staticItemsState = staticMenuItems.map(item => ({
                id: item.id,
                isOpen: false,
                link: item.link,
                zones: []  // Los elementos estáticos no tienen zonas
            }));

            initialState = initialState.concat(staticItemsState);

            const installationsState = Array.isArray(installations) && installations.filter((installation: any) => installation.is_active)  // Filtra las instalaciones inactivas
                .map((installation: any) => ({
                    id: installation.id,
                    isOpen: menuState.find((item: any) => item.id === installation.id)?.isOpen || false,
                    zones: installation.zones ? installation.zones.filter((zone: any) => zone.is_active).map((zone: any) => ({
                        id: zone.id,
                        isOpen: menuState.find((item: any) => item.id == installation.id)?.zones.find((zoneItem: any) => zoneItem.id == zone.id)?.isOpen || false,
                        devices: {
                            id: `devices_${zone.id}`,
                            isOpen: menuState.find((item: any) => item.id == installation.id)?.zones.find((zoneItem: any) => zoneItem.id == zone.id)?.devices.isOpen || false,
                        }
                    })) : []
                }));
            initialState = initialState.concat(installationsState);





            setMenuState(initialState);



        }
    }, [installations, user]);


    const renderMenuItems = (items: any) => {
        if (menuState && menuState.length > 0) {
            return items.map((item: any, index: number) => {
                // Asegurar que el estado para este ítem existe para evitar errores
                if (menuState[index]) {

                    // Configurar el evento click si está definido
                    if (item.hasOwnProperty('click')) {

                        item.click = (e: any) => {

                            if (item.id != "installations" && item.id != "users") {
                                e.preventDefault();
                            }

                            toggleInstallation(item.id);


                        };
                    }

                    // Actualizar 'stateVariables' basado en el estado 'isOpen' correspondiente
                    if (item.hasOwnProperty('stateVariables')) {
                        item.stateVariables = menuState[index].isOpen;
                    }

                    // Procesar subítems si existen y el estado para los subítems está definido
                    if (item.subItems) {
                        item.subItems.forEach((subItem: any, indexSubItem: number) => {

                            if (menuState[index].zones && menuState[index].zones[indexSubItem]) {

                                if (subItem.hasOwnProperty('click')) {
                                    subItem.click = (e: any) => {
                                        e.preventDefault();
                                        toggleZone(item.id, subItem.id);
                                    };
                                }

                                // Si 'stateVariables' está definido en el subItem, actualizarlo
                                if (subItem.hasOwnProperty('stateVariables')) {
                                    subItem.stateVariables = menuState[index].zones[indexSubItem].isOpen;
                                }

                                if (subItem.childItems) {
                                    subItem.childItems.forEach((childItem: any, indexChildItem: number) => {
                                        if (menuState[index].zones[indexSubItem].devices) {

                                            if (childItem.hasOwnProperty('click')) {
                                                childItem.click = (e: any) => {
                                                    e.preventDefault();
                                                    toggleDevice(item.id, subItem.id, childItem.id);
                                                };
                                            }

                                            if (childItem.hasOwnProperty('stateVariables')) {
                                                childItem.stateVariables = menuState[index].zones[indexSubItem].devices.isOpen;
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
                return item;
            });
        }
        return [];
    };



    return <div>
        {renderMenuItems(menuItems)}
    </div>;
};

export default Navdata;