import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import rolesIds from 'constants/roles';

const RedirectToActiveInstallation: React.FC = () => {
  const navigate = useNavigate();
  const installations = useSelector((state: any) => state.Installations.installations);
  const user = useSelector((state: any) => state.Profile.user);

  useEffect(() => {
    if (installations && Array.isArray(installations) && user) {
      const activeInstallation = installations.find((installation: any) => installation.is_active);

      if (activeInstallation && activeInstallation.zones && activeInstallation.zones.length > 0) {
        const firstZone = activeInstallation.zones[0];

        if (user.role_id === rolesIds.operator) {
          navigate(`/sinoptico/zona/${firstZone.id}`);
        } else {
          navigate(`/dashboard/instalacion/${activeInstallation.id}`);
        }
      }
    }
  }, [installations, user, navigate]);

  return null;
};

export default RedirectToActiveInstallation;
