import BreadCrumb from 'Components/Common/BreadCrumb';
import {SearchTable } from 'pages/Tables/ReactTables/ReactTable';
import React from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import UsersList from './UsersList';
import GeneralData from 'pages/Dashboard/GeneralData';

const Users = () => {
    document.title = "Usuarios | Ecentroserver";
    return (
        <React.Fragment>
             <BreadCrumb  pageTitle="Listado de usuarios"/>
            <div className="page-content">
                <Container fluid> 
                    <Col lg={12} className="order-xxl-0 order-first">
                      {/*   <GeneralData /> */}
                    </Col>
                    <Row>
                        <Col xs={12}>
                        <UsersList/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Users;