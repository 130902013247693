import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDailyAverageInstallationRegistersBetweenDates as getDailyAverageInstallationRegistersBetweenDatesApi,
  getIntallationRegistersBetweenDates as getInstallationRegistersBetweenDatesApi
} from "../../helpers/fakebackend_helper";

export const getDailyAverageInstallationRegistersBetweenDates = createAsyncThunk(
  "installationRegister/getDailyAverageInstallationRegistersBetweenDates",
  async ({ startDate, endDate, installationId }: { startDate: string; endDate: string; installationId: string }) => {
    try {
      const response = await getDailyAverageInstallationRegistersBetweenDatesApi(startDate, endDate, installationId);
      return response;
    } catch (error: any) {
      return { error: error.message };
    }
  }
);

export const getInstallationRegistersBetweenDates = createAsyncThunk(
  "installationRegister/getInstallationRegistersBetweenDates",
  async ({ startDate, endDate, installationId }: { startDate: string; endDate: string; installationId: string }) => {
    try {
      const response = await getInstallationRegistersBetweenDatesApi(startDate, endDate, installationId);
      return response;
    } catch (error: any) {
      return { error: error.message };
    }
  }
);

