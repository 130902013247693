import React, { Fragment, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect
} from "react-table";
import { Table, Row, Col, CardBody } from "reactstrap";
import { DefaultColumnFilter } from "../../Components/Common/filters";
import { Link } from "react-router-dom";
import Select from "react-select";
import ParameterRow from "pages/Parameters/ParameterRow";
import { ToastContainer } from "react-toastify";

function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  SearchPlaceholder,
}: any) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <CardBody>
        <form>
          <Row className="g-3">
            <Col>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <input
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control search rounded-pill /"
                  placeholder={SearchPlaceholder}
                  value={value || ""}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
          </Row>
        </form>
      </CardBody>
    </React.Fragment>
  );
}

interface TableContainerFavoritesProps {
  columns?: any;
  data?: any;
  isPagination?: any;
  isGlobalSearch?: any;
  isGlobalFilter?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  className?: any;
  thClass?: any;
  divClass?: any;
  SearchPlaceholder?: any;
  isBordered?: any;
  button?: boolean;
  buttonName?: string;
  toogleModal?: () => void;
  type: string;
  newOrder?: (result: any, type: string) => void;
  id: number;
}

const TableContainerFavorites = ({
  columns,
  data,
  isPagination,
  isGlobalSearch,
  isGlobalFilter,
  customPageSize,
  tableClass,
  isBordered,
  theadClass,
  thClass,
  divClass,
  SearchPlaceholder,
  button,
  buttonName,
  toogleModal,
  type,
  newOrder,
  id
}: TableContainerFavoritesProps) => {
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [currentData, setCurrentData] = useState(data);

  useEffect(() => {
    setCurrentData(data)
  }, [data])

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedData = Array.from(currentData);
    const [movedItem] = reorderedData.splice(result.source.index, 1);
    reorderedData.splice(result.destination.index, 0, movedItem);

    setCurrentData(reorderedData);

    if (newOrder) {
      newOrder(reorderedData, type);
    }
  };

  const handleColumnSelection = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: any) => option.value);
    setSelectedColumns(columns.filter((column: any) => selectedValues.includes(column.Header)));
  };

  const handleInputChange = useCallback((rowIndex: number, columnId: string, value: string) => {
    setCurrentData((prevData: any) => {
      const newData = [...prevData];
      newData[rowIndex] = {
        ...newData[rowIndex],
        [columnId]: value
      };
      return newData;
    });
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: selectedColumns,
      data: currentData,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const options = columns.map((column: any) => ({ value: column.Header, label: column.Header }));

  const generateSortingIndicator = (column: any) => {
    return column.isSorted ? (column.isSortedDesc ? <span>&#8593;</span> : <span>&#8595;</span>) : "";
  };

  const onChangeInSelect = (event: any) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <Fragment>
      {(isGlobalSearch || isGlobalFilter) && (
        <Row className="mb-3">
          {isGlobalSearch && (
            <Col lg={1}>
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
          )}
          <Col lg={10}>
            {isGlobalFilter && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                SearchPlaceholder={SearchPlaceholder}
              />
            )}
          </Col>
          {button ? (
            <Col md={2}>
              <button className="btn btn-primary" onClick={toogleModal}>
                <i className="fas fa-user-plus"></i> {buttonName}
              </button>
            </Col>
          ) : null}
        </Row>
      )}

      <Row>
        <Col lg={button ? 8 : 12} className="mb-2">
          <Row className="mb-3">
            <Col lg={button ? 8 : 12} className="mb-2">
              <Select
                isMulti
                options={options}
                defaultValue={selectedColumns.map((column: any) => ({ value: column.Header, label: column.Header }))}
                onChange={handleColumnSelection}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    borderRadius: '30px',
                    borderColor: 'gray',
                    boxShadow: 'none',
                    '&:hover': {
                      borderColor: 'gray',
                    }
                  }),
                  multiValue: (base: any) => ({
                    ...base,
                    color: 'gray',
                    borderRadius: '30px',
                    borderColor: 'gray',
                  }),
                  multiValueLabel: (base: any) => ({
                    ...base,
                    color: 'gray',
                  }),
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {currentData.length === 0 ? (
        <div className="text-center my-4">
          <i className='mdi mdi-star-off-outline fs-2' />
          <h5>No hay favoritos de {type} disponibles.</h5>
        </div>
      ) : (

        <div className="table-responsive" style={{ overflowX: 'auto', maxHeight: '49vh' }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="table-droppable">
              {(provided: any, snapshot: any) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className={`${divClass} ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}>
                  <Table hover {...getTableProps()} className={tableClass} bordered={isBordered}>
                    <thead className={theadClass}>
                      {headerGroups.map((headerGroup: any) => (
                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column: any) => (
                            <th key={column.id} className={thClass} {...column.getSortByToggleProps()} style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, whiteSpace: 'nowrap' }}>
                              {column.render("Header")}
                              {generateSortingIndicator(column)}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row: any, index: number) => {
                        prepareRow(row);
                        const rowData = currentData[row.index];
                        return (
                          <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                            {(provided: any, snapshot: any) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{ backgroundColor: rowData.color || 'transparent', ...provided.draggableProps.style }}
                                className={snapshot.isDragging ? 'dragging' : ''}
                              >
                                {row.cells.map((cell: any) => (
                                  <td key={cell.column.id} className="pt-0 pb-0 pl-1" style={{ minWidth: cell.column.minWidth, maxWidth: cell.column.maxWidth }}>
                                    {cell.column.id === "value" ? (
                                      <ParameterRow
                                        cell={cell}
                                        updateData={handleInputChange}
                                        id={id}
                                        backgroundColor={rowData.color}
                                      />
                                    ) : (
                                      cell.render("Cell")
                                    )}
                                  </td>
                                ))}
                              </tr>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </tbody>
                  </Table>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}

      {isPagination && (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">Showing <span className="fw-semibold ms-1">{rows.length}</span> of <span className="fw-semibold">{data.length}</span> Results</div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              <li className={!canPreviousPage ? "page-item disabled" : "page-item"}>
                <Link to="#" className="page-link" onClick={previousPage}>Anterior</Link>
              </li>
              {pageOptions.map((item: any, key: any) => (
                <React.Fragment key={key}>
                  <li className="page-item">
                    <Link to="#" className={pageIndex === item ? "page-link active" : "page-link"} onClick={() => gotoPage(item)}>{item + 1}</Link>
                  </li>
                </React.Fragment>
              ))}
              <li className={!canNextPage ? "page-item disabled" : "page-item"}>
                <Link to="#" className="page-link" onClick={nextPage}>Next</Link>
              </li>
            </ul>
          </div>
        </Row>
      )}
    </Fragment>
  );
};

TableContainerFavorites.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  type: PropTypes.string,
  onDragEnd: PropTypes.func
};

export default TableContainerFavorites;
