import TableContainer from 'Components/Common/TableContainerReactTable';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { ModalHeader, Spinner } from 'reactstrap';
import { createInstallation, deleteInstallation, updateInstallation, getInstallations as onGetInstallations, reorderInstallations } from 'slices/installations/thunk';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import ModalConfirm from 'Components/Common/Modals/ModalConfirm';
import { set } from 'lodash';
import { install } from 'echarts/types/dist/shared';
import ModalAddInstallation from './ModalInstallation';
import ModalInstallationZonesList from './ModalInstallationZonesList';
import ModalInstallation from './ModalInstallation';
import ModalHelp from 'Components/Common/Modals/ModalHelp';


const InstallationsList = () => {

    const dispatch: any = useDispatch();
    const selectInstallationZonesByUserState = (state: any) => state.InstallationZones;
    const selectLayoutState = (state: any) => state.Installations;
    const selectLoginState = (state: any) => state.Login;

    const loginDataProperties = createSelector(
        selectLoginState,
        (state: any) => state
    );

    const installationDataProperties = createSelector(
        selectLayoutState,
        (state: any) => state
    );
    const { installations } = useSelector(installationDataProperties);
    const { installationZonePermissions } = useSelector(loginDataProperties);

    useEffect(() => {
        dispatch(onGetInstallations());
    }, [dispatch]);

    const [modal, setModal] = useState(false);
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [zonesModal, setZonesModal] = useState(false);
    const [installation, setInstallation] = useState<any>();
    const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
    const toggleHelpModal = () => setIsHelpModalOpen(!isHelpModalOpen);
    const [currentMessageHelp, setCurrentMessageHelp] = useState<string>('');
    const [currentTitleHelp, setCurrentTitleHelp] = useState<string>('');

    /*     const canDeleteInstallation = (installation_id: number) => {
            console.log("installation_id", installation_id);
            console.log("installationZonePermissions", installationZonePermissions);
            const installationPermissions = installationZonePermissions.find((perm: any) => perm.installation_id == installation_id);
            console.log("installationPermissions", installationPermissions);
            if (!installationPermissions) return false;
    
            return installationPermissions.zones.every((zone: any) => zone.read_write);
        };
     */
    const toggle = () => {
        setModal(!modal);
    }

    useEffect(() => {
        if (!modal && !zonesModal) {
            setInstallation(null);
        }
    }, [modal, zonesModal]);

    const handleConfirmDeleteInstallation = async (installationId: number | null) => {
        setDeleteConfirmationModal(false);

        try {
            if (installationId) {
                const trial = await dispatch(deleteInstallation(installationId)).unwrap();
                toast.success('Instalación eliminada con éxito!');
                setInstallation(null);
            }
        } catch (error: any) {
            toast.error('Error al eliminar la instalación: ' + error.message);
        }
    };

    const handleCancelDeleteInstallation = () => {
        setInstallation(null);
        setDeleteConfirmationModal(false);
    };

    const editInstallationById = (installation: any) => {
        setInstallation(installation);
        toggle();
    };

    const deleteInstallationById = (installation: any) => {
        setInstallation(installation);
        toggleDeleteConfirmationModal();
    };

    const handleCreateOrEditInstallation = async (formData: any) => {
        try {
            let response = null;
            if (!installation) {
                response = await dispatch(createInstallation(formData)).unwrap();
                if (!response.error) {
                    toast.success('Instalación creada con éxito!');
                }
            } else {
                response = await dispatch(updateInstallation(formData)).unwrap();
                if (!response.error) {
                    toast.success('Instalación modificada con éxito!');
                }
            }

            if (response.error && !installation) {
                toast.error('Error al crear la instalación');
            } else if (response.error && installation) {
                toast.error('Error al modificar la instalación');
            }
        } catch (error: any) {
            toast.error('Error al crear la instalación: ' + error.message);
        }
    };

    const toggleDeleteConfirmationModal = () => {
        setDeleteConfirmationModal(!deleteConfirmationModal);
    };

    const toggleZonesModal = () => {
        setZonesModal(!zonesModal);
    };

    const getInstallationId = (installation: any) => {
        setInstallation(installation);
        toggleZonesModal();
    };

    const onDragEnd = (installations: any) => {
        dispatch(reorderInstallations(installations));
    };

    const allColumns = useMemo(
        () => [

            {
                Header: "Nombre",
                accessor: "installation_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Dirección",
                accessor: "address",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Empresa",
                accessor: "company_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Activo",
                accessor: "is_active",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }: any) => (
                    <div>
                        {value
                            ? <i className="ms-3 mdi mdi-check-bold fs-3 text-success" aria-hidden="true"></i>
                            : <i className="ms-3 mdi mdi-close-thick text-danger fs-3" aria-hidden="true"></i>}
                    </div>
                ),
            },
            {
                Header: "Ubicación",
                accessor: "location",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }: any) => (
                    <div>
                        {value
                            ? <a href={value} target="_blank" rel="noopener noreferrer" className="ms-4"><i className="mdi mdi-office-building-marker-outline fs-3" aria-hidden="true"></i></a>
                            : <i className="ms-4 mdi mdi-close-thick text-danger fs-3" aria-hidden="true"></i>}
                    </div>
                ),
            },

            {
                Header: () => (
                    <div>
                        <button
                            onClick={() => {
                                toggleHelpModal();
                                setCurrentTitleHelp("Ayuda de las zonas");
                                setCurrentMessageHelp("Aquí podrás crear una nueva zona, así como reordenarlas, editarlas o eliminarlas. Para poder acceder a las zonas de cada instalación haz clic en el botón de la casa para ver la zonas de cada instalación.");
                            }}
                            style={{
                                border: 'none',
                                background: 'none',
                                padding: 0,
                                outline: 'none',
                                boxShadow: 'none'
                            }}
                            className="btn btn-icon btn-sm"
                            title="Ayuda"
                        >
                            <i className="mdi mdi-help-circle fs-5 text-primary"></i>
                        </button>
                        Añadir o editar zona
                    </div>
                ),
                accessor: "zones",
                disableFilters: true,
                filterable: false,
                Cell: ({ row }: any) => (
                    <button
                        onClick={() => { getInstallationId(row.original) }}
                        className="btn btn-sm ms-3"
                        title="Zonas"
                    >
                        <i className="bx bx-home fs-5"></i>
                    </button>
                ),
            },
            {
                Header: "Acciones",
                accessor: "actions",
                disableFilters: true,
                filterable: false,
                Cell: ({ row }) => (
                    <div>
                        <button
                            className="btn btn-sm me-2" title="Editar"
                            onClick={() => { editInstallationById(row.original) }}
                        >
                            <i className="mdi mdi-pencil fs-5"></i>
                        </button>
                        <button
                            className="btn btn-danger btn-sm" title="Borrar"
                            /*   disabled={!canDeleteInstallation(row.original.id)} */
                            onClick={() => deleteInstallationById(row.original)}>
                            <i className="mdi mdi-delete fs-5"></i>
                        </button>
                    </div>
                ),
            }
        ],
        [installationZonePermissions]
    );

    return (
        <React.Fragment >
            <TableContainer
                columns={(allColumns || [])}
                data={(installations || [])}
                isPagination={false}
                isGlobalFilter={true}
                isBordered={false}
                customPageSize={100}
                className="custom-header-css table align-middle table-nowrap"
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                SearchPlaceholder='Busca una instalación'
                button={true}
                buttonName={"Añade una instalación"}
                toogleModal={toggle}
                useDragAndDrop={true}
                onDragEnd={onDragEnd}
            />
            <ModalInstallation
                isOpen={modal}
                toggle={toggle}
                onFormSubmit={handleCreateOrEditInstallation}
                initialData={installation}
            />
            <ModalConfirm
                isOpen={deleteConfirmationModal}
                toggle={toggleDeleteConfirmationModal}
                title={"Borrar instalación"}
                message={`¿Estás seguro de que quieres borrar ${installation && installation.installation_name} ?`}
                onConfirm={handleConfirmDeleteInstallation}
                onCancel={handleCancelDeleteInstallation}
                idSelected={installation && installation.id}
                iconName=' mdi mdi-delete display-5 text-danger'
                requireNameConfirmation={true}
                confirmationName={installation && installation.installation_name}
            />
            {
                zonesModal &&
                <ModalInstallationZonesList
                    isOpen={zonesModal}
                    toggle={toggleZonesModal}
                    idSelected={installation && installation.id}
                />
            }

            <ModalHelp
                isOpen={isHelpModalOpen}
                toggle={toggleHelpModal}
                title={currentTitleHelp}
                body={currentMessageHelp}
                buttonText="Cerrar"
            />

        </React.Fragment >
    );
};

export default InstallationsList;
