import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Input, Label } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import rolesIds from 'constants/roles';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getCompanies } from 'slices/thunks';

interface ModalInstallationProps {
    isOpen: boolean;
    toggle: () => void;
    onFormSubmit: (formData: any) => Promise<void>;
    initialData?: Installation;  // Datos iniciales para la edición
}

interface OptionType {
    label: string;
    value: string;
}

interface Installation {
    installation_name: string;
    address: string;
    company?: string; // Cambiado a string para coincidir con los valores de Select
    location: string;
    is_active: boolean;
}

const ModalInstallation: React.FC<ModalInstallationProps> = ({ isOpen, toggle, onFormSubmit, initialData }) => {
    const dispatch: any = useDispatch();
    const selectLoginState = (state: any) => state.Login;
    const selectCompaniesState = (state: any) => state.Companies;

    const loginDataProperties = createSelector(
        selectLoginState,
        (state: any) => state
    );

    const companyDataProperties = createSelector(
        selectCompaniesState,
        (state: any) => state
    );

    const { user } = useSelector(loginDataProperties);
    const { companies } = useSelector(companyDataProperties);

    const currentUserRole = user.role_id;

    const companyOptions: OptionType[] = companies.map((company: any) => ({
        label: company.company_name,
        value: company.id.toString()
    }));

    const validationSchema = Yup.object().shape({
        installation_name: Yup.string().required('El nombre de la instalación es obligatorio'),
        address: Yup.string().required('La dirección de la instalación es obligatoria'),
        company: rolesIds.aplicationAdministrator == currentUserRole ? Yup.string().required('La empresa de la instalación es obligatoria') : Yup.string(),
        location: Yup.string(),
        is_active: Yup.boolean(),
    });

    const modalTitle = initialData ? 'EDITAR INSTALACIÓN' : 'AÑADE UNA INSTALACIÓN';



    useEffect(() => {
        dispatch(getCompanies());
    }, [dispatch]);

    return (
        <Modal isOpen={isOpen} size="md">
            <ModalHeader>{modalTitle}</ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={initialData || {
                        installation_name: '',
                        address: '',
                        company: '', // Valor inicial para el campo company
                        location: '',
                        is_active: true,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        onFormSubmit(values);
                        setSubmitting(false);
                        toggle(); // Opcional: Cerrar el modal después de enviar
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <Row>
                                <Col md={12}>
                                    <label htmlFor="installation_name">Nombre de la instalación *</label>
                                    <Field name="installation_name">
                                        {({ field }: FieldProps) => (
                                            <input
                                                {...field}
                                                type="text"
                                                maxLength={13}
                                                placeholder="Nombre de la instalación"
                                                className={`form-control rounded-pill ${errors.installation_name && touched.installation_name ? 'is-invalid' : ''}`}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="installation_name" component="div" className="text-danger" />
                                </Col>
                                <Col md={12} className='mt-2'>
                                    <label htmlFor="address">Dirección *</label>
                                    <Field name="address">
                                        {({ field }: FieldProps) => (
                                            <input
                                                {...field}
                                                type="text"
                                                placeholder="Dirección de la instalación"
                                                className={`form-control rounded-pill ${errors.address && touched.address ? 'is-invalid' : ''}`}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="address" component="div" className="text-danger" />
                                </Col>
                                {
                                    rolesIds.aplicationAdministrator == currentUserRole &&
                                    <Col md={12} className='mt-2'>
                                        <label htmlFor="company">Empresa *</label>
                                        <Field name="company">
                                            {({ form, field, meta }: FieldProps) => (
                                                <Select
                                                    options={companyOptions}
                                                    classNamePrefix="select"
                                                    value={companyOptions.find(option => option.value === form.values.company)}
                                                    onChange={(option: any) => {
                                                        form.setFieldValue('company', option ? option.value : '');
                                                    }}
                                                    onBlur={() => form.setFieldTouched('company', true)}
                                                    placeholder="Selecciona una empresa"
                                                    isClearable
                                                    styles={{
                                                        control: (base: any, state: any) => ({
                                                            ...base,
                                                            borderRadius: '20px',
                                                            borderColor: state.isFocused ? '#80bdff' : base.borderColor,
                                                            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 'none',
                                                            '&:hover': {
                                                                borderColor: state.isFocused ? '#80bdff' : '#ced4da'
                                                            }
                                                        }),
                                                        menu: (base: any) => ({
                                                            ...base,
                                                            borderRadius: '10px'
                                                        }),
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="company" component="div" className="text-danger" />
                                    </Col>
                                }

                                <Col md={12} className='mt-2'>
                                    <label htmlFor="location">Ubicación <a href="https://www.google.es/maps/" target="_blank" rel="noopener noreferrer">Google Maps</a></label>
                                    <Field name="location">
                                        {({ field }: FieldProps) => (
                                            <input
                                                {...field}
                                                type="text"
                                                placeholder="Ubicación en google maps"
                                                className="form-control rounded-pill"
                                            />
                                        )}
                                    </Field>
                                </Col>
                                <Col md={12} className='mt-2 mb-3 d-flex align-items-center'>
                                    <Row className="align-items-center ">
                                        <Col className="pr-0">
                                            <Label className="form-check-label" for="activeSwitch">Instalación activa</Label>
                                        </Col>
                                        <Col xs="auto" className='p-0'>
                                            <div className="form-check form-switch form-switch-lg">
                                                <Field name="is_active">
                                                    {({ field }: FieldProps) => (
                                                        <input
                                                            {...field}
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="activeSwitch"
                                                            checked={field.value}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <ModalFooter className='d-flex justify-content-center'>
                                <Button color="secondary" className='rounded-pill' onClick={toggle}>Cancelar</Button>
                                <Button type="submit" color="primary" className='rounded-pill'>Guardar Cambios</Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
}

export default ModalInstallation;
