import { createSlice } from "@reduxjs/toolkit";
import {
  createParameterValues,
  getParameterValuesByConnection,
  deleteParameterValues,
  updateParameterValues,
} from "./thunk";



export const initialState: any = {
  parameterValues: [],
  isFetchingParameterValues: {}
};


const parameterValuesSlice = createSlice({
  name: "parameterValues",
  initialState,
  reducers: {
    setFilteredParameterValues: (state, action) => {
      state.filteredParameterValues = action.payload;
    },
    setFetchingParameterValues: (state, action) => {
      const { connection_id, isFetching } = action.payload;
      state.isFetchingParameterValues[connection_id] = isFetching;
    },
  },

  extraReducers: (builder) => {

    builder.addCase(getParameterValuesByConnection.fulfilled, (state, action: any) => {
   
      const newParameterValues = action.payload;

      Object.keys(newParameterValues).forEach((key) => {
        if (!state.parameterValues[key]) {
          state.parameterValues[key] = newParameterValues[key];
        }
      });
    });

    builder.addCase(getParameterValuesByConnection.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    /* builder.addCase(getParameterValuesByConnection.fulfilled, (state, action: any) => {
      console.log("getParameterValues reducer", action.payload);
      const newParameterValues = action.payload;
      console.log("newParameterValues", newParameterValues);
      Object.keys(newParameterValues).forEach((key) => {
        const numericKey = Number(key); // Convertir la clave a número
        if (!state.parametersValues) {
          state.parametersValues = {}; // Asegurar que parametersValues esté inicializado
        }
        if (!state.parametersValues[numericKey]) {
          state.parametersValues[numericKey] = newParameterValues[key];
        }
      });
    });
 */


    builder.addCase(createParameterValues.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        state.parameters.push(action.payload);
      }

    });

    builder.addCase(createParameterValues.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateParameterValues.fulfilled, (state: any, action: any) => {
      state.parameters = (state.parameters || []).map((parameter: any) =>
        parameter.id.toString() === action.payload.id.toString()
          ? { ...parameter, ...action.payload }
          : parameter
      );
    });

    builder.addCase(updateParameterValues.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteParameterValues.fulfilled, (state: any, action: any) => {
      state.parameters = state.parameters.filter(
        (parameter: any) => parameter.id.toString() !== action.payload.toString()
      );
    });

    builder.addCase(deleteParameterValues.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});
export const { setFilteredParameterValues, setFetchingParameterValues } = parameterValuesSlice.actions;
export default parameterValuesSlice.reducer;