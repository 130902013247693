import React, { useEffect, useCallback, useState, useContext } from 'react';
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import { Collapse } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Import Data
import navdata from "../LayoutMenuData";
//i18n
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from 'reselect';
import ModalAddDevice from 'Components/Common/Modals/ModalAddDevice';
import DraggableZones from './DraggableZones';
import { useDispatch } from 'react-redux';
import { reorderInstallations } from 'slices/thunks';
import { SocketContext } from 'context/SocketProvider';
import { toast } from 'react-toastify';

export const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: "none",
    background: isDragging ? "rgba(255, 255, 255, 0.7)" : '',
    ...draggableStyle
});

export const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? "rgba(255, 255, 255, 0.1)" : '',
    width: '100%',

});



const VerticalLayout = (props: any) => {
    const dispatch: any = useDispatch();
    let navData = navdata().props.children;
    const [modalKey, setModalKey] = useState<string | null>(null);
    const [modal, setModal] = useState(false);
    const socket = useContext(SocketContext);

    const selectFavoritesState = (state: any) => state.Favorites;
    const selectParametersBackupState = (state: any) => state.ParametersBackup;
    const selectParameterValuesState = (state: any) => state.ParameterValues;
    const selectInstallationsState = (state: any) => state.Installations;

    const FavoritesProperties = createSelector(
        selectFavoritesState,
        (state: any) => state
    );

    const parametersBackupProperties = createSelector(
        selectParametersBackupState,
        (state: any) => state
    );

    const parameterValuesProperties = createSelector(
        selectParameterValuesState,
        (state: any) => state
    );

    const installationDataProperties = createSelector(
        selectInstallationsState,
        (state: any) => state
    );

    const { parameterValues } = useSelector(parameterValuesProperties);
    const { parametersBackup } = useSelector(parametersBackupProperties);
    const { favorites } = useSelector(FavoritesProperties);

    const { installations } = useSelector(installationDataProperties);




    useEffect(() => {

        if (socket) {
            socket.on('BackupResponse', (data) => {

                if (data.success) {
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
            });
        }
        // Asegúrate de agregar el socket como dependencia en el arreglo de dependencias del useEffect
    }, [socket]);



    /*
 layout settings
 */
    const selectLayoutState = (state: any) => state.Layout;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            leftsidbarSizeType: layout.leftsidbarSizeType,
            sidebarVisibilitytype: layout.sidebarVisibilitytype,
            layoutType: layout.layoutType
        })
    );
    // Inside your component
    const {
        leftsidbarSizeType, sidebarVisibilitytype, layoutType
    } = useSelector(selectLayoutProperties);

    //vertical and semibox resize events
    const resizeSidebarMenu = useCallback(() => {
        var windowSize = document.documentElement.clientWidth;
        const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
        var hamburgerIcon = document.querySelector(".hamburger-icon");
        if (windowSize >= 1025) {
            if (document.documentElement.getAttribute("data-layout") === "vertical") {
                document.documentElement.setAttribute("data-sidebar-size", leftsidbarSizeType);
            }
            if (document.documentElement.getAttribute("data-layout") === "semibox") {
                document.documentElement.setAttribute("data-sidebar-size", leftsidbarSizeType);
            }
            if ((sidebarVisibilitytype === "show" || layoutType === "vertical" || layoutType === "twocolumn") && document.querySelector(".hamburger-icon")) {
                if (hamburgerIcon !== null) {
                    hamburgerIcon.classList.remove("open");
                }
            } else {
                // var hamburgerIcon = document.querySelector(".hamburger-icon");
                if (hamburgerIcon !== null) {
                    hamburgerIcon.classList.add("open");
                }
            }

        } else if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove("twocolumn-panel");
            if (document.documentElement.getAttribute("data-layout") === "vertical") {
                document.documentElement.setAttribute("data-sidebar-size", "sm");
            }
            if (document.documentElement.getAttribute("data-layout") === "semibox") {
                document.documentElement.setAttribute("data-sidebar-size", "sm");
            }
            if (humberIcon) {
                humberIcon.classList.add("open");
            }
        } else if (windowSize <= 767) {
            document.body.classList.remove("vertical-sidebar-enable");
            if (document.documentElement.getAttribute("data-layout") !== "horizontal") {
                document.documentElement.setAttribute("data-sidebar-size", "lg");
            }
            if (humberIcon) {
                humberIcon.classList.add("open");
            }
        }
    }, [leftsidbarSizeType, sidebarVisibilitytype, layoutType]);

    useEffect(() => {
        window.addEventListener("resize", resizeSidebarMenu, true);
    }, [resizeSidebarMenu]);


    const showModal = (key: string) => {
        setModalKey(key);
        setModal(true)
    }

    const transformItemProperties = (item: any) => {
        // Destructure to remove 'click', extract 'label', and keep the rest
        const { click, label, subItems, childItems, ...rest } = item;

        // Rename 'label' to 'installation_name' if it exists, otherwise default to undefined
        const newItem = {
            ...rest,
            installation_name: label  // Rename label to installation_name
        };

        // Recursively update 'subItems' if they exist
        if (subItems) {
            newItem.subItems = subItems.map((subItem: any) => transformItemProperties(subItem));

            newItem.subItems = {
                ...rest,
                zone_name: label  // Rename label to installation_name
            };
        }

        // Recursively update 'childItems' if they exist within 'subItems'
        if (childItems) {
            newItem.childItems = childItems.map((childItem: any) => transformItemProperties(childItem));
        }

        return newItem;
    }

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const newInstallations: any = reorder(
            installations,
            result.source.index,
            result.destination.index
        );

        navData = reorder(
            navData,
            result.source.index,
            result.destination.index
        );

        dispatch(reorderInstallations(newInstallations));



    };



    return (
        <React.Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <ul className='p-0'>
                    {navData.filter((item: any) => item.id === 'users' || item.id === 'installations').map((item: any) => (
                        <li key={item.id} className={item.isHeader ? "menu-title" : "nav-item"}>
                            {item.isHeader ?
                                <span data-key="t-menu">{props.t(item.label)}</span> :
                                <NavLink
                                    onClick={item.click}
                                    className={({ isActive }) => isActive ? " menu-link nav-link active" : "menu-link nav-link"}
                                    to={item.link ? item.link : "/#"}
                                >
                                    <i className={item.icon}></i> <span data-key="t-apps">{props.t(item.label)}</span>
                                </NavLink>
                            }
                        </li>
                    ))}
                </ul>
                <Droppable droppableId="droppable-nav" >
                    {(provided: any, snapshot: any) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            className='p-0' >
                            {navData.filter((item: any) => item.id !== 'users' && item.id !== 'installations').map((item: any, index: any) => (
                                <Draggable key={item.id} draggableId={item.id.toString()} index={index} isDragDisabled={item.stateVariables}>
                                    {(provided: any, snapshot: any) => (
                                        <React.Fragment>
                                            <li
                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                className={item.isHeader ? "menu-title" : "nav-item"}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                {item.isHeader ?
                                                    <span data-key="t-menu">{props.t(item.label)}</span> :
                                                    item.subItems ? (
                                                        <>
                                                            <NavLink
                                                                onClick={item.click}
                                                                className={({ isActive, isPending }) =>
                                                                    isPending ? "pending nav-link menu-link" : isActive ? "nav-link menu-link active" : "nav-link menu-link"
                                                                }
                                                                to={item.link ? item.link : "/#"}
                                                                data-bs-toggle="collapse"
                                                                aria-expanded={item.stateVariables}
                                                            >
                                                                <i className={item.icon}></i>
                                                                <span data-key="t-apps">{`Inst ${index + 1 + " .- " + props.t(item.label)}`}

                                                                </span>


                                                            </NavLink>
                                                            <Collapse
                                                                className="menu-dropdown"
                                                                isOpen={item.stateVariables}
                                                                id="sidebarZones"
                                                            >
                                                                <DraggableZones initialZones={item.subItems} props={props} installationId={item.id} installationIndex={index} />
                                                            </Collapse>
                                                        </>
                                                    ) : (
                                                        <NavLink
                                                            onClick={item.click}
                                                            className={({ isActive, isPending }) =>
                                                                isPending ? "pending nav-link" : isActive ? "nav-link active" : "nav-link"
                                                            }
                                                            to={item.link ? item.link : "/#"}
                                                        >
                                                            <i className={item.icon}></i> <span data-key="t-apps">{props.t(item.label)}</span>
                                                        </NavLink>
                                                    )
                                                }
                                            </li>
                                        </React.Fragment>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>

            </DragDropContext>
        </React.Fragment>
    );
};

VerticalLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(VerticalLayout));
