//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  checkToken,
  postFakeLogin,
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";

import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer';
import { profileSuccess } from "../profile/reducer";
import { useContext } from "react";
import { SocketContext } from "context/SocketProvider";




export const loginUser = (user: any, history: any) => async (dispatch: any) => {

  
  try {
  
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      let fireBaseBackend: any = getFirebaseBackend();
      response = fireBaseBackend.loginUser(
        user.username,
        user.password
      );
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = postJwtLogin({
        username: user.username,
        password: user.password
      });

    } else if (process.env.REACT_APP_API_URL) {
      response = postFakeLogin({
        username: user.username,
        password: user.password,
      });
    }

    var data = await response;

    if (data) {

      sessionStorage.setItem("authUser", JSON.stringify({ 'token': data.token }));
      if (process.env.REACT_APP_DEFAULTAUTH === "fake") {

        var finallogin: any = JSON.stringify(data);
        finallogin = JSON.parse(finallogin)
        data = finallogin.data;
        if (finallogin.status === "success") {
          dispatch(loginSuccess(data));
          history('/dashboard')
        } else {
          dispatch(apiError(finallogin));
        }
      } else {
        dispatch(loginSuccess(data));
        history('/dashboard')
      }
    }
  } catch (error: any) {

    if (error == 'Invalid credentials, please log in again.') {
      error = 'Usuario o contraseña incorrectos';
    } else {
      error = 'Ha ocurrido un error en la petición'
    }
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch: any) => {
  try {
    sessionStorage.removeItem("authUser");

    let fireBaseBackend: any = getFirebaseBackend();
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = fireBaseBackend.logout;
      dispatch(logoutUserSuccess(response));
    } else {
      dispatch(logoutUserSuccess(true));
    }

  } catch (error: any) {
    dispatch(apiError(error));
  }
};

export const checkTokenUser = (token: string) => async (dispatch: any): Promise<boolean> => {
  try {
    const data: any = await checkToken(token);

    if (data.valid === true) {
      dispatch(loginSuccess(data));
      dispatch(profileSuccess(data));
      return true; // Retorna true si el token es válido
    } else {
      dispatch(logoutUser());
      return false; // Retorna false si el token no es válido
    }
  } catch (error: any) {
    console.error("Error checking token:", error.message || error.toString());
    dispatch(apiError(error));
    return false; // Retorna false también en caso de error
  }
};

export const socialLogin = (type: any, history: any) => async (dispatch: any) => {
  try {
    let response;

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend: any = getFirebaseBackend();
      response = fireBaseBackend.socialLoginUser(type);
    }
    //  else {
    //   response = postSocialLogin(data);
    // }

    const socialdata = await response;
    if (socialdata) {
      sessionStorage.setItem("authUser", JSON.stringify(response));
      dispatch(loginSuccess(response));
      history('/dashboard')
    }

  } catch (error: any) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error: any) {
    dispatch(apiError(error));
  }
};