import { createSlice } from "@reduxjs/toolkit";
import { createInstallation, getInstallations, deleteInstallation, updateInstallation, reorderInstallations } from "./thunk";



export const initialState: any = {
  installations: [],
};


const installationSlice = createSlice({
  name: "installation",
  initialState,
  reducers: {
    createZone: (state, action) => {
      const { installation_id } = action.payload;

      const installation = state.installations.find((inst: any) => inst.id === installation_id);


      const newZone = {
        ...action.payload,
        connections: [] 
      };


      if (installation) {
        if (installation.zones) {
          installation.zones.push(newZone);
        } else {
          installation.zones = [newZone];
        }
      }
    },

    editZone: (state, action) => {
      const { installation_id, id, zone_name, is_active } = action.payload;

      const installation = state.installations.find((inst: any) => inst.id === installation_id);

      if (installation && installation.zones) {
        const zoneIndex = installation.zones.findIndex((zone: any) => zone.id === id);

        if (zoneIndex !== -1) {
          installation.zones[zoneIndex] = {
            ...installation.zones[zoneIndex],
            id: id, // Aunque normalmente el ID no debería cambiar, se incluye aquí por completitud
            zone_name: zone_name,
            is_active: is_active
          };

        }
      }
    },

    deleteZone: (state, action) => {
      state.installations.forEach((installation: any) => {
        installation.zones = installation.zones.filter(
          (zone: any) => zone.id !== action.payload
        );
      });
    },

    reorderInstallationZones: (state, action) => {
      // Extraer newZones del action.payload
      const newZones = action.payload;

      // Asumimos que todas las zonas pueden tener el mismo installation_id, pero revisamos la primera para estar seguro
      const installationId = newZones[0].installation_id;

      // Encontrar la instalación correspondiente
      const installation = state.installations.find((inst: any) => inst.id === installationId);



      if (installation) {
        // Actualizar las zonas de la instalación encontrada
        installation.zones = newZones.map((zone: any) => (

          {

            id: zone.id,
            zone_name: zone.zone_name,
            is_active: zone.is_active,
            connections: zone.connections
            // Asumimos que estás pasando otros atributos necesarios aquí
          }));
      }
    },

    reorderConnectionsInNavbar: (state, action) => {
      // Extraer newZones del action.payload
      const { installationId, zoneId, connections } = action.payload;
      const newConnections = action.payload;

      // Encontrar la instalación correspondiente
      const installation = state.installations.find((inst: any) => inst.id === installationId);

      // Encontrar la zona
      const zone = installation.zones.find((zone: any) => zone.id == zoneId);

      if (installation) {
        // Actualizar las zonas de la instalación encontrada
        zone.connections = newConnections.connections.map((connection: any) => (

          {
            id: connection.id,
            name: connection.name,
            img: connection.img,
            is_active: connection.is_active
          }));
      }
    },

    updateDevice: (state, action) => {
      const { installation_id, name, zone_id, id, img, ip, is_active, device_id, b_id, sn, supervisor_id } = action.payload;

      const installation = state.installations.find((inst: any) => inst.id === installation_id);

      if (installation) {
        const zone = installation.zones.find((zone: any) => zone.id === Number(zone_id));

        if (zone) {
          const existingDeviceIndex = zone.connections.findIndex((connection: any) => connection.id === id);


          if (existingDeviceIndex !== -1) {
            // If the device exists, update only specified properties
            zone.connections[existingDeviceIndex] = {
              ...zone.connections[existingDeviceIndex],
              ...(name !== undefined && { name }),
              ...(zone_id !== undefined && { zone_id }),
              ...(img !== undefined && { img }),
              ...(ip !== undefined && { ip }),
              ...(device_id !== undefined && { device_id }),
              ...(is_active !== undefined && { is_active }),
              ...(b_id !== undefined && { b_id }),
              ...(sn !== undefined && { sn }),
              ...(supervisor_id !== undefined && { supervisor_id })
            };
          } else {
            // If the device does not exist, add it
            const newDevice = {
              id: id,
              name: name,
              zone_id: zone_id,
              img: img,
              ip: ip,
              device_id: device_id,
              is_active: is_active,
              b_id: b_id,
              sn: sn,
              installation_id: installation_id,
              supervisor_id: supervisor_id
            };

            if (zone.connections) {
              zone.connections.push(newDevice);
            } else {
              zone.connections = [newDevice];
            }
          }
        }
      }
    },

    deleteDevice: (state, action) => {
      const { installation_id, zone_id, id } = action.payload;

      const installation = state.installations.find((inst: any) => inst.id === installation_id);

      if (installation) {
        const zone = installation.zones.find((zone: any) => zone.id === Number(zone_id));

        if (zone) {

          zone.connections = zone.connections.filter((connection: any) => connection.id !== id);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInstallations.fulfilled, (state: any, action: any) => {
      state.installations = action.payload;
    });
    builder.addCase(getInstallations.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(createInstallation.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        state.installations.push(action.payload);
      }

    });

    builder.addCase(createInstallation.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateInstallation.fulfilled, (state: any, action: any) => {
      state.installations = (state.installations || []).map((installation: any) =>
        installation.id.toString() === action.payload.id.toString()
          ? { ...installation, ...action.payload }
          : installation
      );
    });

    builder.addCase(updateInstallation.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


    builder.addCase(reorderInstallations.fulfilled, (state: any, action: any) => {
      state.installations = action.payload;
    });

    builder.addCase(reorderInstallations.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteInstallation.fulfilled, (state: any, action: any) => {
      state.installations = state.installations.filter(
        (installation: any) => installation.id.toString() !== action.payload.toString()
      );
    });

    builder.addCase(deleteInstallation.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});
export const { deleteZone, createZone, editZone, reorderInstallationZones, reorderConnectionsInNavbar, updateDevice, deleteDevice } = installationSlice.actions;
export default installationSlice.reducer;