import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getDevices as getDevicesApi,
  getDevice as getDeviceApi,
  createDevice as createDeviceApi,
  updateDevice as updateDeviceApi,
  deleteDevice as deleteDeviceApi,
} from "../../helpers/fakebackend_helper";

export const getDevices = createAsyncThunk("device/getDevices", async () => {
  try {
    const response = await getDevicesApi();

    return response;
  } catch (error) {
    return error;
  }
});

export const getDevice = createAsyncThunk("device/getDevice", async (deviceId: number) => {
  try {
    const response = await getDeviceApi(deviceId);
    return response;
  } catch (error) {
    return error;
  }
});

export const createDevice = createAsyncThunk("device/createDevice", async (device: any) => {
  try {

    const response: any = await createDeviceApi(device);
    if (response.id) {
      device.id = response.id;
      device.supervision_name = response.supervision_name;
    }
    return device;
  } catch (error) {
    return { 'error': error };
  }
});

export const updateDevice = createAsyncThunk("device/updateDevice", async (device: any) => {
  try {
    const response = await updateDeviceApi(device);
    return device;
  } catch (error) {
    return error;
  }
});


export const deleteDevice = createAsyncThunk("device/deleteDevice", async (device: any) => {
  try {

    const response = await deleteDeviceApi(device);
    return device;
  } catch (error) {
    return error;
  }
});
