import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

interface BreadCrumbProps {
    title?: string;
    subtitle?: string;
    subsubtitle?: string;
    subsubsubtitle?: string;
    pageTitle?: string;
    pageTitleUrl?: string;
    subtitleUrl?: string;
    subsubtitleUrl?: string;
    subsubsubtitleUrl?: string;
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({
    title,
    pageTitle,
    subtitle,
    subsubtitle,
    subsubsubtitle,
    pageTitleUrl,
    subtitleUrl,
    subsubtitleUrl,
    subsubsubtitleUrl
}) => {
    const textStyle = { fontSize: '1rem', fontWeight: 'bold' };
    const linkStyle = { textDecoration: 'none', color: 'inherit', fontSize: '1rem', fontWeight: 'bold' };

    return (
        <React.Fragment>
            <Row className="sticky-top bg-white shadow-sm  px-4" style={{ top: '94px', zIndex: 50 } }>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between mb-0">
                        {title && <h4 className="mb-sm-0" style={textStyle}>{title}</h4>}
                        <div className="page-title-right">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb m-0" style={{ display: 'flex', alignItems: 'center' }}>
                                    {pageTitle && (
                                        <>
                                            <li className="breadcrumb-item">
                                                {pageTitleUrl ? (
                                                    <Link to={pageTitleUrl} style={linkStyle} className="breadcrumb-link">
                                                        {pageTitle}
                                                    </Link>
                                                ) : (
                                                    <span style={textStyle}>{pageTitle}</span>
                                                )}
                                            </li>
                                            {(subtitle || subsubtitle || subsubsubtitle) && (
                                                <span style={{ margin: '0 0.5rem', color: '#6c757d' }}>&gt;</span>
                                            )}
                                        </>
                                    )}
                                    {subtitle && (
                                        <>
                                            <li className="breadcrumb-item">
                                                {subtitleUrl ? (
                                                    <Link to={subtitleUrl} style={linkStyle} className="breadcrumb-link">
                                                        {subtitle}
                                                    </Link>
                                                ) : (
                                                    <span style={textStyle}>{subtitle}</span>
                                                )}
                                            </li>
                                            {(subsubtitle || subsubsubtitle) && (
                                                <span style={{ margin: '0 0.5rem', color: '#6c757d' }}>&gt;</span>
                                            )}
                                        </>
                                    )}
                                    {subsubtitle && (
                                        <>
                                            <li className="breadcrumb-item">
                                                {subsubtitleUrl ? (
                                                    <Link to={subsubtitleUrl} style={linkStyle} className="breadcrumb-link">
                                                        {subsubtitle}
                                                    </Link>
                                                ) : (
                                                    <span style={textStyle}>{subsubtitle}</span>
                                                )}
                                            </li>
                                            {subsubsubtitle && (
                                                <span style={{ margin: '0 0.5rem', color: '#6c757d' }}>&gt;</span>
                                            )}
                                        </>
                                    )}
                                    {subsubsubtitle && (
                                        <li className="breadcrumb-item active">
                                            {subsubsubtitleUrl ? (
                                                <Link to={subsubsubtitleUrl} style={linkStyle} className="breadcrumb-link">
                                                    {subsubsubtitle}
                                                </Link>
                                            ) : (
                                                <span style={textStyle}>{subsubsubtitle}</span>
                                            )}
                                        </li>
                                    )}
                                </ol>
                            </nav>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;
