import { createAsyncThunk } from "@reduxjs/toolkit";

// Importa el método que realiza la llamada API
import {
  getFaultHistoriesNotAcknowledged as getFaultHistoriesNotAcknowledgedApi,
  updateFaultHistory as updateFaultHistoryApi,
  getFaultHistoriesByZone as getFaultHistoriesByZoneApi

} from "../../helpers/fakebackend_helper";

// Define el createAsyncThunk para getFaultHistories
export const getFaultHistoriesNotAcknowledged = createAsyncThunk("faultHistory/getFaultHistoriesNotAcknowledged", async () => {
  
  try {
    const response = await getFaultHistoriesNotAcknowledgedApi();
    
    return response;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});

export const getFaultHistoriesByZone = createAsyncThunk("faultHistory/getFaultHistoriesByZone", async () => {
  try {

    const response = await getFaultHistoriesByZoneApi();
    return response;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});

export const updateFaultHistory = createAsyncThunk("faultHistory/updateFaultHistory", async (faultHistoryData: any) => {
  try {
    const response = await updateFaultHistoryApi(faultHistoryData);
    return faultHistoryData;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});
