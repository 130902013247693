import { createSlice } from "@reduxjs/toolkit";
import { getFaultHistoriesNotAcknowledged, updateFaultHistory, getFaultHistoriesByZone } from "./thunk";

export const initialState: any = {
  faultHistories: [],
  faultHistoryNotAcknowledged: [],
  error: null,
  loading: false,
};

const faultHistorySlice = createSlice({
  name: "faultHistory",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getFaultHistoriesNotAcknowledged.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getFaultHistoriesNotAcknowledged.fulfilled, (state, action) => {
      state.loading = false;
      state.faultHistoryNotAcknowledged = action.payload;
    });
    builder.addCase(getFaultHistoriesNotAcknowledged.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to fetch fault histories';
    });

    builder.addCase(getFaultHistoriesByZone.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getFaultHistoriesByZone.fulfilled, (state, action) => {
      state.loading = false;
      state.faultHistories = action.payload;
    });
    builder.addCase(getFaultHistoriesByZone.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to fetch alarm histories by zone';
    });

    builder.addCase(updateFaultHistory.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload && action.payload.is_acknowledged) {
        state.faultHistoryNotAcknowledged = state.faultHistoryNotAcknowledged.filter((faultHistory: any) => faultHistory.id !== action.payload.id);
      }
    });
  },
});

export default faultHistorySlice.reducer;
