//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/api/login";
export const POST_FAKE_JWT_LOGIN = "/api/login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const CHECK_TOKEN_USER = "/api/checkToken";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

// Calendar
export const GET_EVENTS = "/events";
export const GET_CATEGORIES = "/categories";
export const GET_UPCOMMINGEVENT = "/upcommingevents";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";

// Chat
export const GET_DIRECT_CONTACT = "/chat";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "add/message";
export const DELETE_MESSAGE = "delete/message";

//Mailbox
export const GET_MAIL_DETAILS = "/mail";
export const DELETE_MAIL = "/delete/mail";
export const UNREAD_MAIL = "/unread/mail";
export const STARED_MAIL = "/stared/mail";
export const LABEL_MAIL = "/label/mail";
export const TRASH_MAIL = "/trash/mail";

// Ecommerce
// Product
export const GET_PRODUCTS = "/product";
export const DELETE_PRODUCT = "/delete/product";
export const ADD_NEW_PRODUCT = "/add/product";
export const UPDATE_PRODUCT = "/update/product";

// Orders
export const GET_ORDERS = "/order";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

// Customers
export const GET_CUSTOMERS = "/apps/customer";
export const ADD_NEW_CUSTOMER = "/apps/customer";
export const UPDATE_CUSTOMER = "/apps/customer";
export const DELETE_CUSTOMER = "/apps/customer";

// Sellers
export const GET_SELLERS = "/sellers";

// Project list
export const GET_PROJECT_LIST = "/project/list";

// Task
export const GET_TASK_LIST = "/apps/task";
export const ADD_NEW_TASK = "/apps/task";
export const UPDATE_TASK = "/apps/task";
export const DELETE_TASK = "/apps/task";

// kanban
export const GET_TASKS = "/apps/tasks";
export const ADD_TASKS = "/add/tasks";
export const UPDATE_TASKS = "/update/tasks";
export const DELETE_TASKS = "/delete/tasks";

// CRM
// Conatct
export const GET_CONTACTS = "/apps/contact";
export const ADD_NEW_CONTACT = "/apps/contact";
export const UPDATE_CONTACT = "/apps/contact";
export const DELETE_CONTACT = "/apps/contact";

// Lead
export const GET_LEADS = "/apps/lead";
export const ADD_NEW_LEAD = "/apps/lead";
export const UPDATE_LEAD = "/apps/lead";
export const DELETE_LEAD = "/apps/lead";

// Deals
export const GET_DEALS = "/deals";

// Crypto
export const GET_TRANSACTION_LIST = "/transaction-list";
export const GET_ORDRER_LIST = "/order-list";

// Invoice
export const GET_INVOICES = "/apps/invoice";
export const ADD_NEW_INVOICE = "/apps/invoice";
export const UPDATE_INVOICE = "/apps/invoice";
export const DELETE_INVOICE = "/apps/invoice";

// TicketsList
export const GET_TICKETS_LIST = "/ticket";
export const ADD_NEW_TICKET = "/add/ticket";
export const UPDATE_TICKET = "/update/ticket";
export const DELETE_TICKET = "/delete/ticket";

// Dashboard Analytics

// Sessions by Countries
export const GET_ALL_DATA = "/all-data";
export const GET_HALFYEARLY_DATA = "/halfyearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

// Audiences Metrics
export const GET_ALLAUDIENCESMETRICS_DATA = "/allAudiencesMetrics-data";
export const GET_MONTHLYAUDIENCESMETRICS_DATA = "/monthlyAudiencesMetrics-data";
export const GET_HALFYEARLYAUDIENCESMETRICS_DATA = "/halfyearlyAudiencesMetrics-data";
export const GET_YEARLYAUDIENCESMETRICS_DATA = "/yearlyAudiencesMetrics-data";

// Users by Device
export const GET_TODAYDEVICE_DATA = "/todayDevice-data";
export const GET_LASTWEEKDEVICE_DATA = "/lastWeekDevice-data";
export const GET_LASTMONTHDEVICE_DATA = "/lastMonthDevice-data";
export const GET_CURRENTYEARDEVICE_DATA = "/currentYearDevice-data";

// Audiences Sessions by Country
export const GET_TODAYSESSION_DATA = "/todaySession-data";
export const GET_LASTWEEKSESSION_DATA = "/lastWeekSession-data";
export const GET_LASTMONTHSESSION_DATA = "/lastMonthSession-data";
export const GET_CURRENTYEARSESSION_DATA = "/currentYearSession-data";

// Dashboard CRM

// Balance Overview
export const GET_TODAYBALANCE_DATA = "/todayBalance-data";
export const GET_LASTWEEKBALANCE_DATA = "/lastWeekBalance-data";
export const GET_LASTMONTHBALANCE_DATA = "/lastMonthBalance-data";
export const GET_CURRENTYEARBALANCE_DATA = "/currentYearBalance-data";

// Deal type
export const GET_TODAYDEAL_DATA = "/todayDeal-data";
export const GET_WEEKLYDEAL_DATA = "/weeklyDeal-data";
export const GET_MONTHLYDEAL_DATA = "/monthlyDeal-data";
export const GET_YEARLYDEAL_DATA = "/yearlyDeal-data";

// Sales Forecast

export const GET_OCTSALES_DATA = "/octSales-data";
export const GET_NOVSALES_DATA = "/novSales-data";
export const GET_DECSALES_DATA = "/decSales-data";
export const GET_JANSALES_DATA = "/janSales-data";

// Dashboard Ecommerce
// Revenue
export const GET_ALLREVENUE_DATA = "/allRevenue-data";
export const GET_MONTHREVENUE_DATA = "/monthRevenue-data";
export const GET_HALFYEARREVENUE_DATA = "/halfYearRevenue-data";
export const GET_YEARREVENUE_DATA = "/yearRevenue-data";

// Dashboard Crypto
// Portfolio
export const GET_BTCPORTFOLIO_DATA = "/btcPortfolio-data";
export const GET_USDPORTFOLIO_DATA = "/usdPortfolio-data";
export const GET_EUROPORTFOLIO_DATA = "/euroPortfolio-data";

// Market Graph
export const GET_ALLMARKETDATA_DATA = "/allMarket-data";
export const GET_YEARMARKET_DATA = "/yearMarket-data";
export const GET_MONTHMARKET_DATA = "/monthMarket-data";
export const GET_WEEKMARKET_DATA = "/weekMarket-data";
export const GET_HOURMARKET_DATA = "/hourMarket-data";

// Dashboard Crypto
// Project Overview
export const GET_ALLPROJECT_DATA = "/allProject-data";
export const GET_MONTHPROJECT_DATA = "/monthProject-data";
export const GET_HALFYEARPROJECT_DATA = "/halfYearProject-data";
export const GET_YEARPROJECT_DATA = "/yearProject-data";

// Project Status
export const GET_ALLPROJECTSTATUS_DATA = "/allProjectStatus-data";
export const GET_WEEKPROJECTSTATUS_DATA = "/weekProjectStatus-data";
export const GET_MONTHPROJECTSTATUS_DATA = "/monthProjectStatus-data";
export const GET_QUARTERPROJECTSTATUS_DATA = "/quarterProjectStatus-data";

// Dashboard NFT
// Marketplace
export const GET_ALLMARKETPLACE_DATA = "/allMarketplace-data";
export const GET_MONTHMARKETPLACE_DATA = "/monthMarketplace-data";
export const GET_HALFYEARMARKETPLACE_DATA = "/halfYearMarketplace-data";
export const GET_YEARMARKETPLACE_DATA = "/yearMarketplace-data";

// Project
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

// Pages > Team
export const GET_TEAMDATA = "/teamData";
export const DELETE_TEAMDATA = "/delete/teamData";
export const ADD_NEW_TEAMDATA = "/add/teamData";
export const UPDATE_TEAMDATA = "/update/teamData";

// File Manager
// Folder
export const GET_FOLDERS = "/folder";
export const DELETE_FOLDER = "/delete/folder";
export const ADD_NEW_FOLDER = "/add/folder";
export const UPDATE_FOLDER = "/update/folder";

// File
export const GET_FILES = "/file";
export const DELETE_FILE = "/delete/file";
export const ADD_NEW_FILE = "/add/file";
export const UPDATE_FILE = "/update/file";

// To do
export const GET_TODOS = "/todo";
export const DELETE_TODO = "/delete/todo";
export const ADD_NEW_TODO = "/add/todo";
export const UPDATE_TODO = "/update/todo";

// To do Project
export const GET_PROJECTS = "/projects";
export const ADD_NEW_TODO_PROJECT = "/add/project";

//JOB APPLICATION
export const GET_APPLICATION_LIST = "/application-list";
export const ADD_NEW_APPLICATION_LIST = "/add/application-list";
export const UPDATE_APPLICATION_LIST = "/update/application-list";
export const DELETE_APPLICATION_LIST = "/delete/application-list";

//JOB APPLICATION
export const GET_API_KEY = "/api-key";

// CANDIDATE LIST
export const GET_CANDIDATE = "/candidates";
export const ADD_NEW_CANDIDATE = "add/candidates";
export const UPDATE_CANDIDATE = "update/candidates";
export const DELETE_CANDIDATE = "delete/candidates"

export const GET_CANDIDATE_GRID = "/category-grid";
export const ADD_CANDIDATE_GRID = "/add/category-grid";

export const GET_CATEGORY_LIST = "/category-list";
export const ADD_CATEGORY_LIST = "/add/category-list";


// Installations
export const GET_INSTALLATIONS = "/api/getInstallations";
export const ADD_NEW_INSTALLATION = "/api/createInstallation"
export const UPDATE_INSTALLATION = 'api/updateInstallation';
export const REORDER_INSTALLATIONS = 'api/reorderInstallations';
export const DELETE_INSTALLATION = "/api/deleteInstallation";

//InstallationZones
export const GET_INSTALLATIONZONES = "/api/getInstallationZones";
export const GET_INSTALLATIONZONES_BY_USER = "/api/getInstallationZonesByUser";
export const ADD_NEW_INSTALLATION_ZONE = "/api/createInstallationZone";
export const REORDER_INSTALLATION_ZONES = 'api/reorderInstallationZones';
export const UPDATE_INSTALLATION_ZONE = 'api/updateInstallationZone';
export const UPDATE_USER_INSTALLATION_ZONE = 'api/updateUserInstallationZone';
export const DELETE_INSTALLATION_ZONE = "/api/deleteInstallationZone";


//Status
export const GET_STATUS = "/api/getStatus";
export const ADD_NEW_STATUS = "/api/createStatus";
export const UPDATE_STATUS = 'api/updateStatus';
export const DELETE_STATUS = "/api/deleteStatus";


//Status
export const GET_STATUS_ZONE = "/api/getStatusZone";
export const ADD_NEW_STATUS_ZONE = "/api/createStatusZone";
export const UPDATE_STATUS_ZONE = 'api/updateStatusZone';
export const DELETE_STATUS_ZONE = "/api/deleteStatusZone";
export const REORDERED_STATUS_ZONE = "/api/reorderStatusZones";


// Users
export const GET_USERS = "/api/getUsers";
export const GET_SUPERVISORS_BY_ZONE = "/api/getSupervisorsByZone";
export const ADD_NEW_USER = "/api/createUser"
export const UPDATE_USER = '/api/updateUser';
export const DELETE_USER = "/api/deleteUser";
export const RESET_USER_PASSWORD = "/api/reset_password"
export const CHANGE_USER_PASSWORD = "/api/change_password"

// Devices
export const GET_DEVICES = "/api/getDevices";
export const GET_DEVICE = "/api/getDevice";
export const ADD_NEW_DEVICE = "/api/createDevice"
export const UPDATE_DEVICE = '/api/updateDevice';
export const DELETE_DEVICE = "/api/deleteDevice";

// Parameters
export const GET_PARAMETERS = "/api/getParameters";
export const GET_PARAMETER = "/api/getParameter";
export const GET_VALUE_MODBUS_BY_PARAMETER = "/api/getValueModbusByParameter";
export const ADD_NEW_PARAMETER = "/api/createParameter"
export const UPDATE_PARAMETER = '/api/updateParameter';
export const DELETE_PARAMETER = "/api/deleteParameter";

// ParameterValues
export const GET_PARAMETER_VALUES = "/api/getParametersValues";
export const GET_PARAMETERS_VALUES_BY_CONNECTION = "/api/getParameterValuesByConnection";
export const ADD_NEW_PARAMETER_VALUES = "/api/createParameter"
export const UPDATE_PARAMETER_VALUES = '/api/updateParameter';
export const DELETE_PARAMETER_VALUES = "/api/deleteParameter";

// Favorites
export const GET_FAVORITES_BY_CONNECTION_ID = "/api/getFavoritesByConnectionId";
export const ADD_NEW_FAVORITE = "/api/createFavorite"
export const UPDATE_FAVORITE = '/api/updateFavorite';
export const DELETE_FAVORITE = "/api/deleteFavorite";
export const IS_ANALOGIC_USED = "/api/isAnalogicUsed";
export const REORDER_FAVORITES = "/api/reorderFavorites";
export const GET_DASHBOARD_INFO = "/api/getDashboardInfo";
export const GET_LINKED_FAVORITES = "/api/getLinkedFavorites";
export const ADD_LINKED_PARAMETER = "/api/createLinkedParameter"
export const DELETE_LINKED_PARAMETER = "/api/deleteLinkedParameter"
export const GET_LINKED_VARIABLES_BY_CONNECTION = "/api/getLinkedVariablesByConnection";

// ParameterBackups
export const GET_PARAMETER_BACKUPS = "/api/getParametersBackup";
export const GET_PARAMETER_BACKUP = "/api/getParametersBackup";
export const GET_BACKUP_PARAMETERS_BY_CONNECTION = "/api/backupParametersByConnection"
export const ADD_NEW_PARAMETER_BACKUP = "/api/createParametersBackup";
export const UPDATE_PARAMETER_BACKUP = '/api/updateParametersBackup';
export const DELETE_PARAMETER_BACKUP = "/api/deleteParameterBackup";

// Connections
export const GET_CONNECTIONS = "/api/getConnections";
export const GET_UNCONFIGURED_ACTIVE_CONNECTIONS = "/api/getUnconfiguredActiveConnections";
export const ADD_NEW_CONNECTION = "/api/createConnection"
export const REORDER_CONNECTIONS = '/api/reorderConnections';
export const UPDATE_CONNECTION = '/api/updateConnection';
export const DELETE_CONNECTION = "/api/deleteConnection";

// FaultHistories

export const GET_FAULT_HISTORIES_IS_NOT_ACKNOWLEDGED = "/api/getFaultHistoriesNotAcknowledged";
export const UPDATE_FAULT_HISTORY = "/api/updateFaultHistory";
export const GET_FAULT_HISTORIES_BY_ZONE = "/api/getFaultHistoriesByZone";

// AlarmHistories
export const GET_ALARM_HISTORIES_IS_NOT_ACKNOWLEDGED = "/api/getAlarmHistoriesNotAcknowledged";
export const UPDATE_ALARM_HISTORY = "/api/updateAlarmHistory";
export const GET_ALARM_HISTORIES_BY_ZONE = "/api/getAlarmHistoriesByZone";

// zoneEmails
export const GET_ZONE_EMAIL_BY_ZONE = "/api/getZoneEmailByUser";
export const ADD_ZONE_EMAIL_BY_ZONE = "/api/createZoneEmailByUser";
export const DELETE_ZONE_EMAIL_BY_ZONE = "/api/deleteZoneEmailByUser";


//StartupRegistration
export const GET_STARTUP_REGISTRATION_BY_ZONE = "/api/getStartupRegistrationByZoneId";



// CalculatedParameters
export const GET_CALCULATED_PARAMETERS_BY_ZONE = "/api/getCalculatedParametersByZone";
export const ADD_NEW_CALCULATED_PARAMETER = "/api/createCalculatedParameter";
export const UPDATE_CALCULATED_PARAMETER = "/api/updateCalculatedParameter";
export const DELETE_CALCULATED_PARAMETER = "/api/deleteCalculatedParameter";

// Roles
export const GET_ROLES = "/api/getRoles";
export const ADD_NEW_ROLE = "/api/createRole"
export const UPDATE_ROLE = 'api/updateRole';
export const DELETE_ROLE = "/api/deleteRole";

// Companies
export const GET_COMPANIES = "/api/getCompanies";
export const ADD_NEW_COMPANY = "/api/createCompany";
export const UPDATE_COMPANY = "/api/updateCompany";
export const DELETE_COMPANY = "/api/deleteCompany";


// Registers
export const GET_DAILY_AVERAGE_REGISTERS_BETWEEN_DATES = "/api/getDailyAverageRegistersBetweenDates";
export const GET_REGISTERS_BETWEEN_DATE = "/api/getRegistersBetweenDates";
export const REFRESH_FAVORITES_RECORDS = "/api/refreshFavoritesRecords";


//Alarms and Faults
export const GET_ALARMS_AND_FAULTS_BY_CONNECTION = "/api/getCustomFaultsByConnectionId";
export const CREATE_CUSTOM_ALARM_OR_FAULT = "/api/createCustomAlarmOrFault";
export const UPDATE_CUSTOM_ALARM_OR_FAULT = "/api/updateCustomAlarmOrFault";
export const DELETE_CUSTOM_ALARM_OR_FAULT = "/api/deleteCustomAlarmOrFault";

//Faults and Alarms
export const GET_FAULTS_BY_CONNECTION_ID = "/api/getFaultsByConnectionID";

//Main Variables
export const REORDER_MAIN_VARIABLES = "/api/reorderMainVariables";

//CHECK ANALOGIC IN FAVORITES
export const CHECK_ANALOGIC_IN_FAVORITES = "/api/checkAnalogicInFavorites";

//INSTALLATION CALCULATEDPARAMETER
export const GET_INSTALLATION_CALCULATED_PARAMETERS = "/api/getInstallationCalculatedParameters";
export const ADD_NEW_INSTALLATION_CALCULATED_PARAMETER = "/api/createInstallationCalculatedParameter";
export const UPDATE_INSTALLATION_CALCULATED_PARAMETER = "/api/updateInstallationCalculatedParameter";
export const DELETE_INSTALLATION_CALCULATED_PARAMETER = "/api/deleteInstallationCalculatedParameter";

// INSTALLATION REGISTERS
export const GET_INSTALLATION_REGISTERS_BETWEEN_DATE = "/api/getInstallationRegistersBetweenDates";
export const GET_DAILY_AVERAGE_INSTALLATION_REGISTERS_BETWEEN_DATES = "/api/getDailyAverageInstallationRegistersBetweenDates";

// SYNOPTIC IMAGE CONFIG
export const GET_SYNOPTIC_IMAGE_CONFIG_BY_ZONE = "/api/getSynopticImageConfigByZone";
export const ADD_NEW_SYNOPTIC_IMAGE_CONFIG = "/api/createSynopticImageConfig";
export const UPDATE_SYNOPTIC_IMAGE_CONFIG = "/api/updateSynopticImageConfig";
export const DELETE_SYNOPTIC_IMAGE_CONFIG = "/api/deleteSynopticImageConfig";


// SECTION DATA CONFIG
export const GET_SECTION_DATA_CONFIG_BY_ZONE = "/api/getSectionDataConfigByZone";
export const ADD_SECTION_DATA_CONFIG = "/api/createSectionDataConfig";
export const UPDATE_SECTION_DATA_CONFIG = "/api/updateSectionDataConfig";
export const DELETE_SECTION_DATA_CONFIG = "/api/deleteSectionDataConfig";

