import { createSlice } from "@reduxjs/toolkit";
import { getAlarmHistoriesNotAcknowledged, updateAlarmHistory, getAlarmHistoriesByZone  } from "./thunk";

export const initialState: any = {
  alarmHistories: [],
  alarmHistoriesNotAcknowledged: [],
  error: null,
  loading: false,
};

const alarmHistorySlice = createSlice({
  name: "alarmHistory",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAlarmHistoriesNotAcknowledged.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAlarmHistoriesNotAcknowledged.fulfilled, (state, action) => {
      state.loading = false;
      state.alarmHistoriesNotAcknowledged = action.payload;
    });
    builder.addCase(getAlarmHistoriesNotAcknowledged.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to fetch alarm histories';
    });

    builder.addCase(getAlarmHistoriesByZone.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAlarmHistoriesByZone.fulfilled, (state, action) => {
      state.loading = false;
      state.alarmHistories = action.payload;
    });
    builder.addCase(getAlarmHistoriesByZone.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to fetch alarm histories by zone';
    });

    builder.addCase(updateAlarmHistory.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload && action.payload.is_acknowledged) {
        state.alarmHistoriesNotAcknowledged = state.alarmHistoriesNotAcknowledged.filter((alarmHistory: any) => alarmHistory.id !== action.payload.id);
      }
    });
  },
});

export default alarmHistorySlice.reducer;
