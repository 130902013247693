import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Col, Row, Spinner } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getFaultsByConnectionID } from 'slices/faults/thunk';
import { createCustomAlarmOrFault, updateCustomAlarmOrFault } from 'slices/AlarmsAndFaults/thunk';
import { useParams } from 'react-router-dom';
import ModalHelp from 'Components/Common/Modals/ModalHelp';

interface ModalAlarmsAndFaultsProps {
    isOpen: boolean;
    toggle: () => void;
    isEditing: boolean;
    alarmOrFaultData?: any;
    onUserCreatedorEdited: (isUserCreatedorEdited: boolean, isEditing: boolean) => void;
}

interface UserFormData {
    id: number;
    name: string;
    type: string;
    faultList: number;
    custom_correction_guide: string;
    is_active: boolean;
    notification_interval_minutes?: number | null; // Puede ser número o null
}

interface OptionType {
    label: string;
    value: string;
}

const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderRadius: '20px',
        borderColor: state.isFocused ? '#80bdff' : provided.borderColor,
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 'none',
        '&:hover': {
            borderColor: state.isFocused ? '#80bdff' : '#ced4da'
        }
    }),
    menu: (provided: any) => ({
        ...provided,
        borderRadius: '10px'
    }),
    singleValue: (provided: any) => ({
        ...provided,
        borderRadius: '10px'
    })
};

const ModalAlarmsAndFaults: React.FC<ModalAlarmsAndFaultsProps> = ({ isOpen, toggle, isEditing, alarmOrFaultData, onUserCreatedorEdited }) => {
    const dispatch: any = useDispatch();
    const { id } = useParams();
    const [filteredFaultsList, setFilteredFaultsList] = useState([]);
    const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
    const toggleHelpModal = () => setIsHelpModalOpen(!isHelpModalOpen);
    const [currentMessageHelp, setCurrentMessageHelp] = useState<string>('');
    const [currentTitleHelp, setCurrentTitleHelp] = useState<string>('');

    const selectFaultsState = (state: any) => state.Faults;

    const FaultHistoryProperties = createSelector(
        selectFaultsState,
        (state) => state
    );

    const { faultsList, loading } = useSelector(FaultHistoryProperties);

    useEffect(() => {
        if (isOpen) {
            dispatch(getFaultsByConnectionID(Number(id)));
        }
    }, [isOpen, dispatch, id]);

    useEffect(() => {
        if (alarmOrFaultData?.fault_type) {
            setFilteredFaultsList(faultsList.filter((fault: any) => fault.type === alarmOrFaultData.fault_type));
        }
    }, [faultsList, alarmOrFaultData]);

    const initialValues: UserFormData = isEditing && alarmOrFaultData ? {
        id: alarmOrFaultData.id,
        name: alarmOrFaultData.custom_fault_name || '',
        type: alarmOrFaultData.fault_type || '',
        faultList: alarmOrFaultData.fault_id || 0,
        custom_correction_guide: alarmOrFaultData.custom_correction_guide || '',
        is_active: alarmOrFaultData.is_active,
        notification_interval_minutes: alarmOrFaultData.notification_interval_minutes ?? null,
    } : {
        id: 0,
        name: '',
        type: '',
        faultList: 0,
        custom_correction_guide: '',
        is_active: true,
        notification_interval_minutes: null,
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('El nombre es obligatorio'),
        type: Yup.string().required('El tipo es obligatorio'),
        faultList: Yup.number().required('Tiene que seleccionar un fallo o alarma'),
        custom_correction_guide: Yup.string(),
        is_active: Yup.boolean(),
        notification_interval_minutes: Yup.number().nullable().min(1, 'El intervalo de notificación debe ser al menos 1 minuto').transform(value => (isNaN(value) ? null : value)),
    });

    const typeOptions: OptionType[] = [
        { label: 'Fallo', value: 'Fallo' },
        { label: 'Alarma', value: 'Alarma' }
    ];

    const handleAlarmOrFaultFormSubmit = async (values: any) => {
        const alarmOrFault = {
            id: values.id,
            custom_fault_name: values.name,
            fault_type: values.type,
            fault_id: values.faultList,
            fault_name: faultsList.find((fault: any) => fault.id === values.faultList).fault_name,
            custom_correction_guide: values.custom_correction_guide,
            is_active: values.is_active,
            connection_id: Number(id),
            notification_interval_minutes: values.notification_interval_minutes || null,
        };

        let response;
        if (isEditing) {
            response = await dispatch(updateCustomAlarmOrFault(alarmOrFault)).unwrap();
        } else {
            response = await dispatch(createCustomAlarmOrFault(alarmOrFault)).unwrap();
        }

        if (!response.error) {
            onUserCreatedorEdited(true, isEditing);
        } else {
            onUserCreatedorEdited(false, isEditing);
        }
    };

    const handleTypeChange = (option: any, setFieldValue: any) => {

        setFieldValue('type', option ? option.value : '');

        const newFilteredFaults = faultsList.filter((fault: any) => {
            return fault.type === option.value || fault.type == 'Alarma_Fallo'
        }


        );

        setFilteredFaultsList(newFilteredFaults);
        setFieldValue('faultList', 0);
    };


    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop="static" keyboard={false}>

            <ModalHeader>
                <Button
                    onClick={() => {
                        setCurrentTitleHelp('Ayuda de Alarmas o fallos');
                        setCurrentMessageHelp(
                            'Permite crear alarmas y fallos personalizados, adaptados a las necesidades de tu sistema. Puedes definir alarmas para situaciones que requieren atención o fallos para errores críticos, ajustando los detalles como el nombre, tipo, guía de corrección, y el intervalo de notificaciones.');
                        toggleHelpModal();
                    }}
                    style={{
                        border: 'none',
                        background: 'none',
                        padding: 0,
                        outline: 'none',
                        boxShadow: 'none'
                    }}
                    className="btn-icon"
                >
                    <i className="mdi mdi-help-circle text-primary fs-20"></i>
                </Button>
                {isEditing ? 'EDITA LA ALARMA O FALLO' : 'AÑADE LA ALARMA O FALLO'}</ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        toggle();
                        handleAlarmOrFaultFormSubmit(values);
                    }}
                >
                    {({ errors, touched, setFieldValue, values }) => (
                        <Form>
                            <FormGroup>
                                <Label for="name">Nombre personalizado *</Label>
                                <Field name="name" type="text" className={`form-control rounded-pill ${errors.name && touched.name ? 'is-invalid' : ''}`} />
                                <ErrorMessage name="name" component="div" className="text-danger" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="type">Tipo *</Label>
                                <Select
                                    name="type"
                                    options={typeOptions}
                                    placeholder="Selecciona un tipo"
                                    value={typeOptions.find(option => option.value === values.type)}
                                    onChange={(option: any) => handleTypeChange(option, setFieldValue)}
                                    isClearable
                                    styles={customStyles}
                                />
                                <ErrorMessage name="type" component="div" className="text-danger" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="faultList">Listado *</Label>
                                {loading ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '40px' }}>
                                        <Spinner color="primary" size="sm" />
                                    </div>
                                ) : (
                                    <Select
                                        name="faultList"
                                        options={filteredFaultsList.map((fault: any) => ({
                                            label: `${values.type === 'Alarma' ? 'A' : 'F'}${fault.fault_code} - ${fault.fault_name} - ID: ${fault.fault_id}`,
                                            value: fault.id
                                        }))}
                                        placeholder="Selecciona una opción"
                                        value={filteredFaultsList.map((fault: any) => ({
                                            label: `${values.type === 'Alarma' ? 'A' : 'F'}${fault.fault_code} - ${fault.fault_name} - ID: ${fault.fault_id}`,
                                            value: fault.id
                                        })).find((option: any) => option.value === values.faultList)}
                                        onChange={(option: any) => setFieldValue('faultList', option ? option.value : '')}
                                        isClearable
                                        styles={customStyles}
                                    />
                                )}
                                <ErrorMessage name="faultList" component="div" className="text-danger" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="custom_correction_guide">Guía de corrección personalizada</Label>
                                <Field name="custom_correction_guide" type="text" className={`form-control rounded-pill ${errors.custom_correction_guide && touched.custom_correction_guide ? 'is-invalid' : ''}`} />
                                <ErrorMessage name="custom_correction_guide" component="div" className="text-danger" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="notification_interval_minutes">Intervalo entre avisos (minutos)</Label>
                                <Field
                                    name="notification_interval_minutes"
                                    type="number"
                                    className={`form-control rounded-pill ${errors.notification_interval_minutes && touched.notification_interval_minutes ? 'is-invalid' : ''}`}
                                    value={values.notification_interval_minutes === null ? '' : values.notification_interval_minutes}
                                />
                                <ErrorMessage name="notification_interval_minutes" component="div" className="text-danger" />
                            </FormGroup>
                            <Col md={12} className='mt-2 d-flex align-items-center mb-3'>
                                <Row className="align-items-center">
                                    <Col className="pr-0">
                                        <Label className="form-check-label" for="activeSwitch">Alarma o fallo activo</Label>
                                    </Col>
                                    <Col xs="auto" className='p-0'>
                                        <div className="form-check form-switch form-switch-lg">
                                            <Field name="is_active">
                                                {({ field }: any) => (
                                                    <input
                                                        {...field}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="activeSwitch"
                                                        checked={field.value}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <ModalFooter className='d-flex justify-content-center pb-0'>
                                <Button color="secondary" onClick={toggle} className="rounded-pill">Cancelar</Button>
                                <Button type="submit" color="primary" className="rounded-pill">Guardar Cambios</Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
            <ModalHelp
                isOpen={isHelpModalOpen}
                toggle={toggleHelpModal}
                title={currentTitleHelp}
                body={currentMessageHelp}
                buttonText="Cerrar"
            />
        </Modal>
    );
};

export default ModalAlarmsAndFaults;
