import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getInstallationCalculatedParameters as getInstallationCalculatedParametersApi,
  createInstallationCalculatedParameter as createInstallationCalculatedParameterApi,
  updateInstallationCalculatedParameter as updateInstallationCalculatedParameterApi,
  deleteInstallationCalculatedParameter as deleteInstallationCalculatedParameterApi

} from "../../helpers/fakebackend_helper";


export const getInstallationCalculatedParameters = createAsyncThunk("installationCalculatedParameters/getInstallationCalculatedParameters", async (installationId: any) => {
  try {

    const response = await getInstallationCalculatedParametersApi(installationId);
    
    return response;
  } catch (error) {
    return error;
  }
});

export const createInstallationCalculatedParameter = createAsyncThunk("installationCalculatedParameter/createInstallationCalculatedParameter", async (installationCalculatedParameter: any) => {
  try {
    const response: any = await createInstallationCalculatedParameterApi(installationCalculatedParameter);
    if (response && response.id) {
      installationCalculatedParameter.id = response.id;
    }
    return installationCalculatedParameter;
  } catch (error) {
    return { 'error': error };
  }
});

export const updateInstallationCalculatedParameter = createAsyncThunk("installationCalculatedParameter/updateInstallationCalculatedParameter", async (installationCalculatedParameter: any) => {
  try {

    const response: any = await updateInstallationCalculatedParameterApi(installationCalculatedParameter);

    return response;
  } catch (error) {
    return error;
  }
});

export const deleteInstallationCalculatedParameter = createAsyncThunk(
  "installationCalculatedParameter/deleteInstallationCalculatedParameter",
  async ({ id, installationId }: { id: number; installationId: any }) => {
    try {
      const response = await deleteInstallationCalculatedParameterApi(id);

      return { id, installationId };
    } catch (error) {
      throw error;
    }
  }
);

