interface GoogleConfig {
  API_KEY: string;
  CLIENT_ID: string;
  SECRET: string;
}

interface FacebookConfig {
  APP_ID: string;
}

interface ApiConfig {
  API_URL: string;
}

interface Config {
  google: GoogleConfig;
  facebook: FacebookConfig;
  api: ApiConfig;
}
const isLocal = window.location.hostname === '192.168.0.6';

const config: Config = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: isLocal 
      ? process.env.REACT_APP_API_URL_LOCAL || "" 
      : process.env.REACT_APP_API_URL_EXTERNAL || "",
  },
};

export default config;