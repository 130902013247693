import React, { useState } from 'react';
import { Button } from 'reactstrap';
import ModalConfirm from './Modals/ModalConfirm';

const EnableDisable = ({ onEnableDisable }: any) => {
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => setModalOpen(!modalOpen);

    const handleConfirm = () => {
        onEnableDisable();
        toggleModal();
    };

    return (
        <div className="ms-1 header-item  d-sm-flex">
            <button
                onClick={toggleModal}
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle enable-disable shadow-none">
                <i className='bx bx-key fs-22'></i>
            </button>

            <ModalConfirm
                isOpen={modalOpen}
                toggle={toggleModal}
                title="Habilitar o Deshabilitar"
                message="¿Estás seguro de que quieres habilitar o deshabilitar esta funcionalidad?"
                onConfirm={handleConfirm}
                onCancel={toggleModal}
                iconName='ri-information-line display-5 text-warning'
            />
        </div>
    );
};

export default EnableDisable;
