import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Col, Row } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { getRoles } from 'slices/roles/thunk';
import rolesIds from 'constants/roles';
import { createUser, getCompanies, updateUser } from 'slices/thunks';
import { get } from 'lodash';

interface ModalUserProps {
    isOpen: boolean;
    toggle: () => void;
    isEditing: boolean;
    userData?: any;
    onUserCreatedorEdited: (isUserCreatedorEdited: boolean, isEditing: boolean) => void;
}

interface UserFormData {
    id: number;
    name: string;
    username: string;
    email: string;
    role: string;
    company: string;
    is_active: boolean;
    supervisor: string;
    technician: string;
}

interface OptionType {
    label: string;
    value: string;
}

const ModalUser: React.FC<ModalUserProps> = ({ isOpen, toggle, isEditing, userData, onUserCreatedorEdited }) => {
    const dispatch: any = useDispatch();
    const selectUsersState = (state: any) => state.Users;
    const selectRolesState = (state: any) => state.Roles;
    const selectCompaniesState = (state: any) => state.Companies;
    const selectLoginState = (state: any) => state.Login;

    const roleDataProperties = createSelector(
        selectRolesState,
        (state: any) => state
    );

    const companyDataProperties = createSelector(
        selectCompaniesState,
        (state: any) => state
    );

    const loginDataProperties = createSelector(
        selectLoginState,
        (state: any) => state
    );

    const userDataProperties = createSelector(
        selectUsersState,
        (state: any) => state
    );

    const { roles } = useSelector(roleDataProperties);
    const { companies } = useSelector(companyDataProperties);
    const { users } = useSelector(userDataProperties);
    const { user } = useSelector(loginDataProperties);

    useEffect(() => {
        dispatch(getRoles());
        dispatch(getCompanies());
    }, [dispatch]);

    const initialValues: UserFormData = isEditing && userData ? {
        id: userData.id,
        name: userData.name || '',
        username: userData.username || '',
        email: userData.email || '',
        role: userData.role_id ? userData.role_id.toString() : '',
        company: userData.company_id ? userData.company_id.toString() : '',
        supervisor: userData.role_id === 4 ? (userData.supervisor_id ? userData.supervisor_id.toString() : '') : '',
        technician: userData.role_id === 5 ? (userData.supervisor_id ? userData.supervisor_id.toString() : '') : '',
        is_active: userData.is_active,
    } : {
        id: 0,
        name: '',
        username: '',
        email: '',
        role: '',
        company: '',
        supervisor: '',
        technician: '',
        is_active: true,
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('El nombre es obligatorio'),
        username: Yup.string().required('El nombre de usuario es obligatorio'),
        email: Yup.string().email('Debe ser un correo válido').required('El email es obligatorio'),
        role: Yup.string().required('El rol es obligatorio'),
        company: Yup.string().when('role', (role, schema) => {
            return Number(role) !== rolesIds.aplicationAdministrator && currentUserRole === rolesIds.aplicationAdministrator ? schema.required('La empresa es obligatoria') : schema;
        }),
        supervisor: Yup.string().when(["role", "company"], (role, company, schema) =>
            currentUserRole !== rolesIds.technician && Number(role) === rolesIds.technician && company ? Yup.string().required('El supervisor es obligatorio') : Yup.string()
        ),
        technician: Yup.string().when(["role", "company"], (role, company, schema) =>
            Number(role) === rolesIds.operator && company ? Yup.string().required('El técnico es obligatorio') : Yup.string()
        ),
        is_active: Yup.boolean(),
    });

    const roleOptions: OptionType[] = roles.map((role: any) => ({
        label: role.role_name,
        value: role.id.toString()
    }));

    const companyOptions: OptionType[] = companies.map((company: any) => ({
        label: company.company_name,
        value: company.id.toString()
    }));

    const currentUserRole = user.role_id;
    const currentUserCompany = user.company_id;
    const currentUserId = user.user_id;

    const handleUserFormSubmit = async (values: any) => {
        let supervision_id: string = "";
        if (values.supervisor !== "") {
            supervision_id = values.supervisor;
        } else if (values.technician !== "") {
            supervision_id = values.technician;
        } else if (currentUserRole === rolesIds.supervisor || currentUserRole === rolesIds.technician) {
            supervision_id = currentUserId;
        }

        const company = companies.find((company: any) => company.id === Number(values.company));
        const company_name = company ? company.company_name : '';
        const user = {
            id: values.id,
            name: values.name,
            username: values.username,
            password: null,
            email: values.email,
            role_id: Number(values.role),
            company_id: Number(values.company) !== 0 || values.company !== "" ? Number(values.company) : currentUserCompany,
            company_name: company_name === "" ? "No asignada" : company_name,
            role_name: roles.find((role: any) => role.id.toString() === values.role).role_name,
            is_active: values.is_active,
            supervisor_id: Number(values.role) !== rolesIds.aplicationAdministrator &&
                Number(values.role) !== rolesIds.companyAdministrator &&
                Number(values.role) !== rolesIds.supervisor
                ? Number(supervision_id) : null
        };

        let response;
        if (isEditing) {
            response = await dispatch(updateUser(user)).unwrap();
        } else {
            response = await dispatch(createUser(user)).unwrap();
        }

        if (!response.error) {
            onUserCreatedorEdited(true, isEditing);
        } else {
            onUserCreatedorEdited(false, isEditing);
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop="static" keyboard={false}>
            <ModalHeader>{isEditing ? 'EDITA AL USUARIO' : 'CREA UN USUARIO'}</ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        toggle();
                        handleUserFormSubmit(values);
                    }}
                >
                    {({ errors, touched, setFieldValue, values }) => (
                        <Form>
                            <FormGroup>
                                <Label for="name">Nombre *</Label>
                                <Field name="name" type="text" className={`form-control rounded-pill ${errors.name && touched.name ? 'is-invalid' : ''}`} />
                                <ErrorMessage name="name" component="div" className="text-danger" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="username">Usuario *</Label>
                                <Field name="username" type="text" className={`form-control rounded-pill ${errors.username && touched.username ? 'is-invalid' : ''}`} />
                                <ErrorMessage name="username" component="div" className="text-danger" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email *</Label>
                                <Field name="email" type="email" className={`form-control rounded-pill ${errors.email && touched.email ? 'is-invalid' : ''}`} />
                                <ErrorMessage name="email" component="div" className="text-danger" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="role">Rol *</Label>
                                <Field name="role">
                                    {({ form }: FieldProps) => (
                                        <Select
                                            options={roleOptions}
                                            classNamePrefix="select"
                                            value={roleOptions.find(option => option.value === form.values.role)}
                                            onChange={(option: any) => {
                                                form.setFieldValue('role', option ? option.value : '');
                                                form.setFieldTouched('role', true, false);
                                            }}
                                            onBlur={() => form.setFieldTouched('role', true, true)}
                                            placeholder="Selecciona un rol"
                                            isClearable
                                            styles={{
                                                control: (base: any, state: any) => ({
                                                    ...base,
                                                    borderRadius: '20px',
                                                    borderColor: state.isFocused ? '#80bdff' : base.borderColor,
                                                    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 'none',
                                                    '&:hover': {
                                                        borderColor: state.isFocused ? '#80bdff' : '#ced4da'
                                                    }
                                                }),
                                                menu: (base: any) => ({
                                                    ...base,
                                                    borderRadius: '10px'
                                                }),
                                            }}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="role" component="div" className="text-danger" />
                            </FormGroup>

                            {currentUserRole === rolesIds.aplicationAdministrator &&
                                values.role && values.role !== rolesIds.aplicationAdministrator.toString() && (
                                    <FormGroup>
                                        <Label for="company">Nombre de la Empresa *</Label>
                                        <Field name="company">
                                            {({ form }: FieldProps) => (
                                                <Select
                                                    options={companyOptions}
                                                    classNamePrefix="select"
                                                    value={companyOptions.find(option => option.value === form.values.company)}
                                                    onChange={(option: any) => {
                                                        form.setFieldValue('company', option ? option.value : '');
                                                        form.setFieldTouched('company', true, false);
                                                    }}
                                                    onBlur={() => form.setFieldTouched('company', true, true)}
                                                    placeholder="Selecciona una empresa"
                                                    isClearable
                                                    styles={{
                                                        control: (base: any, state: any) => ({
                                                            ...base,
                                                            borderRadius: '20px',
                                                            borderColor: state.isFocused ? '#80bdff' : base.borderColor,
                                                            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 'none',
                                                            '&:hover': {
                                                                borderColor: state.isFocused ? '#80bdff' : '#ced4da'
                                                            }
                                                        }),
                                                        menu: (base: any) => ({
                                                            ...base,
                                                            borderRadius: '10px'
                                                        }),
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="company" component="div" className="text-danger" />
                                    </FormGroup>
                                )}

                            {((currentUserRole === rolesIds.aplicationAdministrator && values.company) ||
                                currentUserRole === rolesIds.companyAdministrator) &&
                                values.role === rolesIds.technician.toString() && (
                                    <FormGroup>
                                        <Label for="supervisor">Supervisor Asociado *</Label>
                                        <Field name="supervisor">
                                            {({ form }: FieldProps) => {
                                                const filteredSupervisors = users.filter((user: any) =>
                                                    user.role_id === rolesIds.supervisor && (
                                                        (currentUserRole === rolesIds.aplicationAdministrator &&
                                                            user.company_id === Number(form.values.company)) ||
                                                        user.company_id === currentUserCompany
                                                    ));

                                                const selectedSupervisor = filteredSupervisors.find((supervisor: any) => supervisor.id.toString() === form.values.supervisor);

                                                return (
                                                    <Select
                                                        options={filteredSupervisors.map((supervisor: any) => ({
                                                            label: supervisor.name + " - " + supervisor.username,
                                                            value: supervisor.id.toString()
                                                        }))}
                                                        classNamePrefix="select"
                                                        value={selectedSupervisor ? { label: selectedSupervisor.name, value: selectedSupervisor.id.toString() } : null}
                                                        onChange={(option: any) => {
                                                            form.setFieldValue('supervisor', option ? option.value : '');
                                                            form.setFieldTouched('supervisor', true, false);
                                                        }}
                                                        onBlur={() => form.setFieldTouched('supervisor', true, true)}
                                                        placeholder="Selecciona un supervisor"
                                                        isClearable
                                                        styles={{
                                                            control: (base: any, state: any) => ({
                                                                ...base,
                                                                borderRadius: '20px',
                                                                borderColor: state.isFocused ? '#80bdff' : base.borderColor,
                                                                boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 'none',
                                                                '&:hover': {
                                                                    borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                                                                }
                                                            }),
                                                            menu: (base: any) => ({
                                                                ...base,
                                                                borderRadius: '10px'
                                                            }),
                                                        }}
                                                    />
                                                );
                                            }}
                                        </Field>
                                        <ErrorMessage name="supervisor" component="div" className="text-danger" />
                                    </FormGroup>
                                )}

                            {((currentUserRole === rolesIds.aplicationAdministrator && values.company) ||
                                currentUserRole === rolesIds.companyAdministrator ||
                                currentUserRole === rolesIds.supervisor) &&
                                values.role === rolesIds.operator.toString() && (
                                    <FormGroup>
                                        <Label for="technician">Técnico Asociado *</Label>
                                        <Field name="technician">
                                            {({ form }: FieldProps) => {
                                                const filteredTechnicians = users.filter((user: any) =>
                                                    user.role_id === rolesIds.technician && (
                                                        (currentUserRole === rolesIds.aplicationAdministrator &&
                                                            user.company_id === Number(form.values.company)) ||
                                                        user.company_id === currentUserCompany
                                                    ));

                                                const selectedTechnician = filteredTechnicians.find((user: any) => user.id.toString() === form.values.technician);

                                                return (
                                                    <Select
                                                        options={filteredTechnicians.map((tech: any) => ({
                                                            label: tech.name + " - " + tech.username,
                                                            value: tech.id.toString()
                                                        }))}
                                                        classNamePrefix="select"
                                                        value={selectedTechnician ? { label: selectedTechnician.name, value: selectedTechnician.id.toString() } : null}
                                                        onChange={(option: any) => {
                                                            form.setFieldValue('technician', option ? option.value : '');
                                                            form.setFieldTouched('technician', true, false);
                                                        }}
                                                        onBlur={() => form.setFieldTouched('technician', true, true)}
                                                        placeholder="Selecciona un técnico"
                                                        isClearable
                                                        styles={{
                                                            control: (base: any, state: any) => ({
                                                                ...base,
                                                                borderRadius: '20px',
                                                                borderColor: state.isFocused ? '#80bdff' : base.borderColor,
                                                                boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 'none',
                                                                '&:hover': {
                                                                    borderColor: state.isFocused ? '#80bdff' : '#ced4da'
                                                                }
                                                            }),
                                                            menu: (base: any) => ({
                                                                ...base,
                                                                borderRadius: '10px'
                                                            }),
                                                        }}
                                                    />
                                                );
                                            }}
                                        </Field>
                                        <ErrorMessage name="technician" component="div" className="text-danger" />
                                    </FormGroup>
                                )}

                            <Col md={12} className='mt-2 d-flex align-items-center mb-3'>
                                <Row className="align-items-center">
                                    <Col className="pr-0">
                                        <Label className="form-check-label" for="activeSwitch">Usuario activo</Label>
                                    </Col>
                                    <Col xs="auto" className='p-0'>
                                        <div className="form-check form-switch form-switch-lg">
                                            <Field name="is_active">
                                                {({ field }: FieldProps) => (
                                                    <input
                                                        {...field}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="activeSwitch"
                                                        checked={field.value}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <ModalFooter className='d-flex justify-content-center p-0'>
                                <Button color="secondary" onClick={toggle} className="rounded-pill">Cancelar</Button> {/* Controla el cierre solo aquí */}
                                <Button type="submit" color="primary" className="rounded-pill">Guardar Cambios</Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
}

export default ModalUser;
