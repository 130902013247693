import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getParameterValues as getParameterValuesApi,
  getParameterValuesByConnection as getParameterValuesByConnectionApi,
  createParameterValues as createParameterValuesApi,
  updateParameterValues as updateParameterValuesApi,
  deleteParameterValues as deleteParameterValuesApi,
} from "../../helpers/fakebackend_helper";

export const getParameterValues = createAsyncThunk("parameterValues/getParameterValues", async (connectionId: number) => {
  try {
   
    const response = await getParameterValuesApi(connectionId);

    return response;
  } catch (error) {
    return error;
  }
});

export const getParameterValuesByConnection = createAsyncThunk("parameterValues/getParametersValuesByConnection", async (connectionId: number) => {
  try {
   
    const response = await getParameterValuesByConnectionApi(connectionId);
    

    return response;
  } catch (error) {
    return error;
  }
});

export const createParameterValues = createAsyncThunk("parameterValues/createParameterValues", async (parameter: any) => {
  try {

    const response: any = await createParameterValuesApi(parameter);
    if (response.id) {
      parameter.id = response.id;
      parameter.supervision_name = response.supervision_name;
    }
    return parameter;
  } catch (error) {
    return { 'error': error };
  }
});


export const updateParameterValues = createAsyncThunk(
  "parameterValues/updateParameterValues",
  async (parameterInfo: { connectionId: number; parameter_id: any; value: any }, { rejectWithValue }) => {
    try {
      const response = await updateParameterValuesApi(parameterInfo); // Asegúrate de que esta función maneje el objeto completo
      return response.data; // Ajusta esto según cómo tu API devuelva los datos
    } catch (error: any) {
      return rejectWithValue({ error: error.mesage }); // Maneja el error según tu lógica
    }
  }
);

export const deleteParameterValues = createAsyncThunk("parameterValues/deleteParameterValues", async (parameter: any) => {
  try {

    const response = await deleteParameterValuesApi(parameter);
    return parameter;
  } catch (error) {
    return error;
  }
});
