import { createAsyncThunk } from "@reduxjs/toolkit";

// Importa el método que realiza la llamada API
import { 
  getSectionDataConfigByZone as getSectionDataConfigByZoneApi, 
} from "../../helpers/fakebackend_helper";


export const getSectionDataConfigByZone = createAsyncThunk("sectionDataConfig/getSectionDataConfigByZone", async (zoneId: number) => {
  try {
    
    const response = await getSectionDataConfigByZoneApi(zoneId);

    return {zoneId: zoneId, data: response};
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});


