import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getStatusZone } from 'slices/statusZone/thunk';
import TableContainer from 'Components/Common/TableContainerReactTable';
import { getStartupRegistrationByZone } from 'slices/startupRegistration/thunk';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

interface StatusZone {
    id: number;
    installation_zone_id: number;
    name: string;
    description: string;
    position: number;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
    value: string;
}

interface ModalViewStartupStatusProps {
    isOpen: boolean;
    toggle: () => void;
    idSelected: number | null;
}

const ModalViewStartupStatus: React.FC<ModalViewStartupStatusProps> = ({ isOpen, toggle, idSelected }) => {
    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.StartupRegistration;
    const statusZoneDataProperties = createSelector(selectLayoutState, (state: any) => state);
    const { startupRegistration } = useSelector(statusZoneDataProperties);
    const statusZones: StatusZone[] = Array.isArray(startupRegistration) ? startupRegistration : [];

    const [zones, setZones] = useState<StatusZone[]>(statusZones);

    const formatDate = (dateString: string) => {
        if (!dateString) return '';
        const date = new Date(dateString);

        const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

        return format(localDate, 'dd/MM/yyyy HH:mm', { locale: es });
    };

    useEffect(() => {
        if (isOpen && idSelected) {
            dispatch(getStartupRegistrationByZone(idSelected))
            dispatch(getStatusZone(idSelected));
        }
    }, [isOpen, dispatch, idSelected]);

    useEffect(() => {
        if (JSON.stringify(statusZones) !== JSON.stringify(zones)) {
            setZones(statusZones);
        }
    }, [statusZones]);

    const columns = [

        {
            Header: 'Descripción',
            accessor: 'description',
        },
        {
            Header: 'Creado',
            accessor: 'created_at',
            Cell: ({ value }: any) => formatDate(value)
        },

    ];

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>Ver Estados</ModalHeader>
                <ModalBody>
                    <TableContainer
                        columns={columns}
                        data={zones}
                        isPagination={true}
                        isGlobalFilter={true}
                        isBordered={false}
                        customPageSize={9}
                        className="custom-header-css table align-middle table-nowrap"
                        tableClass="table-centered align-middle table-nowrap mb-0"
                        theadClass="text-muted table-light"
                        SearchPlaceholder='Buscar estado...'
                        button={false} // Asumo que no necesitas el botón de "Añadir Usuario" en este caso
                    />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ModalViewStartupStatus;
