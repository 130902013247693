import React, { useEffect, useState, useMemo } from 'react';
import { Col, Container, Spinner, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { getFaultHistoriesByZone } from 'slices/faultHistory/thunk';
import { getAlarmHistoriesByZone } from 'slices/alarmHistory/thunk';
import { ToastContainer, toast } from 'react-toastify';
import BreadCrumb from 'Components/Common/BreadCrumb';
import GeneralData from 'pages/Dashboard/GeneralData';
import TableContainer from 'Components/Common/TableContainerReactTable';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';

const FaultAndAlarmHistory = () => {

    const { id } = useParams();

    const selectInstallationState = (state: any) => state.Installations;

    const installationDataProperties = createSelector(
        selectInstallationState,
        (state: any) => state
    );

    const { installations } = useSelector(installationDataProperties);

    const installation = installations?.find((inst: any) =>
        inst.zones.some((zone: any) => zone.id == id)
    );

    const zone = installation?.zones.find((zone: any) => zone.id == id);
    document.title = "Historial de Fallos y Alarmas | EcentroServer";

    const dispatch: any = useDispatch();

    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);

    const selectFaultHistoryState = (state: any) => state.FaultHistory;
    const selectAlarmHistoryState = (state: any) => state.AlarmHistory;
    const [selectedFaultDescription, setSelectedFaultDescription] = useState("");

    const FaultHistoryProperties = createSelector(
        selectFaultHistoryState,
        (state) => state
    );

    const AlarmHistoryProperties = createSelector(
        selectAlarmHistoryState,
        (state) => state
    );

    const openModal = (description: string) => {
        setSelectedFaultDescription(description);
        toggle();
    }

    const toggle = () => setModal(!modal);

    const { faultHistories } = useSelector(FaultHistoryProperties);
    const { alarmHistories } = useSelector(AlarmHistoryProperties);


    useEffect(() => {
        dispatch(getFaultHistoriesByZone());
        dispatch(getAlarmHistoriesByZone());
        setLoading(false);
    }, [dispatch]);

    const allColumnsFaults = useMemo(
        () => [
            {
                Header: "Dispositivo",
                accessor: "connection_name",
                Cell: ({ value }: any) => value || 'Desconocido'
            },
            {
                Header: "Nombre",
                accessor: "custom_fault.custom_fault_name",
                Cell: ({ row }: any) => row.original.custom_fault?.custom_fault_name || row.original.fault?.fault_name || 'Desconocido'
            },
            {
                Header: "Creado",
                accessor: "created_at",
                Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy HH:mm:ss')
            },
            {
                Header: "Emails enviados",
                accessor: "email_count",
                Cell: ({ value }) => <div className="text-center">{value}</div>
            },
            {
                Header: "Acciones",
                accessor: "actions",
                Cell: ({ row }: any) => (
                    <Button color="link" onClick={() => openModal(row.original.custom_fault?.custom_correction_guide || row.original.fault?.correction_guide)}>
                        <i className='las la-info-circle'></i>
                    </Button>
                )
            },
        ],
        []
    );

    const allColumnsAlarms = useMemo(
        () => [
            {
                Header: "Dispositivo",
                accessor: "connection_name",
                Cell: ({ value }: any) => value || 'Desconocido'
            },
            {
                Header: "Nombre",
                accessor: "alarm.fault_name",
                Cell: ({ value }: any) => value || 'Desconocido'
            },
            {
                Header: "Creado",
                accessor: "created_at",
                Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy HH:mm:ss')
            },
            {
                Header: "Emails enviados",
                accessor: "email_count",
                Cell: ({ value }) => <div className="text-center">{value}</div>
            },
            {
                Header: "Acciones",
                accessor: "actions",
                Cell: ({ row }: any) => (
                    <Button color="link" onClick={() => openModal(row.original.custom_fault?.custom_correction_guide || row.original.fault?.correction_guide)}>
                        <i className='las la-info-circle'></i>
                    </Button>
                )
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <BreadCrumb
                pageTitle={Array.isArray(installations) && installations
                    .find((installation: any) => installation.zones
                        .some((zone: any) => zone.id === Number(id) && zone.is_active))?.installation_name}
                pageTitleUrl={`/dashboard/instalacion/${installation && installation.id}`}

                subtitle={Array.isArray(installations) && installations
                    .flatMap((installation: any) => installation.zones)
                    .find((zone: any) => zone.id == Number(id) && zone.is_active)?.zone_name}

                subtitleUrl={`/dashboard/zona/${zone && zone.id}`}

                subsubtitle={"Historial de fallos y alarmas"}
            />
            <div className="page-content">
                <Container fluid>
                    <Col lg={12} className="order-xxl-0 order-first">
                        <GeneralData />
                    </Col>
                    <Row className="d-flex">
                        <Col md={6} className="d-flex flex-column p-0">
                            <Card className=" p-3 flex-grow-1 m-2 rounded shadow-sm">
                                <div>
                                    <div className="d-flex align-items-center mb-3">
                                        <h5 className="flex-grow-1 fs-16 mb-0">Historial de Fallos</h5>
                                    </div>
                                    {loading ? (
                                        <div className="text-center vh-100 d-flex justify-content-center align-items-center">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <TableContainer
                                            columns={allColumnsFaults}
                                            data={faultHistories}
                                            customPageSize={8}
                                            divClass="table-responsive table-card mb-1"
                                            tableClass="align-middle table-nowrap"
                                            theadClass="table-light text-muted"
                                            isPagination={true}
                                            isGlobalFilter={true}
                                            SearchPlaceholder='Busca un fallo...'
                                        />
                                    )}
                                </div>
                            </Card>
                        </Col>
                        <Col md={6} className="d-flex flex-column p-0">
                            <Card className=" m-2 p-3 flex-grow-1 rounded shadow-sm">
                                <div>
                                    <div className="d-flex align-items-center mb-3">
                                        <h5 className="flex-grow-1 fs-16 mb-0">Historial de Alarmas</h5>
                                    </div>
                                    {loading ? (
                                        <div className="text-center vh-100 d-flex justify-content-center align-items-center">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <TableContainer
                                            columns={allColumnsAlarms}
                                            data={alarmHistories}
                                            customPageSize={8}
                                            divClass="table-responsive table-card mb-1"
                                            tableClass="align-middle table-nowrap"
                                            theadClass="table-light text-muted"
                                            isPagination={true}
                                            isGlobalFilter={true}
                                            SearchPlaceholder='Busca una alarma...'
                                        />
                                    )}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Descripción del Fallo</ModalHeader>
                <ModalBody>
                    {selectedFaultDescription || 'No hay descripción disponible.'}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default FaultAndAlarmHistory;
