import { createSlice } from "@reduxjs/toolkit";
import {
  createStatus,
  getStatus,
  deleteStatus,
  updateStatus,

} from "./thunk";



export const initialState: any = {
  status: [],
  loadedStatus: false
};


const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setStatusLoaded: (state, action) => {
      state.loadedStatus= action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStatus.fulfilled, (state: any, action: any) => {
      state.status = action.payload;
    });
    builder.addCase(getStatus.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });



    builder.addCase(createStatus.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        state.status.push(action.payload);
      }

    });

    builder.addCase(createStatus.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateStatus.fulfilled, (state: any, action: any) => {
      state.status = (state.status || []).map((status: any) =>
        status.id.toString() === action.payload.id.toString()
          ? { ...status, ...action.payload }
          : status
      );
    });

    builder.addCase(updateStatus.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteStatus.fulfilled, (state: any, action: any) => {
      state.status = state.status.filter(
        (status: any) => status.id.toString() !== action.payload.toString()
      );
    });

    builder.addCase(deleteStatus.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});

export const {setStatusLoaded } = statusSlice.actions;
export default statusSlice.reducer;