import { createSlice } from "@reduxjs/toolkit";
import {
  getDailyAverageInstallationRegistersBetweenDates,
  getInstallationRegistersBetweenDates
} from "../../slices/installationRegisters/thunk";

export const initialState: any = {
  installationRegisters: {},
  selectedOptions: {},
  loading: false,
  error: null
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setZoneSelection: (state, action) => {
      const { zoneId, selectedYear, selectedMonth, selectedWeek, selectedDay, selectionMode, startDate, endDate  } = action.payload;
      state.selectedOptions[zoneId] = {
        selectedYear: selectedYear,
        selectedMonth: selectedMonth,
        selectedWeek: selectedWeek,
        selectedDay: selectedDay,
        selectionMode: selectionMode,
        startDate: startDate,
        endDate: endDate
      };
    },
  },
  extraReducers: (builder) => {
    builder

    .addCase(getDailyAverageInstallationRegistersBetweenDates.fulfilled, (state, action) => {
      const { installationId } = action.meta.arg; 
      state.loading = false;
      state.installationRegisters[installationId] = action.payload; 
    })
      .addCase(getDailyAverageInstallationRegistersBetweenDates.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload.error || null;
      })

      .addCase(getInstallationRegistersBetweenDates.fulfilled, (state, action) => {
        const { installationId} = action.meta.arg;  
        state.loading = false;
        state.installationRegisters[installationId] = action.payload;
      })

      .addCase(getInstallationRegistersBetweenDates.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload.error || null;
      })
  },
});

export const { setZoneSelection } = registerSlice.actions;
export default registerSlice.reducer;
