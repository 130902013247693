import React from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, CardTitle, Button } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useNavigate } from 'react-router-dom';

const Empty = () => {
    document.title = "Sin instalaciones | Ecentroserver";

    return (
        <React.Fragment>
            <div className="page-content">

            </div>
        </React.Fragment>
    );
};

export default Empty;
