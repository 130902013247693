import { createSlice } from "@reduxjs/toolkit";
import {
  getZoneEmailByUser,
  createZoneEmailByUser,
  deleteZoneEmailByUser
} from "./thunk";

export const initialState: any = {
  zoneEmails: [],
  error: null,
  loading: false,
};

const zoneEmailSlice = createSlice({
  name: "zoneEmail",
  initialState,
  reducers: {},

  extraReducers: (builder) => {

    builder.addCase(getZoneEmailByUser.fulfilled, (state, action) => {
      state.zoneEmails = action.payload;
    })
    builder.addCase(getZoneEmailByUser.rejected, (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload.error || null;
    })
    builder.addCase(createZoneEmailByUser.fulfilled, (state : any, action : any) => {
  
    
        state.zoneEmails.push(action.payload);
      
    });
    builder.addCase(createZoneEmailByUser.rejected, (state : any, action : any) => {
      state.loading = false;
      state.error = action.payload.error || null;
    });
    builder.addCase(deleteZoneEmailByUser.fulfilled, (state : any, action : any) => {
  
      state.zoneEmails = state.zoneEmails.filter((email : any) => email.id !== action.payload);
    });
    builder.addCase(deleteZoneEmailByUser.rejected, (state : any, action : any) => {
      state.loading = false;
      state.error = action.payload.error || null;
    });
  },
});

export default zoneEmailSlice.reducer;
