import { createSlice } from "@reduxjs/toolkit";
import {
  createInstallationZone,
  getInstallationZones,
  deleteInstallationZone,
  updateInstallationZone,
  reorderInstallationZones,
  getInstallationZonesByUser,
  updateUserInstallationZone
} from "./thunk";



export const initialState: any = {
  installationZones: [],
};


const installationZoneSlice = createSlice({
  name: "installationZone",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInstallationZones.fulfilled, (state: any, action: any) => {
      state.installationZones = action.payload;
    });
    builder.addCase(getInstallationZones.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getInstallationZonesByUser.fulfilled, (state: any, action: any) => {
      state.installationZonesByUser = action.payload;
    });
    builder.addCase(getInstallationZonesByUser.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(createInstallationZone.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        state.installationZones.push(action.payload);
      }

    });

    builder.addCase(createInstallationZone.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateInstallationZone.fulfilled, (state: any, action: any) => {
      state.installationZones = (state.installationZones || []).map((installationZone: any) =>
        installationZone.id.toString() === action.payload.id.toString()
          ? { ...installationZone, ...action.payload }
          : installationZone
      );
    });

    builder.addCase(updateInstallationZone.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateUserInstallationZone.fulfilled, (state, action) => {
      state.installationZonesByUser = (state.installationZonesByUser || []).map((installationZone: any) => {
        if (installationZone.installation_id.toString() === action.payload.installation_id.toString()) {
          const updatedZones = installationZone.zones.map((zone: any) => {
            if (zone.id.toString() === action.payload.installation_zone_id.toString()) {
              if (action.payload.type === 'read') {
                return {
                  ...zone,
                  read: action.payload.checked,
                  read_write: action.payload.checked ? false : zone.read_write
                };
              } else if (action.payload.type === 'read_write') {
                return {
                  ...zone,
                  read_write: action.payload.checked,
                  read: action.payload.checked ? false : zone.read
                };
              }
            }
            return zone;
          });

          return { ...installationZone, zones: updatedZones };
        }

        return installationZone;
      });
     
    });

    builder.addCase(updateUserInstallationZone.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


    builder.addCase(reorderInstallationZones.fulfilled, (state: any, action: any) => {
      state.installationZones = action.payload;
    });

    builder.addCase(reorderInstallationZones.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteInstallationZone.fulfilled, (state: any, action: any) => {
      state.installationZones = state.installationZones.filter(
        (installationZone: any) => installationZone.id.toString() !== action.payload.toString()
      );
    });

    builder.addCase(deleteInstallationZone.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});

export default installationZoneSlice.reducer;