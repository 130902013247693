import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spinner, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import { createSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { resetValues, setCurrentParameterPath, setFilteredParametersBackup } from 'slices/parametersBackup/reducer';
import ParameterTable from './ParameterTable';

interface MenuItem {
    title: string;
    icon?: string;
    category?: string;
    link?: string;
    subItems?: MenuItem[];
}

interface ParametersListProps {
    allColumns: any[];
    parametersList: any[];
    filterActive: string;
    connectionId: number;
}

const ParametersList: React.FC<ParametersListProps> = ({ allColumns, parametersList, filterActive, connectionId }) => {
    const dispatch: any = useDispatch();
    const { id } = useParams();
    const [offcanvasOpen, setOffcanvasOpen] = useState(false);

    const toggleOffcanvas = () => setOffcanvasOpen(!offcanvasOpen);

    const selectParametersBackupState = (state: any) => state.ParametersBackup;
    const selectParameterValuesState = (state: any) => state.ParameterValues;
    const selectFavoritesState = (state: any) => state.Favorites;

    const deviceParameterProperties = createSelector(
        selectParametersBackupState,
        (state: any) => state
    );

    const deviceParameterValuesProperties = createSelector(
        selectParameterValuesState,
        (state: any) => state
    );

    const FavoritesProperties = createSelector(
        selectFavoritesState,
        (state: any) => state
    );

    const { parametersBackup, currentParameterPath, filteredParameterBackup } = useSelector(deviceParameterProperties);
    const { parameterValues } = useSelector(deviceParameterValuesProperties);
    const { favorites } = useSelector(FavoritesProperties);

    const handleCategoryClick = (group: string, subgroup1?: string, subgroup2?: string, subgroup3?: string) => {
        if (isActive(buildParameterPath({ group, subgroup_1: subgroup1, subgroup_2: subgroup2, subgroup_3: subgroup3 }))) {
            return;
        }

        dispatch(setCurrentParameterPath({ group, subgroup_1: subgroup1, subgroup_2: subgroup2, subgroup_3: subgroup3 }));

        let filteredParameters = parametersBackup[connectionId].parameters.filter((param: any) =>
            param.group === group &&
            (!subgroup1 || param.subgroup_1 === subgroup1) &&
            (!subgroup2 || param.subgroup_2 === subgroup2) &&
            (!subgroup3 || param.subgroup_3 === subgroup3)
        );

        const connection_ip = parametersBackup[connectionId].connection_ip;
        const device_range = parametersBackup[connectionId].device_range;
        filteredParameters = filteredParameters.map((param: any) => {
            const favorite = favorites[connectionId] && favorites[connectionId].find((fav: any) => fav.parameter_id == param.id);
            return {
                ...param,
                favorite,
                connection_ip,
                device_range,
                connectionId
            };
        });

        dispatch(setFilteredParametersBackup(filteredParameters));

        // Close the offcanvas on mobile screens when an item is clicked
        if (window.innerWidth < 992) {
            setOffcanvasOpen(false);
        }
    };

    useEffect(() => {
        if (favorites[connectionId]) {
            const newfilteredParameterBackup = filteredParameterBackup.map((param: any) => {
                const favorite = favorites[connectionId].find((fav: any) => fav.parameter_id == param.id);
                return {
                    ...param,
                    favorite
                };
            });
            dispatch(setFilteredParametersBackup(newfilteredParameterBackup));
        }
    }, [favorites]);

    const buildParameterPath = ({ group, subgroup_1, subgroup_2, subgroup_3 }: any) => {
        const parts = [group, subgroup_1, subgroup_2, subgroup_3].filter(Boolean);
        return parts.join(' > ');
    };

    const parameterPath = buildParameterPath(currentParameterPath);

    useEffect(() => {
        return () => {
            dispatch(setFilteredParametersBackup([]));
            dispatch(setCurrentParameterPath({ group: "", subgroup_1: "", subgroup_2: "", subgroup_3: "" }));
        };
    }, []);

    const formatIndex = (index: string, level: number) => {
        const parts = index.split('.');
        const formattedParts = parts.slice(0, level + 1);
        const formattedIndex = formattedParts.join('.');
        if (['B', 'V', 'P'].includes(formattedIndex.charAt(0))) {
            return formattedIndex.substring(1);
        }
        return formattedIndex;
    };

    const extractName = (title: string) => {
        const firstSpaceIndex = title.indexOf(' ');
        return title.substring(firstSpaceIndex + 1);
    };

    const isActive = (path: string) => {
        const currentPath = buildParameterPath(currentParameterPath);
        return currentPath === path;
    };

    const menuItems = parametersBackup[connectionId]?.groups ? parametersBackup[connectionId].groups.parameters.map((group: any) => {
        const deviceRange = parametersBackup[connectionId]?.device_range;
        const isClickable = deviceRange.trim().toLowerCase() === "vacon" && group.group.trim().toLowerCase() === "guía rápida";

        return {
            title: group.group,
            icon: "ri-file-list-2-line",
            isClickable: isClickable,
            subItems: parametersBackup[connectionId].subgroups1.parameters
                .filter((subItem: any) => subItem.group === group.group && subItem.index.substring(2, 3) === group.index.substring(2, 3))
                .map((subItem: any) => {
                    const prefix = subItem.parameter_type === 'Visualization' ? 'V' : 'P'; // Aquí defines si es 'v' o 'p' según el tipo
                    return {
                        title: `${prefix} ${formatIndex(subItem.index, 1)} ${subItem.subgroup_1}`,
                        displayTitle: subItem.subgroup_1,
                        subItems: parametersBackup[connectionId].subgroups2.parameters
                            .filter((subSubItem: any) => {
                                const formattedIndex = formatIndex(subItem.index, 1);
                                const matches = subSubItem.subgroup_1 === subItem.subgroup_1 && formatIndex(subSubItem.index, 2).startsWith(formattedIndex);
                                return matches;
                            })
                            .map((subSubItem: any) => {
                                const prefix = subSubItem.parameter_type === 'Visualization' ? 'V' : 'P';
                                return {
                                    title: `${prefix} ${formatIndex(subSubItem.index, 2)} ${subSubItem.subgroup_2}`,
                                    displayTitle: subSubItem.subgroup_2,
                                    subItems: parametersBackup[connectionId].subgroups3.parameters
                                        .filter((subSubSubItem: any) => subSubSubItem.subgroup_2 === subSubItem.subgroup_2 && subSubSubItem.index.substring(2).startsWith(subSubItem.index.substring(2)))
                                        .map((subSubSubItem: any) => {
                                            const prefix = subSubSubItem.parameter_type === 'Visualization' ? 'v' : 'p';
                                            return {
                                                title: `${prefix} ${formatIndex(subSubSubItem.index, 3)} ${subSubSubItem.subgroup_3}`,
                                                displayTitle: subSubSubItem.subgroup_3
                                            };
                                        })
                                };
                            })
                    };
                })
        };
    }) : [];

    return (
        <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n1 p-1 min-vh-100">
            {/* Offcanvas for mobile view */}
            <Offcanvas isOpen={offcanvasOpen} toggle={toggleOffcanvas} direction="start" transition>
                <OffcanvasHeader toggle={toggleOffcanvas}>
                    {parametersBackup && parametersBackup[connectionId] ? parametersBackup[connectionId].connection_name : ''}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0 d-flex flex-column">
                    {menuItems.length === 0 ? (
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <Spinner color="primary" />
                        </div>
                    ) : (
                        <div className="mt-3 file-menu-sidebar-scroll ps-3">
                            <ul className="list-unstyled file-manager-menu">
                                {menuItems.map((item: any, index: any) => (
                                    <li key={index}>
                                        {item.subItems ? (
                                            <>
                                                {item.isClickable ? (
                                                    <a href="#!" onClick={(e) => { e.preventDefault(); handleCategoryClick(item.title); }} className={isActive(item.title) ? "active" : ""}>
                                                        <i className={`${item.icon} align-bottom me-2`}></i> <span className="file-list-link">{item.title}</span>
                                                    </a>
                                                ) : (
                                                    <span>
                                                        <i className={`${item.icon} align-bottom me-2`}></i> <span className="file-list-link">{item.title}</span>
                                                    </span>
                                                )}
                                                <ul className="sub-menu list-unstyled ps-3">
                                                    {item.subItems.map((subItem: any, subIndex: any) => (
                                                        <li key={subIndex}>
                                                            <a href="#!" onClick={(e) => { e.preventDefault(); handleCategoryClick(item.title, subItem.displayTitle); }} className={isActive(`${item.title} > ${subItem.displayTitle}`) ? "active" : ""}>{subItem.title}</a>
                                                            {subItem.subItems && (
                                                                <ul className="list-unstyled ps-3">
                                                                    {subItem.subItems.map((subSubItem: any, subSubIndex: any) => (
                                                                        <li key={subSubIndex}>
                                                                            <a href="#!" onClick={(e) => { e.preventDefault(); handleCategoryClick(item.title, subItem.displayTitle, subSubItem.displayTitle); }} className={isActive(`${item.title} > ${subItem.displayTitle} > ${subSubItem.displayTitle}`) ? "active" : ""}>{subSubItem.title}</a>
                                                                            {subSubItem.subItems && (
                                                                                <ul className="list-unstyled ps-3">
                                                                                    {subSubItem.subItems.map((subSubSubItem: any, subSubSubIndex: any) => (
                                                                                        <li key={subSubSubIndex}>
                                                                                            <a href="#!" onClick={(e) => { e.preventDefault(); handleCategoryClick(item.title, subItem.displayTitle, subSubItem.displayTitle, subSubSubItem.displayTitle); }} className={isActive(`${item.title} > ${subItem.displayTitle} > ${subSubItem.displayTitle} > ${subSubSubItem.displayTitle}`) ? "active" : ""}>{subSubSubItem.title}</a>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            )}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : (
                                            <a href="#!" className={isActive(item.title) ? "active" : ""} onClick={(e) => { e.preventDefault(); handleCategoryClick(item.title); }}>
                                                <i className={`${item.icon} align-bottom me-2`}></i> <span className="file-list-link">{item.title}</span>
                                            </a>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </OffcanvasBody>
            </Offcanvas>
            {/* Sidebar for desktop view */}
            <div className="file-manager-sidebar d-none d-lg-block overflow-y-auto">
                <div className="p-3 d-flex flex-column h-100">
                    <div>
                        <h5 className="mb-0 fw-semibold">{parametersBackup && parametersBackup[connectionId] ? parametersBackup[connectionId].connection_name : ''}</h5>
                    </div>
                    {menuItems.length === 0 ? (
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <Spinner color="primary" />
                        </div>
                    ) : (
                        <div className="mt-3 file-menu-sidebar-scroll ps-3">
                            <ul className="list-unstyled file-manager-menu">
                                {menuItems.map((item: any, index: any) => (
                                    <li key={index}>
                                        {item.subItems ? (
                                            <>
                                                {item.isClickable ? (
                                                    <a href="#!" onClick={(e) => { e.preventDefault(); handleCategoryClick(item.title); }} className={isActive(item.title) ? "active" : ""}>
                                                        <i className={`${item.icon} align-bottom me-2`}></i> <span className="file-list-link">{item.title}</span>
                                                    </a>
                                                ) : (
                                                    <span>
                                                        <i className={`${item.icon} align-bottom me-2`}></i> <span className="file-list-link">{item.title}</span>
                                                    </span>
                                                )}
                                                <ul className="sub-menu list-unstyled ps-3">
                                                    {item.subItems.map((subItem: any, subIndex: any) => (
                                                        <li key={subIndex}>
                                                            <a href="#!" onClick={(e) => { e.preventDefault(); handleCategoryClick(item.title, subItem.displayTitle); }} className={isActive(`${item.title} > ${subItem.displayTitle}`) ? "active" : ""}>{subItem.title}</a>
                                                            {subItem.subItems && (
                                                                <ul className="list-unstyled ps-3">
                                                                    {subItem.subItems.map((subSubItem: any, subSubIndex: any) => (
                                                                        <li key={subSubIndex}>
                                                                            <a href="#!" onClick={(e) => { e.preventDefault(); handleCategoryClick(item.title, subItem.displayTitle, subSubItem.displayTitle); }} className={isActive(`${item.title} > ${subItem.displayTitle} > ${subSubItem.displayTitle}`) ? "active" : ""}>{subSubItem.title}</a>
                                                                            {subSubItem.subItems && (
                                                                                <ul className="list-unstyled ps-3">
                                                                                    {subSubItem.subItems.map((subSubSubItem: any, subSubSubIndex: any) => (
                                                                                        <li key={subSubSubIndex}>
                                                                                            <a href="#!" onClick={(e) => { e.preventDefault(); handleCategoryClick(item.title, subItem.displayTitle, subSubItem.displayTitle, subSubSubItem.displayTitle); }} className={isActive(`${item.title} > ${subItem.displayTitle} > ${subSubItem.displayTitle} > ${subSubSubItem.displayTitle}`) ? "active" : ""}>{subSubSubItem.title}</a>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            )}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : (
                                            <a href="#!" className={isActive(item.title) ? "active" : ""} onClick={(e) => { e.preventDefault(); handleCategoryClick(item.title); }}>
                                                <i className={`${item.icon} align-bottom me-2`}></i> <span className="file-list-link">{item.title}</span>
                                            </a>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <div className="file-manager-content w-100 p-3 py-0">
                <div className="mx-n3 pt-3 px-4 overflow-hidden">
                    <div id="folder-list" className="mb-2 d-block d-lg-none">
                        <Row className="justify-content-between g-2 mb-3 mb-4">
                            <Col>
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 me-2 d-block d-lg-none">
                                        <button type="button" className="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn" onClick={toggleOffcanvas}>
                                            <i className="ri-menu-2-fill align-bottom"></i>
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row id="folderlist-data"></Row>
                    </div>
                    <div>
                        <div className="d-flex align-items-center mb-3">
                            <h5 className="flex-grow-1 fs-16 mb-0" id="filetype-title">{parameterPath}</h5>
                        </div>
                        <ParameterTable
                            columns={allColumns || []}
                            data={parametersList || []}
                            isPagination={false}
                            isGlobalFilter={true}
                            isBordered={false}
                            customPageSize={30}
                            tableClass="table-centered align-middle table-nowrap mb-0"
                            theadClass="text-muted table-light"
                            SearchPlaceholder='Busca un parámetro...'
                            id={Number(id)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ParametersList;
