import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables, ChartOptions } from 'chart.js';
import streamingPlugin from 'chartjs-plugin-streaming';
import 'chartjs-adapter-luxon';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import vaconImage from '../../assets/images/help_graph_realtime.png';
import ModalHelp from 'Components/Common/Modals/ModalHelp';



Chart.register(...registerables, streamingPlugin);

const XAXISRANGE = 10000; // Ajusta este valor según sea necesario
const MAX_DATA_POINTS = 100; // Número máximo de puntos de datos para mantener

interface DataPoint {
  x: string;
  y: number | undefined;
}

interface Graph {
  name: string;
  data: DataPoint[];
  value: string;
  color: string;
  connection_id?: number;
}

interface ChartJSBasicRealtimeProps {
  inGraph?: Graph[];
  calculatedParameters?: any[];
}

const initializeData = (graphs: Graph[]) => {
  return graphs.map(graph =>
    graph.data ? graph.data.map(item => ({ x: new Date(item.x), y: item.y ?? 0 })) : [{ x: new Date(), y: 0 }]
  );
};

const ChartJSBasicRealtime: React.FC<ChartJSBasicRealtimeProps> = ({ inGraph = [], calculatedParameters = [] }) => {

  const selectParametersBackupState = (state: any) => state.ParametersBackup;

  const parametersBackupProperties = createSelector(
    selectParametersBackupState,
    (state) => state
  );

  const { parametersBackup } = useSelector(parametersBackupProperties);

  const chartRef = useRef<any>(null);
  const [data, setData] = useState<{ x: Date, y: number }[][]>(initializeData(inGraph));
  const [yMax, setYMax] = useState<number>(100);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const toggleHelpModal = () => setIsHelpModalOpen(!isHelpModalOpen);

  // Filtrar los parámetros calculados que tienen in_graph a true y tienen un valor de result
  const filteredParameters = calculatedParameters.filter(param => param.in_graph && param.result !== "");

  // Mapear los parámetros filtrados a la estructura esperada por el componente de la gráfica
  const mappedGraphs: Graph[] = filteredParameters.map((param) => ({
    name: `${param.name} - variable ${param.position}`,
    data: [], // Inicialmente vacío, se actualizará en el useEffect
    value: param.result,
    color: 'rgba(75,192,192,1)' // Color por defecto, puedes cambiar esto si es necesario
  }));

  // Combinar inGraph y mappedGraphs
  const combinedGraphs = [...inGraph, ...mappedGraphs];

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      setData(prevData => {
        return combinedGraphs.map((graph, index) => {
          const lastDataPoint = prevData[index]?.[prevData[index].length - 1];
          const lastValue = lastDataPoint ? lastDataPoint.y : 0;
          const newValue = graph.value !== undefined
            ? parseFloat(String(graph.value).replace(',', '.')) // Convertir a cadena antes del replace
            : lastValue;

          const newSeries = {
            x: currentTime,
            y: newValue
          };

          const updatedSeries = [...(prevData[index] || []), newSeries];

          if (updatedSeries.length > MAX_DATA_POINTS) {
            updatedSeries.shift();
          }

          // Calcular el nuevo valor máximo del eje Y con un 10% extra
          const maxYValue = Math.max(...updatedSeries.map(point => point.y));
          const newYMax = maxYValue * 1.1; // Agregar un 10% al valor máximo
          setYMax(newYMax);

          return updatedSeries;
        });
      });
    }, 1000); // Actualización cada segundo

    return () => clearInterval(interval);
  }, [combinedGraphs]);


  const onRefresh = (chart: Chart) => {
    const currentTime = Date.now();
    chart.data.datasets.forEach((dataset, index) => {
      dataset.data.push({
        x: currentTime,
        y: data[index]?.[data[index].length - 1]?.y || 0
      });
    });
  };

  const chartData = {
    datasets: combinedGraphs.map((graph, index) => {
      const connectionName = graph.connection_id ? parametersBackup[graph.connection_id]?.connection_name : '';
      const label = connectionName ? `${graph.name} - ${connectionName}` : graph.name;

      return {
        label: label,
        data: data[index] || [],
        backgroundColor: `${graph.color}80`, // Usar color con transparencia
        borderColor: graph.color, // Usar color especificado
        fill: false,
        pointRadius: 0
      };
    })
  };

  const chartOptions: ChartOptions<'line'> = {
    scales: {
      x: {
        type: 'realtime',
        realtime: {
          duration: XAXISRANGE,
          refresh: 1000, // Frecuencia de actualización a 1 segundo
          delay: 1000, // Retraso de 1 segundo
          onRefresh: onRefresh
        },
        ticks: {
          display: false // Ocultar los valores del eje X
        },
        grid: {
          display: true, // Mostrar líneas de la cuadrícula
          drawBorder: false
        }
      },
      y: {
        suggestedMin: 0,
        suggestedMax: yMax,
        ticks: {
          callback: function (value) {
            // Convertir a número y forzar que los valores se muestren con dos decimales
            return typeof value === 'number' ? value.toFixed(2) : Number(value).toFixed(2);
          }
        },
        title: {
          display: false // Ocultar la etiqueta del eje Y
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: true
        }
      },
      tooltip: {
        enabled: false // Deshabilitar tooltips
      }
    },
    maintainAspectRatio: false, // Importante: Mantener la relación de aspecto desactivada
    animation: {
      duration: 0 // Deshabilitar animaciones para actualizaciones en tiempo real
    },
    elements: {
      line: {
        tension: 0.4 // Suaviza las líneas de la gráfica
      }
    }
  };

  const noData = (
    !combinedGraphs ||
    (Array.isArray(combinedGraphs) && combinedGraphs.length === 0) ||
    (Array.isArray(combinedGraphs) && combinedGraphs.every(graph => graph && Array.isArray(graph.data) && graph.data.length === 0))
  );

  return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {noData ? (
        <div className="text-center my-5">
          <i className='ri-bar-chart-fill fs-1' />
          <h5 className="text-muted">No hay favoritos asociados</h5>
          <Button
            onClick={toggleHelpModal}
            style={{
              border: 'none',
              background: 'none',
              padding: 0,
              outline: 'none',
              boxShadow: 'none',
              marginLeft: '5px'
            }}
            className="text-muted fs-20  "
          >
            <i className=" mdi mdi-help-circle text-primary"></i>
          </Button>


        </div>
      ) : (
        <Line ref={chartRef} data={chartData} options={chartOptions} />
      )}

      <ModalHelp
        isOpen={isHelpModalOpen}
        toggle={toggleHelpModal}
        title="Ayuda de gráfica a tiempo real"
        body="Visualiza las variables que necesites en la gráfica a tiempo real habilitándola desde un favorito de visualización."
        buttonText="Cerrar"
        imageSrc={vaconImage}  // Pasar la imagen opcional
      />
    </div>
  );
};

export default ChartJSBasicRealtime;
