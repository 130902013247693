import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getRoles as getRolesApi,
  createRole as createRoleApi,
  updateRole as updateRoleApi,
  deleteRole as deleteRoleApi,
} from "../../helpers/fakebackend_helper";

export const getRoles = createAsyncThunk("role/getRoles", async () => {
  try {
    const response = await getRolesApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const createRole = createAsyncThunk("role/createRole", async (role: any) => {
  try {
    const response: any = await createRoleApi(role);
   
    if (response.id) {
      role.id = response.id;
    }
    return role;
  } catch (error) {
    return { 'error': error };
  }
});

export const updateRole = createAsyncThunk("role/updateRole", async (role: any) => {
  try {
    const response = await updateRoleApi(role);
    return role;
  } catch (error) {
    return error;
  }
});



export const deleteRole = createAsyncThunk("installation/deleteInstallation", async (role: any) => {
  try {
    const response = await deleteRoleApi(role);
    return role;
  } catch (error) {
    return error;
  }
});