import React from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, CardTitle, Button } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useNavigate } from 'react-router-dom';

const NoZones = () => {
    document.title = "DashBoard | Ecentroserver";
    const navigate = useNavigate();

    const handleCreateZone = () => {
        navigate('/instalaciones'); // Puedes ajustar esta ruta según tu estructura de rutas
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <Card className="text-center shadow-lg mt-4">
                                <CardHeader className="text-white">
                                    <CardTitle tag="h4">No se ha creado ninguna zona o todas las zonas están inactivas</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <p className="lead">
                                        Actualmente no se ha creado ninguna zona o todas las zonas están inactivas. Por favor, crea una nueva zona o actívalas. Para acceder a las zonas tendrás que ir al apartado de instalaciones y hacer click en el siguiente icono    <i className="bx bx-home fs-5"></i>.
                                    </p>
                                    <Button color="primary" onClick={handleCreateZone}>
                                        Ir a instalaciones
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default NoZones;
