import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Form, Input, Label, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from '../ParticlesAuth';
import logoELC from "../../../assets/images/ELC_logo.svg";
import logoDanfoss from "../../../assets/images/Danfoss-Logo.png";
import { changeUserPassword } from 'slices/users/thunk';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

const BasicPasswCreate = () => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    document.title = "Crear nueva contraseña | Ecentroserver";

    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(""); // Inicializamos error como una cadena vacía
    const [successMessage, setSuccessMessage] = useState(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            password: "",
            confirm_password: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(8, 'La contraseña debe tener al menos 8 caracteres.')
                .matches(RegExp('(.*[a-z].*)'), 'Al menos una letra minúscula')
                .matches(RegExp('(.*[A-Z].*)'), 'Al menos una letra mayúscula')
                .matches(RegExp('(.*[0-9].*)'), 'Al menos un número')
                .required("Este campo es requerido"),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password'), ""], 'Las contraseñas deben coincidir')
                .required('Confirmar contraseña es requerido')
        }),
        onSubmit: async (values, { setSubmitting }) => {
            if (token) {
                setIsSubmitting(true);

                try {
                    const response = await dispatch(changeUserPassword({ password: values.password, token: token }));

                    if (response.payload.success) {
                        setSuccessMessage(response.payload.message);
                        setTimeout(() => {
                            navigate('/login');
                        }, 3000);
                    } else if (response.payload.error) {

                        setError(response.payload.error);
                    }
                } catch (error) {
                    setError("Hubo un error al cambiar la contraseña. Por favor, inténtelo de nuevo.");
                } finally {
                    setIsSubmitting(false);
                    setSubmitting(false); // Asegura que formik deje de estar en estado de envío
                }
            } else {
                setError("Falta el token de autorización.");
                setSubmitting(false); // Asegura que formik deje de estar en estado de envío
            }
        }
    });

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">

                                        <Row>
                                            <Col lg={12}>
                                                <div className="text-center mb-4 text-white-50">
                                                    <div>
                                                        <Link to="/" className="d-inline-block auth-logo d-flex justify-content-around">
                                                            <img
                                                                src={logoELC}
                                                                alt="Logo ELC"
                                                                className="logo-elc"
                                                            />
                                                            <img
                                                                src={logoDanfoss}
                                                                alt="Logo Danfoss"
                                                                className="logo-danfoss mt-auto"
                                                            />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Crear nueva contraseña</h5>
                                        </div>

                                        <div className="p-2">
                                            {error && <Alert color="danger">{error}</Alert>}
                                            {successMessage && <Alert color="success">{successMessage}</Alert>}
                                            <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); }}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="password-input">Contraseña</Label>
                                                    <div className="position-relative auth-pass-inputgroup">
                                                        <Input
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5 password-input"
                                                            placeholder="Introduce la contraseña"
                                                            id="password-input"
                                                            name="password"
                                                            value={validation.values.password}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            invalid={!!(validation.errors.password && validation.touched.password)}
                                                        />
                                                        {validation.errors.password && validation.touched.password && (
                                                            <FormFeedback>{validation.errors.password}</FormFeedback>
                                                        )}
                                                        <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                            id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                    </div>
                                                    <div id="passwordInput" className="form-text">Debe tener al menos 8 caracteres.</div>
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="confirm-password-input">Confirmar contraseña</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            type={confirmPasswordShow ? "text" : "password"}
                                                            className="form-control pe-5 password-input"
                                                            placeholder="Confirma la contraseña"
                                                            id="confirm-password-input"
                                                            name="confirm_password"
                                                            value={validation.values.confirm_password}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            invalid={!!(validation.errors.confirm_password && validation.touched.confirm_password)}
                                                        />
                                                        {validation.errors.confirm_password && validation.touched.confirm_password && (
                                                            <FormFeedback>{validation.errors.confirm_password}</FormFeedback>
                                                        )}
                                                        <Button color="link" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button">
                                                            <i className="ri-eye-fill align-middle"></i></Button>
                                                    </div>
                                                </div>

                                                <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                    <h5 className="fs-13">La contraseña debe contener al menos:</h5>
                                                    <p id="pass-length" className="invalid fs-12 mb-2">Mínimo <b>8 caracteres</b></p>
                                                    <p id="pass-lower" className="invalid fs-12 mb-2">Al menos una letra <b>minúscula</b> (a-z)</p>
                                                    <p id="pass-upper" className="invalid fs-12 mb-2">Al menos una letra <b>mayúscula</b> (A-Z)</p>
                                                    <p id="pass-number" className="invalid fs-12 mb-0">Al menos un <b>número</b> (0-9)</p>
                                                </div>

                                                <div className="mt-4">
                                                    <Button color="success" className="w-100" type="submit" disabled={isSubmitting}>
                                                        {isSubmitting ? "Enviando..." : "Resetea la contraseña"}
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default BasicPasswCreate;
