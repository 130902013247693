import { createAsyncThunk } from "@reduxjs/toolkit";

// Importa el método que realiza la llamada API
import { 
  getSynopticImageConfigByZone as getSynopticImageConfigByZoneApi, 
} from "../../helpers/fakebackend_helper";


export const getSynopticImageConfigByZone = createAsyncThunk("synopticImageConfig/getSynopticImageConfigByZone", async (zoneId: number) => {
  try {
    
    const response = await getSynopticImageConfigByZoneApi(zoneId);

    return {zoneId: zoneId, data: response};
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});


