import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import DraggableDevices from './DraggableDevices';
import { reorderInstallationZones } from 'slices/thunks';
import { useDispatch } from 'react-redux';
import { getItemStyle, getListStyle } from '.';

const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

interface zones {
    initialZones: any;
    props: any;
    installationId: number;
    installationIndex: number
}

const DraggableZones = ({ initialZones, props, installationId, installationIndex }: zones) => {
    const dispatch: any = useDispatch();
    const [zones, setZones] = useState(initialZones);

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        const reorderNewZones = reorder(zones, result.source.index, result.destination.index);
        const newZones = reorderNewZones.map((zone: any) => {
            const connections = zone.childItems
                .filter((item: any) => item.id !== "createConnection")
                .flatMap((item: any) => {
                    if (item.childItems) {
                        return item.childItems.map(({ id, label: name, img, is_active }: any) => ({ id, name, img, is_active }));
                    }
                    return [];
                });

            return {
                id: zone.id,
                installation_id: zone.installation_id,
                installation_name: zone.installation_name,
                is_active: zone.is_active,
                zone_description: zone.zone_description,
                zone_name: zone.zone_name,
                connections: connections
            };
        });

        dispatch(reorderInstallationZones(newZones));
        setZones(reorderNewZones);
    };

    useEffect(() => {
        setZones(initialZones);
    }, [initialZones]);

    return (
        <div>
            {/* {zones.length > 0 && (
                <ul className='nav nav-sm flex-column'>
                    <li className="nav-item">
                        <NavLink
                            to={`/dashboard/instalacion/${installationId}`}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending nav-link " : isActive ? " nav-link active" : "nav-link"
                            }
                        >
                            {props.t('Dashboard')}
                        </NavLink>
                    </li>
                </ul>
            )} */}
            {zones.length > 0 ? (
                <>
                    <ul className='nav nav-sm flex-column'>
                        <li className="nav-item">
                            <NavLink
                                to={`/dashboard/instalacion/${installationId}`}
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link " : isActive ? "nav-link active" : "nav-link"
                                }
                                end
                            >
                                {props.t('Dashboard General')}
                            </NavLink>
                        </li>
                    </ul>
                    <ul className='nav nav-sm flex-column'>
                        <li className="nav-item">
                            <NavLink
                                to={`/instalacion/registros/${installationId}`}
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link " : isActive ? "nav-link active" : "nav-link"
                                }
                                end
                            >
                                {props.t('Registro de la instalación')}
                            </NavLink>
                        </li>
                    </ul>
                </>
            ) : (
                <div className="alert alert-danger text-center" role="alert">
                    {props.t('No hay zonas activas o creadas para esta instalación')}
                </div>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-nav">
                    {(provided: any, snapshot: any) => (
                        <ul ref={provided.innerRef} {...provided.droppableProps} className='nav nav-sm flex-column' style={getListStyle(snapshot.isDraggingOver)}>
                            {zones && zones.map((subItem: any, index: any) => (
                                <Draggable key={subItem.id} draggableId={subItem.id.toString()} index={index} isDragDisabled={subItem.stateVariables}>
                                    {(provided: any, snapshot: any) => (
                                        <li
                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            className="nav-item">
                                            {!subItem.isChildItem ? (
                                                <>
                                                    <NavLink
                                                        to={subItem.link ? subItem.link : "/#"}
                                                        className={({ isActive, isPending }) =>
                                                            isPending ? "pending nav-link " : isActive ? " nav-link active" : "nav-link"
                                                        }
                                                    >
                                                        {props.t(subItem.label)}
                                                        {subItem.badgeName &&
                                                            <span className={"badge badge-pill bg-" + subItem.badgeColor} data-key="t-new">{subItem.badgeName}</span>
                                                        }
                                                    </NavLink>
                                                </>
                                            ) : (
                                                <>
                                                    <NavLink
                                                        onClick={subItem.click}
                                                        className={({ isActive, isPending }) =>
                                                            isPending ? "pending nav-link " : isActive ? "nav-link  active" : "nav-link menu-link"
                                                        }
                                                        to={subItem.link ? subItem.link : "/#"}
                                                        data-bs-toggle="collapse"
                                                        aria-expanded={subItem.stateVariables}
                                                    >
                                                        {`Z${(index + 1) + " .- " + props.t(subItem.label)}`}
                                                    </NavLink>
                                                    <Collapse
                                                        className="menu-dropdown"
                                                        isOpen={subItem.stateVariables}
                                                        id="sidebarDevices">
                                                        <DraggableDevices initialDevices={subItem.childItems} props={props} zoneId={subItem.id} installationId={installationId} />
                                                    </Collapse>
                                                </>
                                            )}
                                        </li>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default DraggableZones;
