import { createAsyncThunk } from "@reduxjs/toolkit";

// Importa el método que realiza la llamada API
import {
  getZoneEmailByUser as getZoneEmailByUserApi,
  createZoneEmailByUser as createZoneEmailByUserApi,
  deleteZoneEmailByUser as deleteZoneEmailByUserApi

} from "../../helpers/fakebackend_helper";

// Define el createAsyncThunk para getFaultHistories
export const getZoneEmailByUser = createAsyncThunk("zoneEmail/getZoneEmailByUser", async (userId: number) => {

  try {

    const response = await getZoneEmailByUserApi(userId);
  
    return response;
  } catch (error: any) {

    return { error: error.message || 'An error occurred' };
  }
});

export const createZoneEmailByUser = createAsyncThunk("zoneEmail/createZoneEmailByUser", async (zoneEmail: any) => {
  try {
    const response : any = await createZoneEmailByUserApi(zoneEmail);

    if (response && response.created && response.id) {
      return { ...zoneEmail, id: response.id };
    }
    return response;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});

export const deleteZoneEmailByUser = createAsyncThunk("zoneEmail/deleteZoneEmailByUser", async (zoneEmail: any) => {
  try {
    const response = await deleteZoneEmailByUserApi(zoneEmail);
    return zoneEmail;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});
