import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Card, CardBody, CardTitle, Row, Modal, ModalHeader, ModalBody, Input, FormGroup, Label, Spinner, Button, ModalFooter } from 'reactstrap';
import LinkedParamsTooltip from 'Components/Common/Tooltips/LinkedParamsTooltip';
import { useDispatch } from 'react-redux';
import { reorderMainVariables } from 'slices/mainVariables/thunk';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import ModalHelp from 'Components/Common/Modals/ModalHelp';

type FavoriteItem = {
  id: string;
  name: string;
  unit: string;
  value?: string;
  isInChart: boolean;
  color: string;
  tooltipOpen?: boolean;
  connection_id: number;
  favorite_id: number;
  favorite_parameter_id: number;
};

type MainVariable = {
  id: number;
  name: string;
  unit: string;
  value?: string;
  favorite_id: number;
  position: number;
  color: string;
  connection_id: number;
  connection_name: string;
  favorite_parameter_id: number;
  parameter_index: string;
};

type Props = {
  mainVariables: MainVariable[];
};

const FavoritesDragDropList: React.FC<Props> = ({ mainVariables }) => {
  const [favorites, setFavorites] = useState<FavoriteItem[]>([]);
  const [tooltipState, setTooltipState] = useState<{ [key: string]: boolean }>({});
  const dispatch: any = useDispatch();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const toggleHelpModal = () => setIsHelpModalOpen(!isHelpModalOpen);

  const selectParameterValuesState = (state: any) => state.ParameterValues;

  const parameterValuesDataProperties = createSelector(
    selectParameterValuesState,
    (state: any) => state
  );

  const { parameterValues } = useSelector(parameterValuesDataProperties);

  useEffect(() => {
    const initialFavorites = mainVariables.map(mv => {
      const existingFavorite = favorites.find(fav => fav.id === mv.id.toString());
      return {
        id: mv.id.toString(),
        name: `${mv.connection_name}-${mv.parameter_index}-${mv.name}`,
        unit: mv.unit,
        value: mv.value,
        isInChart: true,
        color: mv.color,
        connection_id: mv.connection_id,
        tooltipOpen: existingFavorite ? existingFavorite.tooltipOpen : false,
        favorite_id: mv.favorite_id,
        favorite_parameter_id: mv.favorite_parameter_id
      };
    });

    setFavorites(initialFavorites);

  }, [mainVariables]);

  const [modal, setModal] = useState(false);
  const [selectedFavorite, setSelectedFavorite] = useState<FavoriteItem | null>(null);

  const toggleFavoriteTooltip = (id: string) => {
    setTooltipState(prevState => {
      const newState = { ...prevState };
      Object.keys(newState).forEach(key => {
        newState[key] = false;
      });
      newState[id] = !prevState[id];
      return newState;
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedFavorite) {
      setSelectedFavorite({
        ...selectedFavorite,
        [event.target.name]: event.target.name === 'isInChart' ? event.target.checked : event.target.value
      });
    }
  };

  const toggleModal = () => setModal(!modal);

  const selectFavorite = (favorite: FavoriteItem) => {
    setSelectedFavorite(favorite);
    toggleModal();
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(favorites);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFavorites(items);

    const orderedIds = items.map(item => Number(item.id));

    dispatch(reorderMainVariables({ reorderedItems: orderedIds }));
  };

  const handleFavoriteClick = (favorite: FavoriteItem) => {
    selectFavorite(favorite);
  };

  return (
    <div className="favorites-drag-drop-list h-100">
      <Card className="mh-100 h-100 mb-0">
        <CardTitle className='text-center p-1 d-flex align-items-center'>
          VARIABLES PRINCIPALES
          <Button
            onClick={toggleHelpModal}
            style={{
              border: 'none',
              background: 'none',
              padding: 0,
              outline: 'none',
              boxShadow: 'none',
              marginLeft: '5px'
            }}
            className="text-muted fs-20  "
          >
            <i className=" mdi mdi-help-circle text-primary"></i>
          </Button>
        </CardTitle>
        <CardBody className='m-0 mt-1' style={{ maxHeight: '800px', overflowY: 'auto', paddingTop: '0px', paddingBottom: '0px' }}>
          {favorites.length === 0 ? (
            <div className="text-center my-5">
              <i className='mdi mdi-star-off-outline fs-2' />
              <h5 className="text-muted">No hay favoritos asociados</h5>
            </div>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-favorites">
                {(provided: any) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {favorites.map(({ id, name, unit, value, isInChart, color, connection_id, favorite_id, favorite_parameter_id }, index) => {
                      const tooltipOpen = tooltipState[id] || false; // Obtener el estado del tooltip del nuevo estado
                      return (
                        <Draggable key={id} draggableId={id.toString()} index={index}>
                          {(provided: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Row className='bg-light mb-1' id={`favorite-${id}`} onClick={() => toggleFavoriteTooltip(id)}>
                                <div className="d-flex align-items-center" style={{ backgroundColor: color, padding: '2px', borderRadius: '4px', width: '100%' }}>
                                  <span className='m-0 text-truncate' style={{ flex: '0 1 75%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {name}
                                  </span>
                                  <span className='m-0 text-center' style={{ flex: '0 1 25%', minWidth: '60px', marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {value !== undefined && value !== null && value !== "" ? (
                                      <>

                                        <span>
                                          {
                                            parameterValues[connection_id]?.[favorite_parameter_id]?.find((parameterValue: any) =>
                                              parameterValue.value === value
                                            )?.textValue ?? value
                                          }
                                        </span>
                                        <span style={{ marginLeft: '5px', fontSize: '0.8rem' }}>{unit}</span>
                                      </>
                                    ) : <Spinner size="sm" color="primary" />}
                                  </span>
                                </div>
                              </Row>
                              {tooltipOpen && (
                                <LinkedParamsTooltip
                                  target={`favorite-${id}`}
                                  isOpen={!!tooltipOpen}
                                  toggle={() => toggleFavoriteTooltip(id)}
                                  connection_id={connection_id}
                                  favorite_id={favorite_id}
                                />
                              )}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Detalle del Favorito</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Nombre</Label>
            <Input type="text" name="name" value={selectedFavorite?.name} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Label>Color de fondo</Label>
            <Input type="select" name="color" value={selectedFavorite?.color} onChange={handleChange}>
              <option value="#FF0000">Rojo</option>
              <option value="#0000FF">Azul</option>
            </Input>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" name="isInChart" checked={selectedFavorite?.isInChart} onChange={handleChange} />{' '}
              Añadir a la gráfica
            </Label>
          </FormGroup>
        </ModalBody>
      </Modal>
      <ModalHelp
        isOpen={isHelpModalOpen}
        toggle={toggleHelpModal}
        title="Ayuda de gráfica a tiempo real"
        body="Visualiza las variables que necesites en la gráfica a tiempo real habilitándola desde un favorito de visualización."
        buttonText="Cerrar"

      />
    </div >
  );
};

export default FavoritesDragDropList;
