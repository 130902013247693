import TableContainer from 'Components/Common/TableContainerReactTable';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { deleteUser, getUsers, resetUserPassword } from 'slices/users/thunk';
import rolesIds from 'constants/roles';
import ModalConfirm from 'Components/Common/Modals/ModalConfirm';
import { ToastContainer, toast } from 'react-toastify';
import ModalUser from './ModalAlarmsAndFaults';
import { set } from 'lodash';
import UserZoneConfigModal from './userZoneConfigModal';
import { deleteCustomAlarmOrFault, getAlarmsAndFaultsByConnection } from 'slices/AlarmsAndFaults/thunk';
import ModalAlarmsAndFaults from './ModalAlarmsAndFaults';

const AlarmsAndFaultsList = () => {

    const dispatch: any = useDispatch();

    const selectUsersState = (state: any) => state.Users;
    const selectRolesState = (state: any) => state.Roles;
    const selectLayoutState = (state: any) => state;
    const selectLoginState = (state: any) => state.Login;
    const selectAlarmsAndFaultsState = (state: any) => state.AlarmsAndFaults;

    const alarmOrFaultDataProperties = createSelector(
        selectUsersState,
        (state: any) => state
    );

    const roleDataProperties = createSelector(
        selectRolesState,
        (state: any) => state
    );

    const layoutDataProperties = createSelector(
        selectLayoutState,
        (state: any) => state
    );

    const loginDataProperties = createSelector(
        selectLoginState,
        (state: any) => state
    );

    const zonesProperties = createSelector(
        selectLoginState,
        (state: any) => state
    );

    const alarmsAndFaultsProperties = createSelector(
        selectAlarmsAndFaultsState,
        (state: any) => state
    );

    const state = useSelector(layoutDataProperties);
    const { users } = useSelector(alarmOrFaultDataProperties);
    const { roles } = useSelector(roleDataProperties);
    const { user } = useSelector(loginDataProperties);
    const { zones } = useSelector(zonesProperties);
    const { alarmsAndFaults } = useSelector(alarmsAndFaultsProperties);
    const { id } = useParams();



    const [modal, setModal] = useState(false);
    const [modalZones, setModalZones] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [alarmOrFaultData, setUserData] = useState<any>({});
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);



    useEffect(() => {
        dispatch(getAlarmsAndFaultsByConnection(Number(id)));
    }, [dispatch, id]);

    const toggle = () => {
        setModal(!modal);
    }

    const toggleModalZones = () => {
        setModalZones(!modalZones);
    }

    const toggleModalAndSetEditing = (alarmOrFault: any) => {
        setUserData(alarmOrFault);
        setIsEditing(true);
        toggle();
    }

    const handleEditAlarmOrFault = (alarmOrFault: any) => {
        toggleModalAndSetEditing(alarmOrFault);
    }

    const configureZonesbyUser = (user: any) => {
        setUserData(user);
        toggleModalZones();
    }

    useEffect(() => {
        if (!modal) {
            setUserData(null);
            setIsEditing(false);
        }
    }, [modal]);

    const toggleDeleteConfirmationModal = () => {
        setDeleteConfirmationModal(!deleteConfirmationModal);
    }

    const handleConfirmDeleteAlarmOrFault = async (alarmOrFaultId: number | null) => {
        setDeleteConfirmationModal(false);

        try {
            if (alarmOrFaultId) {
                const response = await dispatch(deleteCustomAlarmOrFault(alarmOrFaultId)).unwrap();

                if (!response.error) {
                    toast.success('¡Alarma o fallo eliminado con éxito!');
                    setUserData(null);
                } else {
                    toast.error('Error al eliminar el fallo o alarma');
                }
            }
        } catch (error: any) {
            toast.error('Error al eliminar al usuario');
        }
    }

    const handleCancelDeleteAlarmOrFault = () => {
        setUserData(null);
        setDeleteConfirmationModal(false);
    }

    const deleteUserById = (user: any) => {
        setUserData(user);
        toggleDeleteConfirmationModal();
    }

    const allColumns = useMemo(() => {
        // Define las columnas base
        let columns = [
            {
                Header: "Nombre personalizado",
                accessor: "custom_fault_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Nombre de Fallo o alarma",
                accessor: "fault_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Tipo",
                accessor: "fault_type",
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Estado",
                accessor: "is_active",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }: any) => (
                    <div>
                        {value === true ? <i className="mdi mdi-check-bold text-success fs-3"></i> : <i className="mdi mdi-close-thick text-danger fs-3"></i>}
                    </div>
                ),
            },
            {
                Header: "Acciones",
                accessor: "actions",
                disableFilters: true,
                filterable: false,
                Cell: ({ row }: any) => (
                    <div>
                        <button onClick={() => handleEditAlarmOrFault(row.original)} className="btn btn-sm me-2" title="Configurar fallo o alarma">
                            <i className="ri-settings-4-fill fs-5"></i>
                        </button>

                        <button onClick={() => deleteUserById(row.original)} className="btn btn-danger btn-sm" title="Borrar">
                            <i className="mdi mdi-delete fs-5"></i>
                        </button>
                    </div>
                ),
            }
        ];

        return columns;

    }, [user.role_id]);

    const handleUserCreatedOrEdited = (createdUserOrEdited: boolean, isEditing: boolean) => {
        if (createdUserOrEdited && !isEditing) {
            toast.success('Alarma o fallo creado con éxito!');
        }
        if (!createdUserOrEdited && !isEditing) {
            toast.error('Error al crear la alarma o fallo!');
        }
        if (createdUserOrEdited && isEditing) {
            toast.success('Alarma o fallo editado con éxito!');
        }
        if (!createdUserOrEdited && isEditing) {
            toast.error('Error al editar la alarma o fallo!');
        }
    }

    return (
        <React.Fragment >
            <TableContainer
                columns={(allColumns || [])}
                data={(alarmsAndFaults || [])}
                isPagination={true}
                isGlobalFilter={true}
                isBordered={false}
                customPageSize={9}
                className="custom-header-css table align-middle table-nowrap"
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                SearchPlaceholder='Busca una alarma o fallo...'
                button={true}
                buttonName={"Añadir fallo o alarma"}
                toogleModal={toggle}
            />
            <ModalAlarmsAndFaults
                isOpen={modal}
                toggle={toggle}
                isEditing={isEditing}
                alarmOrFaultData={alarmOrFaultData}
                onUserCreatedorEdited={handleUserCreatedOrEdited}
            />

            <ModalConfirm
                isOpen={deleteConfirmationModal}
                toggle={toggleDeleteConfirmationModal}
                title={"Borrar el fallo o alarma"}
                message={`¿Estás seguro de que quieres borrar ${alarmOrFaultData && alarmOrFaultData.name} ?`}
                onConfirm={handleConfirmDeleteAlarmOrFault}
                onCancel={handleCancelDeleteAlarmOrFault}
                idSelected={alarmOrFaultData && alarmOrFaultData.id}
                iconName='mdi mdi-delete display-5 text-danger'
            />
            {/*   {alarmOrFaultData ?
                <UserZoneConfigModal
                    isOpen={modalZones}
                    toggle={toggleModalZones}
                    alarmOrFaultData={alarmOrFaultData}
                />
                : null} */}
        </React.Fragment >
    );
};

export default AlarmsAndFaultsList;
