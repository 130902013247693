import { createAsyncThunk } from "@reduxjs/toolkit";

// Importa el método que realiza la llamada API
import { 
  getStartupRegistrationByZone as getStartupRegistrationByZoneApi, 
} from "../../helpers/fakebackend_helper";


export const getStartupRegistrationByZone = createAsyncThunk("startupRegistration/getStartupRegistrationByZone", async (zoneId: number) => {
  try {
    
    const response = await getStartupRegistrationByZoneApi(zoneId);

    return response;
  } catch (error: any) {
    return { error: error.message || 'An error occurred' };
  }
});


