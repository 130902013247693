import React from 'react';

const StatusIndicator = ({ section }: { section: string }) => {
  const statuses = ['Listo', 'Marcha', 'Fallo', 'Alarma'];

  return (
    <div className={`status-container ${section}`}>
      {statuses.map((status) => (
        <div className="status-indicator" key={status}>
          <div className={`led ${status}`}></div>
          <div className="status-label">{status}</div>
        </div>
      ))}
    </div>
  );
};

export default StatusIndicator;