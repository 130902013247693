import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

//Calendar
import CalendarReducer from "./calendar/reducer";
//Chat
import chatReducer from "./chat/reducer";
//Ecommerce
import EcommerceReducer from "./ecommerce/reducer";

//Project
import ProjectsReducer from "./projects/reducer";

// Tasks
import TasksReducer from "./tasks/reducer";

//Crypto
import CryptoReducer from "./crypto/reducer";

//TicketsList
import TicketsReducer from "./tickets/reducer";


//Invoice
import InvoiceReducer from "./invoice/reducer";

//Mailbox
import MailboxReducer from "./mailbox/reducer";

// Dashboard Analytics
import DashboardAnalyticsReducer from "./dashboardAnalytics/reducer";

// Dashboard CRM
import DashboardCRMReducer from "./dashboardCRM/reducer";

// Dashboard Ecommerce
import DashboardEcommerceReducer from "./dashboardEcommerce/reducer";

// Dashboard Cryto
import DashboardCryptoReducer from "./dashboardCrypto/reducer";

// Dashboard Cryto
import DashboardProjectReducer from "./dashboardProject/reducer";

// Dashboard NFT
import DashboardNFTReducer from "./dashboardNFT/reducer";

// Pages > Team
import TeamDataReducer from "./team/reducer";

// File Manager
import FileManagerReducer from "./fileManager/reducer";

// To do
import TodosReducer from "./todos/reducer";

// Job
import JobReducer from "./jobs/reducer";

// API Key
import APIKeyReducer from "./apiKey/reducer";

//Installations
import InstallationsReducer from "./installations/reducer";

//InstallationZones
import InstallationZonesReducer from "./installationZones/reducer";

//Users
import UsersReducer from "./users/reducer";

//Devices
import DevicesReducer from "./devices/reducer";

//Favorites
import FavoritesReducer from "./favorites/reducer";

//Registers
import RegistersReducer from "./registers/reducer";

//Parameters
import ParametersReducer from "./parameters/reducer";

//Parameters Backup
import ParametersBackupReducer from "./parametersBackup/reducer";

//CalculatedParameters
import CalculatedParametersReducer from "./calculatedParameters/reducer";

//ParameterValues
import ParameterValuesReducer from "./parameterValues/reducer";

//Connections
import ConnectionsReducer from "./connections/reducer";

//Roles
import RolesReducer from "./roles/reducer";

//Company
import CompanyReducer from "./companies/reducer";

//Company
import FaultHistoryReducer from "./faultHistory/reducer";

//AlarmHistory
import AlarmHistoryReducer from "./alarmHistory/reducer";


//ZoneEmail
import ZoneEmailReducer from "./zoneEmail/reducer";

//AlarmsAndFaults
import AlarmsAndFaultsReducer from "./AlarmsAndFaults/reducer";

//Faults (And alarms)
import FaultsReducer from "./faults/reducer";

//Status
import StatusReducer from "./status/reducer";

//Status
import StatusZoneReducer from "./statusZone/reducer";

//startupRegistration
import StartupRegistrationReducer from "./startupRegistration/reducer";

//InstallationCalculatedParameters
import installationCalculatedParametersReducer from "./installationCalculatedParameters/reducer";

//InstallationRegisters
import installationRegistersReducer from "./installationRegisters/reducer";

//SynopticImageConfig
import SynopticImageReducer from "./synopticImageConfig/reducer";

//SectionDataConfig
import SynopticDataReducer from "./sectionDataConfig/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Calendar: CalendarReducer,
    Installations: InstallationsReducer,
    InstallationZones: InstallationZonesReducer,
    Users: UsersReducer,
    Devices: DevicesReducer,
    Parameters: ParametersReducer,
    ParametersBackup: ParametersBackupReducer,
    ParameterValues: ParameterValuesReducer,
    CalculatedParameters: CalculatedParametersReducer,
    Connections: ConnectionsReducer,
    Faults: FaultsReducer,
    Roles: RolesReducer,
    Companies: CompanyReducer,
    Favorites: FavoritesReducer,
    Registers: RegistersReducer,
    Chat: chatReducer,
    FaultHistory: FaultHistoryReducer,
    AlarmHistory: AlarmHistoryReducer,
    AlarmsAndFaults: AlarmsAndFaultsReducer,
    ZoneEmail: ZoneEmailReducer,
    StatusZone: StatusZoneReducer,
    StartupRegistration: StartupRegistrationReducer,
    Projects: ProjectsReducer,
    Ecommerce: EcommerceReducer,
    Tasks: TasksReducer,
    Crypto: CryptoReducer,
    Tickets: TicketsReducer,
    Invoice: InvoiceReducer,
    Mailbox: MailboxReducer,
    DashboardAnalytics: DashboardAnalyticsReducer,
    DashboardCRM: DashboardCRMReducer,
    DashboardEcommerce: DashboardEcommerceReducer,
    DashboardCrypto: DashboardCryptoReducer,
    DashboardProject: DashboardProjectReducer,
    DashboardNFT: DashboardNFTReducer,
    Team: TeamDataReducer,
    FileManager: FileManagerReducer,
    Todos: TodosReducer,
    Jobs: JobReducer,
    APIKey: APIKeyReducer,
    Status: StatusReducer,
    InstallationCalculatedParameters: installationCalculatedParametersReducer,
    InstallationRegisters: installationRegistersReducer,
    SynopticImageConfig: SynopticImageReducer,
    SectionDataConfig: SynopticDataReducer
});

export default rootReducer;