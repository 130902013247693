import { createSlice } from "@reduxjs/toolkit";
import {
  createFavorite,
  getFavoritesByConnectionId,
  deleteFavorite,
  updateFavorite,
  reorderFavorites,
  getDashboardInfo,
  getLinkedFavorites,
  createLinkedParameter,
  deleteLinkedParameter,
  getLinkedVariablesByConnection,
  resetFavoriteSelected
} from "./thunk";
import { act } from "react-dom/test-utils";



export const initialState: any = {
  favorites: [],
  dashBoardInfo: {},
  linkedFavorites: [],
  linkedFavoritesByConnection: [],
  favoriteIdSelected: null,
  isFetchingFavorites: {}
};


const parameterValuesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    /* createFavorite: (state, action) => {
      const { favorite_id, name, min_scale, max_scale, connection_id} = action.payload;

      const favorite = state.favorites.find((favorite: any) => favorite.id === favorite_id);

      console.log("favorite found: ", favorite)
     
    }, */
    setFetchingFavorites: (state, action) => {
      const { connection_id, isFetching } = action.payload;
      state.isFetchingFavorites[connection_id] = isFetching;
    },
    setFilteredFavorite: (state, action) => {
      state.filteredFavorite = action.payload;
    },

    setSelectedFavoriteId: (state, action) => {
      state.favoriteIdSelected = action.payload;
    },

    setDashBoardInfo: (state, action) => {
      state.dashBoardInfo = action.payload;
    },

    updateDashboardValues: (state, action) => {
      const { values } = action.payload;
      values.forEach(({ parameter_id, value, bit_parameter_id, idmap_id, connection_id }: any) => {
        if (state.dashBoardInfo.favorites) {
          const favorite = state.dashBoardInfo.favorites.find((fav: any) => {
            return fav.parameter_id == parameter_id &&
              fav.bit_parameter_id == bit_parameter_id &&
              ((fav.idmap_id == idmap_id) || (fav.idmap_id === null && idmap_id === "") || (fav.idmap_id === "" && idmap_id === null)) &&
              fav.connection_id == connection_id;
          });
          if (favorite) {
            favorite.value = value;
          }
        }

        if (state.dashBoardInfo.main_variables) {
          const mainVariable = state.dashBoardInfo.main_variables.find((mv: any) => {
            return mv.bit_parameter_id == bit_parameter_id && Number(mv.parameter_id) == Number(parameter_id) &&
              ((mv.idmap_id == idmap_id) || (mv.idmap_id === null && idmap_id === "") || (mv.idmap_id === "" && idmap_id === null)) &&
              mv.connection_id == connection_id;
          });
          if (mainVariable) {
            mainVariable.value = value;
          }
        }

        if (state.dashBoardInfo.graph_favorites) {
          const graphFavorite = state.dashBoardInfo.graph_favorites.find((gf: any) => {
            return gf.parameter_id == parameter_id &&
              gf.bit_parameter_id == bit_parameter_id &&
              ((gf.idmap_id == idmap_id) || (gf.idmap_id === null && idmap_id === "") || (gf.idmap_id === "" && idmap_id === null)) &&
              gf.connection_id == connection_id;
          });
          if (graphFavorite) {

            graphFavorite.value = value;

          }
        }

        if (state.dashBoardInfo.status) {
          const status = state.dashBoardInfo.status[connection_id];
          if (status) {
            ['run', 'ready', 'fault', 'warning'].forEach((statusType) => {
              const statusRegister = status[statusType];
              if (statusRegister &&
                statusRegister.parameter_id == parameter_id &&
                statusRegister.bit_parameter_id == bit_parameter_id &&
                ((statusRegister.idmap_id == idmap_id) || (statusRegister.idmap_id === null && idmap_id === "") || (statusRegister.idmap_id === "" && idmap_id === null))
              ) {
                statusRegister.value = value;
              }
            });
          }
        }

        if (state.dashBoardInfo.ip_registers) {
          Object.keys(state.dashBoardInfo.ip_registers).forEach((ip) => {
            const register = state.dashBoardInfo.ip_registers[ip].find((reg: any) => {
              return reg.parameter_id == parameter_id &&
                reg.bit_parameter_id == bit_parameter_id &&
                ((reg.idmap_id == idmap_id) || (reg.idmap_id === null && idmap_id === "") || (reg.idmap_id === "" && idmap_id === null)) &&
                reg.connection_id == connection_id;
            });
            if (register) {
              register.value = value;
            }
          });
        }
      });
    },

    reorderMainVariablesInDashboardInfo: (state, action) => {
      const { reorderedItems } = action.payload;

      if (state.dashBoardInfo.main_variables) {
        // Recorremos el array de IDs reordenados y actualizamos la posición
        state.dashBoardInfo.main_variables = state.dashBoardInfo.main_variables.map((existingVariable: any) => {
          // Encuentra el nuevo índice (posición) del ID en reorderedItems
          const newPosition = reorderedItems.indexOf(existingVariable.id);

          if (newPosition !== -1) {
            return {
              ...existingVariable,
              position: newPosition + 1, // Actualizamos la posición con el nuevo índice, sumando 1 para que no sea cero-indexado
            };
          }
          return existingVariable; // Si el ID no está en reorderedItems, no cambia
        });

        // Si lo prefieres, también podrías ordenar el array por la nueva posición
        state.dashBoardInfo.main_variables.sort((a: any, b: any) => a.position - b.position);
      }
    },


  },

  extraReducers: (builder) => {

    builder.addCase(getFavoritesByConnectionId.fulfilled, (state, action: any) => {

      const newFavorites = action.payload;

      Object.keys(newFavorites).forEach((key) => {
        if (!state.parameters || !state.parameters[key]) {
          state.favorites[key] = newFavorites[key];
        }
      });
    });

    builder.addCase(getFavoritesByConnectionId.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


    builder.addCase(createFavorite.fulfilled, (state: any, action: any) => {
      const { connection_id, ...favoriteData } = action.payload;

      if (!Array.isArray(state.favorites[connection_id])) {
        state.favorites[connection_id] = [];
      }


      state.favorites[connection_id].push(favoriteData);
    });

    builder.addCase(createFavorite.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateFavorite.fulfilled, (state: any, action: any) => {

      const updatedFavorite = action.payload;
      const linkedFavoritesIds = state.linkedFavorites.map((linkedFavorite: any) => linkedFavorite.id);
      const connectionId = updatedFavorite.connection_id;

      if (state.favorites[connectionId]) {
        state.favorites[connectionId] = state.favorites[connectionId].map((favorite: any) => {



          if (favorite.id === updatedFavorite.id) {

            return {
              ...favorite,
              name: updatedFavorite.name !== undefined ? updatedFavorite.name : favorite.name,
              min_scale: updatedFavorite.min_scale !== undefined ? updatedFavorite.min_scale : favorite.min_scale,
              max_scale: updatedFavorite.max_scale !== undefined ? updatedFavorite.max_scale : favorite.max_scale,
              register: updatedFavorite.register !== undefined ? updatedFavorite.register : favorite.register,
              in_graph: updatedFavorite.in_graph !== undefined ? updatedFavorite.in_graph : favorite.in_graph,
              principal: updatedFavorite.principal !== undefined ? updatedFavorite.principal : favorite.principal,
              unit: updatedFavorite.unit !== undefined ? updatedFavorite.unit : favorite.unit,
              color: updatedFavorite.color !== undefined ? updatedFavorite.color : favorite.color,
              decimals: updatedFavorite.decimals !== undefined ? updatedFavorite.decimals : favorite.decimals,
            };
          }

          if (linkedFavoritesIds.includes(favorite.id)) {

            return {
              ...favorite,
              color: updatedFavorite.color
            }
          }

          return favorite;
        });
      }
    });

    builder.addCase(updateFavorite.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteFavorite.fulfilled, (state: any, action: any) => {
      const { connectionId, favoriteId } = action.payload;
      /*  console.log("favoriteid parmetrization", action.payload) */
      const linkedFavoritesIds = state.linkedFavorites.map((linkedFavorite: any) => linkedFavorite.id);
      /* console.log("linkedFavoritesIDs deleted", linkedFavoritesIds) */
      // Filtrar los favoritos, eliminando el que coincida con favoriteId
      const updatedFavorites = state.favorites[connectionId].filter(
        (favorite: any) => favorite.id !== favoriteId
      );

      // Si el array resultante está vacío, asegúrate de mantener la clave con un array vacío
      if (updatedFavorites.length === 0) {
        state.favorites[connectionId] = [];
      } else {
        state.favorites[connectionId] = updatedFavorites;
      }

      // Actualizar el color de los favoritos vinculados
      state.favorites[connectionId] = state.favorites[connectionId].map((favorite: any) => {
        if (linkedFavoritesIds.includes(favorite.id)) {

          return {
            ...favorite,
            color: null
          }
        }
        return favorite;
      });
    });

    builder.addCase(deleteFavorite.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


    builder.addCase(reorderFavorites.fulfilled, (state: any, action: any) => {
      const { favorites, connectionId } = action.payload;

      // Crear un nuevo array de favoritos reordenados y con la posición actualizada
      const reorderedFavorites = state.favorites[connectionId].map((existingFavorite: any) => {
        const favorite = favorites.find((f: any) => f.id === existingFavorite.id);
        if (favorite) {
          return { ...existingFavorite, position: favorite.position }; // Actualizar la posición
        }
        return existingFavorite; // Devolver el favorito tal cual si no coincide
      });

      // Asignar el array reordenado al estado
      state.favorites[connectionId] = reorderedFavorites;
    });


    builder.addCase(reorderFavorites.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getDashboardInfo.fulfilled, (state, action) => {
      state.dashBoardInfo = action.payload;
    });

    builder.addCase(getDashboardInfo.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getLinkedFavorites.fulfilled, (state, action) => {

      state.linkedFavorites = action.payload;
    });

    builder.addCase(getLinkedFavorites.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(createLinkedParameter.fulfilled, (state, action) => {

      const { favorite_visualization_id, favorite_parametrization_id, connection_id } = action.payload;



      const visualizationFavorite = state.favorites[connection_id].find((fav: any) => fav.id == favorite_visualization_id);


      if (visualizationFavorite) {

        const parametrizationFavorite = state.favorites[connection_id].find((fav: any) => fav.id == favorite_parametrization_id);


        if (parametrizationFavorite) {
          parametrizationFavorite.color = visualizationFavorite.color;
          state.linkedFavorites.push(parametrizationFavorite)
          state.linkedFavoritesByConnection.push(parametrizationFavorite.parameter_id)
        }
      }
    });


    builder.addCase(createLinkedParameter.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getLinkedVariablesByConnection.fulfilled, (state, action) => {
      state.linkedFavoritesByConnection = action.payload;
    });

    builder.addCase(getLinkedVariablesByConnection.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteLinkedParameter.fulfilled, (state, action) => {
      // Eliminar el parameter_id del array linkedFavoritesByConnection
      const favorite = action.payload;

      state.linkedFavoritesByConnection = state.linkedFavoritesByConnection.filter(
        (parameter_id: any) => parameter_id !== favorite.parameter_id
      );

      state.linkedFavorites = state.linkedFavorites.filter(
        (linkedFavorite: any) => linkedFavorite.id !== favorite.id
      );

      // Buscar y actualizar el color a null en state.favorites
      Object.keys(state.favorites).forEach(connectionId => {
        state.favorites[favorite.connection_id] = state.favorites[favorite.connection_id].map((fav: any) => {
          if (fav.id === favorite.id) {
            return { ...fav, color: null };
          }
          return fav;
        });
      });
    });

    builder.addCase(deleteLinkedParameter.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(resetFavoriteSelected.fulfilled, (state) => {
      state.favoriteIdSelected = null;
    });



  },
});
export const { setFilteredFavorite, setSelectedFavoriteId, updateDashboardValues, setDashBoardInfo, reorderMainVariablesInDashboardInfo, setFetchingFavorites } = parameterValuesSlice.actions;
export default parameterValuesSlice.reducer;