import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

interface ModalHelpProps {
  isOpen: boolean;
  toggle: () => void;
  title: string;
  body: string;
  buttonText: string;
  imageSrc?: string;  // La imagen es opcional
}

const ModalHelp: React.FC<ModalHelpProps> = ({
  isOpen,
  toggle,
  title,
  body,
  buttonText,
  imageSrc
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <p>{body}</p>
        {imageSrc && (
          <div className='d-flex justify-content-center'>
            <img src={imageSrc} alt="help image" style={{ maxWidth: '100%', maxHeight: '400px' }} />
          </div>
        )}
      </ModalBody>
      <ModalFooter className='d-flex justify-content-center'>
        <Button color="secondary rounded-pill" onClick={toggle}>
          {buttonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalHelp;
