import { createSlice } from "@reduxjs/toolkit";
import { getSynopticImageConfigByZone } from "./thunk";
import { config } from "process";

export const initialState: any = {
  synopticImageConfig: {}, // Cambiado a un objeto clave-valor
  error: null,
  loading: false,
};

const synopticImageConfigSlice = createSlice({
  name: "synopticImageConfig",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getSynopticImageConfigByZone.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSynopticImageConfigByZone.fulfilled, (state, action) => {
      state.loading = false;
      const { zoneId, data } : any = action.payload; 
      console.log("data", data);
      state.synopticImageConfig[zoneId] = data;
    });
    builder.addCase(getSynopticImageConfigByZone.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload?.error || "Failed to fetch Synoptic Image Configurations";
    });
  },
});

export default synopticImageConfigSlice.reducer;
