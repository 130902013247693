import { createSlice } from "@reduxjs/toolkit";
import {
  createConnection,
  getConnections,
  getUnconfiguredActiveConnections,
  deleteConnection,
  updateConnection
} from "./thunk";



export const initialState: any = {
  connections: [],
};


const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {},

  extraReducers: (builder) => {

    builder.addCase(getConnections.fulfilled, (state: any, action: any) => {
      state.connections = action.payload;
    });
    builder.addCase(getConnections.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getUnconfiguredActiveConnections.fulfilled, (state: any, action: any) => {
      state.unconfiguredActiveConnections = action.payload;
    });

    builder.addCase(getUnconfiguredActiveConnections.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(createConnection.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        state.connections.push(action.payload);
      }

    });

    builder.addCase(createConnection.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateConnection.fulfilled, (state: any, action: any) => {
      state.connections = (state.connections || []).map((connection: any) =>
        connection.id.toString() === action.payload.id.toString()
          ? { ...connection, ...action.payload }
          : connection
      );

      state.unconfiguredActiveConnections = state.unconfiguredActiveConnections.filter(
        (connection: any) => connection.id.toString() !== action.payload.id.toString()
      );
    });

    builder.addCase(updateConnection.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(deleteConnection.fulfilled, (state: any, action: any) => {
      state.unconfiguredActiveConnections.push(action.payload);
      state.connections = state.connections.filter(
        (connection: any) => connection.id.toString() !== action.payload.toString()
      );

    });

    builder.addCase(deleteConnection.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});

export default connectionSlice.reducer;