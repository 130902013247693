import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getInstallationZones as getInstallationZonesApi,
  createInstallationZone as createInstallationZoneApi,
  updateInstallationZone as updateInstallationZoneApi,
  updateUserInstallationZone as updateUserInstallationZoneApi,
  reorderInstallationZones as reorderInstallationZonesApi,
  deleteInstallationZone as deleteInstallationZoneApi,
  getInstallationZonesByUser as getInstallationZonesByUserApi
} from "../../helpers/fakebackend_helper";
import { createZone, deleteZone, editZone, reorderInstallationZones as reorderInstallationZonesInNavbar } from "slices/installations/reducer";

export const getInstallationZones = createAsyncThunk("installationZone/getInstallationZones", async (installationId: number) => {
  try {
    const response = await getInstallationZonesApi(installationId);
    return response;
  } catch (error) {
    return error;
  }
});

export const getInstallationZonesByUser = createAsyncThunk("installationZone/getInstallationZonesByUser", async (userId: number) => {
  try {
    const response = await getInstallationZonesByUserApi(userId);
    return response;
  } catch (error) {
    return error;
  }
});


export const createInstallationZone = createAsyncThunk(
  "installationZone/createInstallationZone",
  async (installation: any, { dispatch }) => {
    try {
      const response: any = await createInstallationZoneApi(installation);

      
     
      dispatch(createZone(response.installation_zone));

      return response.installation_zone;

    } catch (error) {
      return { 'error': error || 'An error occurred during the creation process' };
    }
  }
);

export const updateInstallationZone = createAsyncThunk(
  "installationZone/updateInstallationZone",
  async (installationZone: any, { dispatch }) => {
    try {
      const response = await updateInstallationZoneApi(installationZone);
      dispatch(editZone(installationZone));
      return installationZone;
    } catch (error) {
      return error;
    }
  });

export const updateUserInstallationZone = createAsyncThunk(
  "installationZone/updateUserInstallationZone",
  async (userInstallationZone: any) => {
    try {
      const response = await updateUserInstallationZoneApi(userInstallationZone);
      return userInstallationZone;
    } catch (error) {
      return { error };
    }
  }
);


export const reorderInstallationZones = createAsyncThunk(
  "installationZone/reorderInstallationZones",
  async (installationZones: any, { dispatch }) => {
    try {
      const installationZonesIds = installationZones.map((zone: any) => zone.id);
      const payload = { installationZones_ids: installationZonesIds };
      const response = await reorderInstallationZonesApi(payload);
      dispatch(reorderInstallationZonesInNavbar(installationZones))

      return installationZones;

    } catch (error) {
      return error;
    }
  }
);

export const deleteInstallationZone = createAsyncThunk(
  "installationZone/deleteInstallationZone",
  async (zone: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await deleteInstallationZoneApi(zone.id);
      if (response) {
        // Despacha la acción deleteZone definida en el otro slice con el id de la zona
        dispatch(deleteZone(zone.id));
      }
      return zone.id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);