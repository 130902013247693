import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getStatusZone as getStatusZoneApi,
  createStatusZone as createStatusZoneApi,
  updateStatusZone as updateStatusZoneApi,
  deleteStatusZone as deleteStatusZoneApi,
  reorderedStatusZones as reorderedStatusZonesApi

} from "../../helpers/fakebackend_helper";

export const getStatusZone = createAsyncThunk("statusZone/getStatusZone", async (zoneID: number) => {
  try {
    const response = await getStatusZoneApi(zoneID);
    
    return response;
  } catch (error) {
    return error;
  }
});

export const createStatusZone = createAsyncThunk(
  "statusZone/createStatusZone",
  async ({ status, parametersBackup }: { status: any, parametersBackup: any }, { dispatch }) => {
    try {
      const response: any = await createStatusZoneApi(status);
      console.log("createStatusZone", response);
      return {
        ...response,
        connection_name: parametersBackup[status.connection_id].connection_name
      };
    } catch (error) {
      return { 'error': error || 'An error occurred during the creation process' };
    }
  }
);

export const updateStatusZone = createAsyncThunk(
  "statusZone/updateStatusZone",
  async (installationZone: any, { dispatch }) => {
    try {

      const response = await updateStatusZoneApi(installationZone);
      return installationZone;
    } catch (error) {
      return error;
    }
  });


export const reorderedStatusZones = createAsyncThunk(
  "statusZone/reorderedStatusZones",
  async (statusZone: any, { dispatch }) => {
    try {

      console.log("status zone reordered", statusZone)
      const response = await reorderedStatusZonesApi(statusZone);
      return statusZone;
    } catch (error) {
      return error;
    }
  });


export const deleteStatusZone = createAsyncThunk(
  "statusZone/deleteStatusZone",
  async (zone: any, { dispatch, rejectWithValue }) => {
    try {

      const response = await deleteStatusZoneApi(zone.id);
      return { id: zone.id, installation_zone_id: zone.installation_zone_id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);