import { createSlice } from "@reduxjs/toolkit";
import { getFaultsByConnectionID } from "./thunk";

export const initialState: any = {
  faultsList: [],
  error: null,
  loading: false,
};

const faultHistorySlice = createSlice({
  name: "faults",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getFaultsByConnectionID.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getFaultsByConnectionID.fulfilled, (state, action) => {
      state.loading = false;
      state.faultsList = action.payload;
    });
    builder.addCase(getFaultsByConnectionID.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to fetch fault histories';
    });





  },
});

export default faultHistorySlice.reducer;
