import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './slices';
import './i18n';

// Función para sanitizar transiciones de UI-Router
const sanitizeUIRouterTransition = (transition: any) => ({
  params: transition.router?.globals?.params,
  current: transition.router?.globals?.current,
  targetState: transition.targetState?.().state(),
  from: transition.from?.(),
  to: transition.to?.()
});

// Sanitizador de acciones con logs
const actionSanitizer = (action: any) => {

  const uiRouterActions = /@ui-router.+/g;
  const sanitizedAction = uiRouterActions.test(action.type)
    ? { type: action.type, transition: sanitizeUIRouterTransition(action.transition) }
    : action;

  return sanitizedAction;
};

// Sanitizador de estado con enfoque agresivo
const stateSanitizer = (state: any, index: number) => {
  return {
    ...state,
    Layout: state.Layout ? '<<LAYOUT_DATA>>' : undefined,
    Login: state.Login ? '<<LOGIN_DATA>>' : undefined,
    Account: state.Account ? '<<ACCOUNT_DATA>>' : undefined,
    ForgetPassword: state.ForgetPassword ? '<<FORGET_PASSWORD_DATA>>' : undefined,
    Profile: state.Profile ? '<<PROFILE_DATA>>' : undefined,
    Calendar: state.Calendar ? '<<CALENDAR_DATA>>' : undefined,
    Installations: state.Installations ? '<<INSTALLATIONS_DATA>>' : undefined,
    InstallationZones: state.InstallationZones ? '<<INSTALLATION_ZONES_DATA>>' : undefined,
    Users: state.Users ? '<<USERS_DATA>>' : undefined,
    Devices: state.Devices ? '<<DEVICES_DATA>>' : undefined,
    Parameters: state.Parameters ? '<<PARAMETERS_DATA>>' : undefined,
    ParametersBackup: state.ParametersBackup ? '<<PARAMETERS_BACKUP_DATA>>' : undefined,
    ParameterValues: state.ParameterValues ? '<<PARAMETER_VALUES_DATA>>' : undefined,
    CalculatedParameters: state.CalculatedParameters ? '<<CALCULATED_PARAMETERS_DATA>>' : undefined,
    Connections: state.Connections ? '<<CONNECTIONS_DATA>>' : undefined,
    Faults: state.Faults ? '<<FAULTS_DATA>>' : undefined,
    Roles: state.Roles ? '<<ROLES_DATA>>' : undefined,
    Companies: state.Companies ? '<<COMPANIES_DATA>>' : undefined,
    Favorites: state.Favorites ? '<<FAVORITES_DATA>>' : undefined,
    Registers: state.Registers ? '<<REGISTERS_DATA>>' : undefined,
    Chat: state.Chat ? '<<CHAT_DATA>>' : undefined,
    FaultHistory: state.FaultHistory ? '<<FAULT_HISTORY_DATA>>' : undefined,
    AlarmHistory: state.AlarmHistory ? '<<ALARM_HISTORY_DATA>>' : undefined,
    AlarmsAndFaults: state.AlarmsAndFaults ? '<<ALARMS_AND_FAULTS_DATA>>' : undefined,
    ZoneEmail: state.ZoneEmail ? '<<ZONE_EMAIL_DATA>>' : undefined,
    StatusZone: state.StatusZone ? '<<STATUS_ZONE_DATA>>' : undefined,
    StartupRegistration: state.StartupRegistration ? '<<STARTUP_REGISTRATION_DATA>>' : undefined,
    Projects: state.Projects ? '<<PROJECTS_DATA>>' : undefined,
    Ecommerce: state.Ecommerce ? '<<ECOMMERCE_DATA>>' : undefined,
    Tasks: state.Tasks ? '<<TASKS_DATA>>' : undefined,
    Crypto: state.Crypto ? '<<CRYPTO_DATA>>' : undefined,
    Tickets: state.Tickets ? '<<TICKETS_DATA>>' : undefined,
    Invoice: state.Invoice ? '<<INVOICE_DATA>>' : undefined,
    Mailbox: state.Mailbox ? '<<MAILBOX_DATA>>' : undefined,
    DashboardAnalytics: state.DashboardAnalytics ? '<<DASHBOARD_ANALYTICS_DATA>>' : undefined,
    DashboardCRM: state.DashboardCRM ? '<<DASHBOARD_CRM_DATA>>' : undefined,
    DashboardEcommerce: state.DashboardEcommerce ? '<<DASHBOARD_ECOMMERCE_DATA>>' : undefined,
    DashboardCrypto: state.DashboardCrypto ? '<<DASHBOARD_CRYPTO_DATA>>' : undefined,
    DashboardProject: state.DashboardProject ? '<<DASHBOARD_PROJECT_DATA>>' : undefined,
    DashboardNFT: state.DashboardNFT ? '<<DASHBOARD_NFT_DATA>>' : undefined,
    Team: state.Team ? '<<TEAM_DATA>>' : undefined,
    FileManager: state.FileManager ? '<<FILE_MANAGER_DATA>>' : undefined,
    Todos: state.Todos ? '<<TODOS_DATA>>' : undefined,
    Jobs: state.Jobs ? '<<JOBS_DATA>>' : undefined,
    APIKey: state.APIKey ? '<<API_KEY_DATA>>' : undefined,
    Status: state.Status ? '<<STATUS_DATA>>' : undefined,
  };
};

// Configuración de Redux DevTools con opciones de sanitización
const store = configureStore({
  reducer: rootReducer,
  devTools: {
    actionSanitizer,
    stateSanitizer,
    maxAge: 20,  // Limitar el número de acciones rastreadas
    serialize: false,  // Desactivar la serialización de objetos complejos
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
