import { createSlice } from "@reduxjs/toolkit";
import { createCompany, getCompanies, deleteCompany, updateCompany } from "./thunk";

export const initialState: any = {
  companies: [],
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanies.fulfilled, (state: any, action: any) => {
      state.companies = action.payload;
    });
    builder.addCase(getCompanies.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(createCompany.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        state.companies.push(action.payload);
      }

    });

    builder.addCase(createCompany.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(updateCompany.fulfilled, (state: any, action: any) => {
      state.companies = (state.companies || []).map((company: any) =>
        company.id.toString() === action.payload.id.toString()
          ? { ...company, ...action.payload }
          : company
      );
    });

    builder.addCase(updateCompany.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


    builder.addCase(deleteCompany.fulfilled, (state: any, action: any) => {
      state.companies = state.companies.filter(
        (company: any) => company.id.toString() !== action.payload.toString()
      );
    });

    builder.addCase(deleteCompany.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  },
});

export default companySlice.reducer;