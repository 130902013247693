import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getUsers as getUsersApi,
  createUser as createUserApi,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi,
  resetUserPassword as resetUserPasswordApi,
  changeUserPassword as changeUserPasswordApi
} from "../../helpers/fakebackend_helper";

export const getUsers = createAsyncThunk("user/getUsers", async () => {
  try {
    const response = await getUsersApi();

    return response;
  } catch (error) {
    return error;
  }
});

export const createUser = createAsyncThunk("user/createUser", async (user: any) => {
  try {

    const response: any = await createUserApi(user);

    if (response.id) {
      user.id = response.id;
      user.supervision_name = response.supervision_name;
      user.company_name = response.company_name;
    }
    return user;
  } catch (error) {
    return { 'error': error };
  }
});

export const updateUser = createAsyncThunk("user/updateUser", async (user: any) => {
  try {
    const response = await updateUserApi(user);
    return user;
  } catch (error) {
    return error;
  }
});


export const deleteUser = createAsyncThunk("user/deleteUser", async (user: any) => {
  try {

    const response = await deleteUserApi(user);
    return user;
  } catch (error) {
    return error;
  }
});

export const resetUserPassword = createAsyncThunk("user/resetPassword", async (userId: boolean) => {
  try {
    const userIdValue = { userId: userId }
    const response = await resetUserPasswordApi(userIdValue);
    return response;
  } catch (error) {
    return { error: error };
  }
});

export const changeUserPassword = createAsyncThunk("user/changeUserPassword", async (passwordInfo : any) => {
  try {
    const { password, token } = passwordInfo;
    const response = await changeUserPasswordApi(passwordInfo);
    return response;
  } catch (error) {
    return { error: error };
  }
});