import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Table, UncontrolledTooltip, ModalFooter } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { toast } from 'react-toastify';
import ModalConfirm from 'Components/Common/Modals/ModalConfirm';
import ModalAddStatusZone from './ModalAddStatusZone';
import { deleteStatusZone, getStatusZone, reorderedStatusZones } from 'slices/statusZone/thunk';

interface StatusZone {
    id: number;
    installation_zone_id: number;
    name: string;
    description: string;
    position: number;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
    value: string;
    connection_name: string;
    message_activated: string;
    message_deactivated: string;
}

interface ModalStatusZoneProps {
    isOpen: boolean;
    toggle: () => void;
    idSelected: number | null;
    zoneId?: number;
}

const ModalStatusZone: React.FC<ModalStatusZoneProps> = ({ isOpen, toggle, idSelected, zoneId }) => {
    const dispatch: any = useDispatch();



    const selectStatusZone = createSelector(
        (state: any, idSelected: any) => state.StatusZone.statusZone[Number(idSelected)],
        (zone) => Array.isArray(zone) ? zone : (zone ? [zone] : [])
    );

    const statusZones = useSelector(state => selectStatusZone(state, idSelected));

    const [zones, setZones] = useState<StatusZone[]>([]);
    const [currentZone, setCurrentZone] = useState<StatusZone | null>(null);
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [addZoneModalOpen, setAddZoneModalOpen] = useState(false);

    useEffect(() => {
        if (JSON.stringify(statusZones) !== JSON.stringify(zones)) {
            setZones(statusZones);
        }
    }, [statusZones]);

    const toggleAddZoneModal = () => {
        setAddZoneModalOpen(!addZoneModalOpen);
    };

    const handleAddZoneClick = () => {
        setCurrentZone(null);
        toggleAddZoneModal();
    };

    const handleZoneClick = (zone: StatusZone) => {
        setCurrentZone(zone);
        toggleAddZoneModal();
    };

    const handleUpdateZone = (updatedZone: StatusZone) => {
        const updatedZones = zones.map(zone =>
            zone.id === updatedZone.id ? updatedZone : zone
        );
        setZones(updatedZones);
        toggleAddZoneModal();
    };

    const handleDeleteZone = (zone: any) => {
        setCurrentZone(zone);
        setDeleteConfirmationModal(true);
    };

    const onDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!destination || destination.index === source.index) {
            return;
        }
        const newZones = Array.from(zones);
        const [relocatedItem] = newZones.splice(source.index, 1);
        newZones.splice(destination.index, 0, relocatedItem);
        setZones(newZones);

        const reorderedIds = newZones.map(zone => zone.id);

        dispatch(reorderedStatusZones(reorderedIds));
    };

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        userSelect: 'none',
        background: isDragging ? 'rgba(255, 255, 255, 0.8)' : '',
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver: any) => ({
        background: isDraggingOver ? '#F0F0F0' : '',
        width: '100%'
    });

    const handleAddZone = async (values: any) => {
        setAddZoneModalOpen(false);
    };

    const toggleDeleteConfirmationModal = () => {
        setDeleteConfirmationModal(!deleteConfirmationModal);
    };

    const handleConfirmDeleteStatusZone = async (statusZoneId: number | null) => {
        setDeleteConfirmationModal(false);
        try {
            if (statusZoneId) {
                await dispatch(deleteStatusZone(currentZone)).unwrap();
                toast.success('Zona eliminada con éxito!');
            }
        } catch (error: any) {
            toast.error('Error al eliminar la zona: ' + error.message);
        }
    };

    const handleCancelDeleteStatusZone = () => {
        setDeleteConfirmationModal(false);
    };

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>Zonas de estado</ModalHeader>
                <ModalBody>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable-zones">
                            {(provided: any, snapshot: any) => (
                                <Table className="table table-striped mb-5" style={getListStyle(snapshot.isDraggingOver)}>
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: '240px' }}>Descripción</th>
                                            <th scope="col" style={{ width: '240px' }}>Dispositivo</th>
                                            <th scope="col" style={{ width: '240px' }}>Mensaje Activado</th>
                                            <th scope="col" style={{ width: '240px' }}>Mensaje Desactivado</th>
                                            <th scope="col" style={{ width: '160px' }} className="text-center">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                        {zones.map((zone, index) => (
                                            <Draggable key={`zone-${zone.id}-${index}`} draggableId={zone.id.toString()} index={index}>
                                                {(provided: any, snapshot: any) => (
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                    >
                                                        <td style={{ width: '240px' }} className="py-1">{zone.description}</td>
                                                        <td style={{ width: '240px' }} className="py-1">{zone.connection_name}</td>
                                                        <td style={{ width: '240px' }} className="py-1">{zone.message_activated}</td>
                                                        <td style={{ width: '240px' }} className="py-1">{zone.message_deactivated}</td>
                                                        <td style={{ width: '160px' }} className="text-center py-1">
                                                            <Button id="editZoneButton" className="btn btn-sm me-2" color="none" onClick={() => handleZoneClick(zone)}>
                                                                <i className="mdi mdi-pencil fs-6"></i>
                                                            </Button>
                                                            <UncontrolledTooltip placement="top" target="editZoneButton">Editar zona</UncontrolledTooltip>
                                                            <Button id="deleteZoneButton" color="danger" className="btn btn-sm me-2" onClick={() => handleDeleteZone(zone)}>
                                                                <i className="mdi mdi-delete fs-6"></i>
                                                            </Button>
                                                            <UncontrolledTooltip placement="top" target="deleteZoneButton">Eliminar zona</UncontrolledTooltip>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </tbody>
                                </Table>
                            )}
                        </Droppable>
                    </DragDropContext>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-center w-100">
                        <Button color="primary" onClick={handleAddZoneClick} className="mb-3 rounded-pill">
                            Añade un estado
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>

            <ModalAddStatusZone
                isOpen={addZoneModalOpen}
                toggle={toggleAddZoneModal}
                onSave={handleAddZone}
                currentZone={currentZone}
                idSelected={idSelected}
                zoneId={zoneId}
            />

            <ModalConfirm
                isOpen={deleteConfirmationModal}
                toggle={toggleDeleteConfirmationModal}
                title={"Borrar estado de la zona"}
                message={`¿Estás seguro de que quieres borrar ${currentZone?.description}?`}
                onConfirm={() => handleConfirmDeleteStatusZone(currentZone ? Number(currentZone.id) : null)}
                onCancel={handleCancelDeleteStatusZone}
                idSelected={currentZone ? Number(currentZone.id) : null}
                iconName="mdi mdi-delete display-5 text-danger"
            />
        </div>
    );
};

export default ModalStatusZone;
