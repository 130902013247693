import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getInstallations as getInstallationsApi,
  createInstallation as createInstallationApi,
  updateInstallation as updateInstallationApi,
  reorderInstallations as reorderInstallationsApi,
  deleteInstallation as deleteInstallationApi,
} from "../../helpers/fakebackend_helper";

export const getInstallations = createAsyncThunk("installation/getInstallations", async () => {
  try {
    const response = await getInstallationsApi();

    return response;
  } catch (error) {
    return error;
  }
});

export const createInstallation = createAsyncThunk("installation/createInstallation", async (installation: any) => {
  try {
    const response: any = await createInstallationApi(installation);

    if (response.id) {
      installation.id = response.id;
    }
    return installation;
  } catch (error) {
    return { 'error': error };
  }
});

export const updateInstallation = createAsyncThunk("installation/updateInstallation", async (installation: any) => {
  try {
    const response = await updateInstallationApi(installation);
    return installation;
  } catch (error) {
    return error;
  }
});


export const reorderInstallations = createAsyncThunk("installation/reorderInstallations", async (installations: any) => {
  try {
    const installationIds = installations.map((inst: any) => inst.id);
    const payload = { installation_ids: installationIds };
    const response = await reorderInstallationsApi(payload);
    return installations;
  } catch (error) {
    return error;
  }
});

export const deleteInstallation = createAsyncThunk("installation/deleteInstallation", async (installation: any) => {
  try {

    const response = await deleteInstallationApi(installation);
    return installation;
  } catch (error) {
    return error;
  }
});