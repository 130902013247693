import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getCalculatedParametersByZone as getCalculatedParametersByZoneApi,
  createCalculatedParameter as createCalculatedParameterApi,
  updateCalculatedParameter as updateCalculatedParameterApi,
  deleteCalculatedParameter as deleteCalculatedParameterApi

} from "../../helpers/fakebackend_helper";


export const getCalculatedParametersByZone = createAsyncThunk("calculatedParameters/getCalculatedParametersByZone", async (zoneID: any) => {
  try {
    const response = await getCalculatedParametersByZoneApi(zoneID);



    return response;
  } catch (error) {
    return error;
  }
});

export const createCalculatedParameter = createAsyncThunk("calculatedParameter/createCalculatedParameter", async (calculatedParameter: any) => {
  try {
    const response: any = await createCalculatedParameterApi(calculatedParameter);
    if (response && response.id) {
      calculatedParameter.id = response.id;
    }
    return calculatedParameter;
  } catch (error) {
    return { 'error': error };
  }
});

export const updateCalculatedParameter = createAsyncThunk("calculatedParameter/updateCalculatedParameter", async (calculatedParameter: any) => {
  try {

    const response: any = await updateCalculatedParameterApi(calculatedParameter);

    return response;
  } catch (error) {
    return error;
  }
});

export const deleteCalculatedParameter = createAsyncThunk(
  "calculatedParameter/deleteCalculatedParameter",
  async ({ id, zoneID }: { id: number; zoneID: any }) => {
    try {
      const response = await deleteCalculatedParameterApi(id);

      return { id, zoneID };
    } catch (error) {
      throw error; // Esto propagará el error a quien llame a la función thunk
    }
  }
);

