import { createSlice } from "@reduxjs/toolkit";
import { getStartupRegistrationByZone } from "./thunk";

export const initialState: any = {
  startupRegistration: [],
  error: null,
  loading: false,
};

const startupRegistrationSlice = createSlice({
  name: "startupRegistration",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getStartupRegistrationByZone.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getStartupRegistrationByZone.fulfilled, (state, action) => {
      state.loading = false;
      state.startupRegistration = action.payload;
    });
    builder.addCase(getStartupRegistrationByZone.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.payload.error || 'Failed to fetch alarm histories';
    });
  },
});

export default startupRegistrationSlice.reducer;
